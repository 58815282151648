/* tslint:disable */

/**
 * This file has been automatically generated by the [capnpc-ts utility](https://github.com/jdiaz5513/capnp-ts).
 */

import * as capnp from "capnp-ts";
import { ObjectSize as __O, Struct as __S } from 'capnp-ts';
import { NtbData, NTB } from "./ntb.capnp";
export const _capnpFileId = "eb1f06140c4c1a3a";
export class HtbData extends __S {
    static readonly _capnp = { displayName: "HtbData", id: "d6241f3b1078f4b4", size: new __O(16, 2) };
    static _NodeTxBundles: capnp.ListCtor<NTB>;
    getBlockNumber(): number { return __S.getUint32(0, this); }
    setBlockNumber(value: number): void { __S.setUint32(0, value, this); }
    getEpochNumber(): number { return __S.getUint32(4, this); }
    setEpochNumber(value: number): void { __S.setUint32(4, value, this); }
    getChainNumber(): number { return __S.getUint32(8, this); }
    setChainNumber(value: number): void { __S.setUint32(8, value, this); }
    adoptPrevClsHash(value: capnp.Orphan<capnp.Data>): void { __S.adopt(value, __S.getPointer(0, this)); }
    disownPrevClsHash(): capnp.Orphan<capnp.Data> { return __S.disown(this.getPrevClsHash()); }
    getPrevClsHash(): capnp.Data { return __S.getData(0, this); }
    hasPrevClsHash(): boolean { return !__S.isNull(__S.getPointer(0, this)); }
    initPrevClsHash(length: number): capnp.Data { return __S.initData(0, length, this); }
    setPrevClsHash(value: capnp.Data): void { __S.copyFrom(value, __S.getPointer(0, this)); }
    adoptNodeTxBundles(value: capnp.Orphan<capnp.List<NTB>>): void { __S.adopt(value, __S.getPointer(1, this)); }
    disownNodeTxBundles(): capnp.Orphan<capnp.List<NTB>> { return __S.disown(this.getNodeTxBundles()); }
    getNodeTxBundles(): capnp.List<NTB> { return __S.getList(1, HtbData._NodeTxBundles, this); }
    hasNodeTxBundles(): boolean { return !__S.isNull(__S.getPointer(1, this)); }
    initNodeTxBundles(length: number): capnp.List<NTB> { return __S.initList(1, HtbData._NodeTxBundles, length, this); }
    setNodeTxBundles(value: capnp.List<NTB>): void { __S.copyFrom(value, __S.getPointer(1, this)); }
    toString(): string { return "HtbData_" + super.toString(); }
}
export class HTB extends __S {
    static readonly _capnp = { displayName: "HTB", id: "bb28adfc9dfa9688", size: new __O(0, 2) };
    adoptHtbData(value: capnp.Orphan<HtbData>): void { __S.adopt(value, __S.getPointer(0, this)); }
    disownHtbData(): capnp.Orphan<HtbData> { return __S.disown(this.getHtbData()); }
    getHtbData(): HtbData { return __S.getStruct(0, HtbData, this); }
    hasHtbData(): boolean { return !__S.isNull(__S.getPointer(0, this)); }
    initHtbData(): HtbData { return __S.initStructAt(0, HtbData, this); }
    setHtbData(value: HtbData): void { __S.copyFrom(value, __S.getPointer(0, this)); }
    adoptHubSignature(value: capnp.Orphan<capnp.Data>): void { __S.adopt(value, __S.getPointer(1, this)); }
    disownHubSignature(): capnp.Orphan<capnp.Data> { return __S.disown(this.getHubSignature()); }
    getHubSignature(): capnp.Data { return __S.getData(1, this); }
    hasHubSignature(): boolean { return !__S.isNull(__S.getPointer(1, this)); }
    initHubSignature(length: number): capnp.Data { return __S.initData(1, length, this); }
    setHubSignature(value: capnp.Data): void { __S.copyFrom(value, __S.getPointer(1, this)); }
    toString(): string { return "HTB_" + super.toString(); }
}
HtbData._NodeTxBundles = capnp.CompositeList(NTB);
