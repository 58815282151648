import { UUT } from "@/generated/uut.capnp";
import { ConfirmationTxUutValidationCode as UutValidationCode} from "@/generated/confirmationTx.capnp";

/*
 * Accepted transactions are assgined the VALID code
 *
 * Rejected transactions are assigned an INVALID_* code
 */
// enum UutValidationCode {
//     VALID = 0,
//     INVALID_NTB = 1,                // Included in an invalid NTB
//     INVALID_SIGNATURE = 2,          // Invalide UUT signature
//     INVALID_ACCOUNT = 3,            // UUT refers to a non-existant account
//     INVALID_NONCE_REPLAY = 4,       // Nonce is invalid
//     INVALID_JOINT_SOLVENCY = 5,     // Did not pass joint solvency
// }

/* 
 * Newly instantiated UutWithIds are unverified.  As checkes are made, they can end up in the REJECTED or ACCEPTED state.
 */
enum UutValidationState {
    UNVERIFIED = 0,
    REJECTED = 1,
    ACCEPTED = 2
}
  
/*
 * UUTs are addressed by the (ntbIndex, uutIndex) tuple.  The start off in an UNVERIFIED state, and end up ACCEPTED or REJECTED.
 * In their final state they are assigned one of the UutValidationCodes.
 */
class UutWithId {
    private _uut: UUT
    private _ntbIndex: number
    private _uutIndex: number
    private _validationCode: UutValidationCode | null = null
    private _validationState: UutValidationState
    private _feeAmount = 0
  
    get uut() {
      return this._uut
    }
  
    get indexes() {
      return [this._ntbIndex, this._uutIndex]
    }
  
    get isValid() {
      return this._validationCode == UutValidationCode.VALID
    }

    get feeAmount() {
        return this._feeAmount
    }

    set feeAmount(newAmount) {
        this._feeAmount = newAmount        
    }
  
    get validationCode(): UutValidationCode | null {
      return this._validationCode
    }

    set validationCode(value: UutValidationCode | null) {

        if (value == null) {
          throw('Unexpected null validation code')
        }

        this._validationCode = value

        // Determine final validation state
        switch (this._validationCode) {
            case UutValidationCode.VALID:
                this._validationState = UutValidationState.ACCEPTED
                break
            default:
                this._validationState = UutValidationState.REJECTED
                break
        }
    }

    get validationState() {
      return this._validationState
    }

    constructor(ntbIndex: number, uutIndex: number, uut: UUT) {
      // Which NTB
      this._ntbIndex = ntbIndex
  
      // Which UUT within NTB
      this._uutIndex = uutIndex
  
      // UUT data
      this._uut = uut
  
      // validation state 
      this._validationState = UutValidationState.UNVERIFIED
    }
  }

  export { UutWithId, UutValidationState, UutValidationCode }