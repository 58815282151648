// Messages
import { NTB } from "@/generated/ntb.capnp";
import { UUT } from "@/generated/uut.capnp";

// Message Wrappers
import { uutJsonFromUUT } from "@/models/capnpMessages/UutJson";
import { ntbFromBuffer } from "@/models/capnpMessages/MessageFromBuffer";

// Utilities
import { buf2hex, trucatedBuf2hex } from "@/models/formatUtils";

/*
 * Transforms the contents of the NTB to a human readable JSON format.
 */
export function ntbJsonFromNTB(ntb: NTB): Record<string, unknown> {
  // Signature
  const signature = ntb.getNodeSignature();
  const signatureBuffer: ArrayBuffer = signature.toArrayBuffer();
  const signatureText = buf2hex(signatureBuffer);

  const ntbData = ntb.getNtbData();

  // Header
  const blockNumber = ntbData.getBlockNumber();
  const chainNumber = ntbData.getChainNumber();
  const epochNumber = ntbData.getEpochNumber();

  const currentHubPublicKey = trucatedBuf2hex(ntbData.getCurrentHubPublicKey().toArrayBuffer());
  const nodePublicKey = trucatedBuf2hex(ntbData.getNodePublicKey().toArrayBuffer())

  const prevClsHash = buf2hex(ntbData.getPrevClsHash().toArrayBuffer())

  const uuts = ntbData.getUnverifiedUserTransactions();
  const uutList = [];
  for (let i = 0; i < uuts.getLength(); i++) {
    const uut: UUT = uuts.get(i);
    const uutJSON = uutJsonFromUUT(uut);
    uutList.push(uutJSON);
  }

  // TODO - prev cls hash

  return {
    ntbData: {
      chainNumber: chainNumber,
      blockNumber: blockNumber,
      //epochNumber: epochNumber,
      currentHubPublicKey: currentHubPublicKey,
      nodePublicKey: nodePublicKey,

      prevClsHash: prevClsHash,
      unverifiedUserTransactions: uutList,
    },
    Signature: signatureText,
  };
}

/*
 * Transforms the contents of the NTB from the capnp message format
 * in an ArrayBuffer to a human readable JSON format.
 */
export function ntbJson(buffer: ArrayBuffer): Record<string, unknown> {
  const ntb: NTB = ntbFromBuffer(buffer);
  return ntbJsonFromNTB(ntb)
}

export default {
  ntbJson,
};
