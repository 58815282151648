/* tslint:disable */

/**
 * This file has been automatically generated by the [capnpc-ts utility](https://github.com/jdiaz5513/capnp-ts).
 */

import * as capnp from "capnp-ts";
import { ObjectSize as __O, Struct as __S } from 'capnp-ts';
export const _capnpFileId = "aafd4014c641f3b1";
export enum ConfirmationTxUutValidationCode {
    VALID,
    INVALID_NTB,
    INVALID_SIGNATURE,
    INVALID_ACCOUNT,
    INVALID_NOUNCE_REPLAY,
    INVALID_JOINT_SOLVENCY
}
export class ConfirmationTx extends __S {
    static readonly UutValidationCode = ConfirmationTxUutValidationCode;
    static readonly _capnp = { displayName: "ConfirmationTx", id: "a14b88eb2e9320e0", size: new __O(24, 0) };
    getNtbIndex(): number { return __S.getUint32(0, this); }
    setNtbIndex(value: number): void { __S.setUint32(0, value, this); }
    getUutIndex(): number { return __S.getUint32(4, this); }
    setUutIndex(value: number): void { __S.setUint32(4, value, this); }
    getValidationCode(): ConfirmationTxUutValidationCode { return __S.getUint16(8, this); }
    setValidationCode(value: ConfirmationTxUutValidationCode): void { __S.setUint16(8, value, this); }
    getFeeCollectionAmount(): capnp.Uint64 { return __S.getUint64(16, this); }
    setFeeCollectionAmount(value: capnp.Uint64): void { __S.setUint64(16, value, this); }
    toString(): string { return "ConfirmationTx_" + super.toString(); }
}
