/* tslint:disable */

/**
 * This file has been automatically generated by the [capnpc-ts utility](https://github.com/jdiaz5513/capnp-ts).
 */

import * as capnp from "capnp-ts";
import { ObjectSize as __O, Struct as __S } from 'capnp-ts';
export const _capnpFileId = "87b311c88e639d44";
export class UutData extends __S {
    static readonly _capnp = { displayName: "UutData", id: "a88a9040f4604008", size: new __O(24, 3) };
    getChainNumber(): number { return __S.getUint32(0, this); }
    setChainNumber(value: number): void { __S.setUint32(0, value, this); }
    getBlockNumber(): number { return __S.getUint32(4, this); }
    setBlockNumber(value: number): void { __S.setUint32(4, value, this); }
    getUserTxNumber(): number { return __S.getUint32(8, this); }
    setUserTxNumber(value: number): void { __S.setUint32(8, value, this); }
    adoptNodePublicKey(value: capnp.Orphan<capnp.Data>): void { __S.adopt(value, __S.getPointer(0, this)); }
    disownNodePublicKey(): capnp.Orphan<capnp.Data> { return __S.disown(this.getNodePublicKey()); }
    getNodePublicKey(): capnp.Data { return __S.getData(0, this); }
    hasNodePublicKey(): boolean { return !__S.isNull(__S.getPointer(0, this)); }
    initNodePublicKey(length: number): capnp.Data { return __S.initData(0, length, this); }
    setNodePublicKey(value: capnp.Data): void { __S.copyFrom(value, __S.getPointer(0, this)); }
    adoptSenderPublicKey(value: capnp.Orphan<capnp.Data>): void { __S.adopt(value, __S.getPointer(1, this)); }
    disownSenderPublicKey(): capnp.Orphan<capnp.Data> { return __S.disown(this.getSenderPublicKey()); }
    getSenderPublicKey(): capnp.Data { return __S.getData(1, this); }
    hasSenderPublicKey(): boolean { return !__S.isNull(__S.getPointer(1, this)); }
    initSenderPublicKey(length: number): capnp.Data { return __S.initData(1, length, this); }
    setSenderPublicKey(value: capnp.Data): void { __S.copyFrom(value, __S.getPointer(1, this)); }
    adoptReceiverAccount(value: capnp.Orphan<capnp.Data>): void { __S.adopt(value, __S.getPointer(2, this)); }
    disownReceiverAccount(): capnp.Orphan<capnp.Data> { return __S.disown(this.getReceiverAccount()); }
    getReceiverAccount(): capnp.Data { return __S.getData(2, this); }
    hasReceiverAccount(): boolean { return !__S.isNull(__S.getPointer(2, this)); }
    initReceiverAccount(length: number): capnp.Data { return __S.initData(2, length, this); }
    setReceiverAccount(value: capnp.Data): void { __S.copyFrom(value, __S.getPointer(2, this)); }
    getAmount(): capnp.Uint64 { return __S.getUint64(16, this); }
    setAmount(value: capnp.Uint64): void { __S.setUint64(16, value, this); }
    toString(): string { return "UutData_" + super.toString(); }
}
export class UUT extends __S {
    static readonly _capnp = { displayName: "UUT", id: "a914e54e40115d23", size: new __O(0, 2) };
    adoptUutData(value: capnp.Orphan<UutData>): void { __S.adopt(value, __S.getPointer(0, this)); }
    disownUutData(): capnp.Orphan<UutData> { return __S.disown(this.getUutData()); }
    getUutData(): UutData { return __S.getStruct(0, UutData, this); }
    hasUutData(): boolean { return !__S.isNull(__S.getPointer(0, this)); }
    initUutData(): UutData { return __S.initStructAt(0, UutData, this); }
    setUutData(value: UutData): void { __S.copyFrom(value, __S.getPointer(0, this)); }
    adoptSenderSignature(value: capnp.Orphan<capnp.Data>): void { __S.adopt(value, __S.getPointer(1, this)); }
    disownSenderSignature(): capnp.Orphan<capnp.Data> { return __S.disown(this.getSenderSignature()); }
    getSenderSignature(): capnp.Data { return __S.getData(1, this); }
    hasSenderSignature(): boolean { return !__S.isNull(__S.getPointer(1, this)); }
    initSenderSignature(length: number): capnp.Data { return __S.initData(1, length, this); }
    setSenderSignature(value: capnp.Data): void { __S.copyFrom(value, __S.getPointer(1, this)); }
    toString(): string { return "UUT_" + super.toString(); }
}
