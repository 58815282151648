export enum EventStyles {
    BLACK = "black",
    GEEQ_USER = "#60bcd0",
    GEEQ_NODE = "#3d94c3",
    GEEQ_CORP = "#479997",
    EVENT_NUMBER = "#D66339"
}

/* 
Red/orange: #d66339
Medium blue: #3d94c3
Light Blue: #60bcd0
Light Grey:#939598
Dark Grey/ Black: #414042
*/

export default { EventStyles }