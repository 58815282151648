/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getRandomOctet() {
    return getRandomInt(1,255);
}

function getRandomIPAddr() {
    return getRandomOctet() + '.' + getRandomOctet() + '.' + getRandomOctet() + '.' + getRandomOctet();
}
    
export { getRandomIPAddr };