<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header"></slot>
          </div>
          <div class="modal-body-top">
            <slot name="body"></slot>
            <!-- Json vs Raw format checkbox -->
            <div class="raw-check-box">
              <input type="checkbox" v-model="showRawData" />&nbsp;Show Raw
              Cap'n Proto Message ({{ wrapper.sourceBuffer.byteLength }} bytes)
            </div>
          </div>
          <div class="modal-body-bottom">
            <div v-if="validWrapper">
              <!-- <div>{{ event.description }}</div> -->
              <div v-if="showJSON">
                <!-- Message in JSON format -->
                <vue-json-pretty
                  style="height: 200px"
                  :virtual="virtualFlag"
                  :data="wrapper.json"
                  :show-line="showLine"
                  :collapsed-on-click-brackets="collapsedOnClickBrackets"
                />
              </div>
              <div v-else>
                <!-- Message in raw format -->
                <RawInspector :raw="wrapper.sourceBuffer" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button
                class="modal-default-button"
                @click="closeWindow(itemIndex)"
              >
                Close
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { Wrapper } from "@/models/capnpMessages/WrapperInterface";
import RawInspector from "./RawMessageInspector";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import $ from "jquery";
export default {
  emits: ["close"],
  name: "MessageDetail",

  props: {
    wrapper: Wrapper,
    itemIndex: Number,
  },
  data() {
    return {
      showRawData: false,

      // vue-json-pretty settings
      virtualFlag: false,
      showLine: true,
      collapsedOnClickBrackets: true,
    };
  },
  computed: {
    showJSON() {
      return !this.showRawData;
    },
    validWrapper() {
      return this.wrapper != undefined;
    },
  },
  methods: {
    closeWindow(itemIndex) {
      this.$emit("close", itemIndex);
      $('.modal-mask').hide();
      
    },
  },
  components: {
    VueJsonPretty,
    RawInspector,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
