// Capnp messages
import { HtbData } from "@/generated/htb.capnp";

// Capnp message wrappers
import { GenesisBlockWrapper } from "@/models/capnpMessages/GenesisBlockWrapper";
import { UutWrapper } from "@/models/capnpMessages/UutWrapper";
import { NtbWrapper } from "@/models/capnpMessages/NtbWrapper";
import { HtbWrapper } from "@/models/capnpMessages/HtbWrapper";
import { ClsWrapper, getClsRaw } from "@/models/capnpMessages/ClsWrapper";
import { getClsJSON } from "@/models/capnpMessages/ClsJson"

// Model utilities
import { LogEvent, logTypeEnum, LogFullDescriptionEnum } from "@/models/LogEvent";

// Actor related
import { NonGeeqCorpActor, ACTOR_TYPE } from "../Actor";
import { NETWORK_API, API_RETURN_CODE } from "@/models/TheNetwork";

// Geeq Node related
import { BlockChain } from "./nodeutils/BlockChain";
import { NodeTxBundleMgr } from "./nodeutils/NodeTxBundleMgr";
import { NodeState } from "./nodeutils/NodeState";
import { STATES_TYPE } from "./nodeutils/NodeState";
import { trucatedBuf2hex } from "../formatUtils";
import { HubTxBundleMgr } from "./nodeutils/HubTxBundleManager";
import { NodeCommunicator } from "./nodeutils/NodeCommunicator";
import { NodeBehaviors } from "./nodeutils/NodeBehaviors";

// Components
import { EventStyles } from "@/components/LogSytle"

// Resources
import { InfoButtonText } from "@/assets/resources"
import { arrayBufferEquals } from "../arrayUtils";
import { GeeqUser } from "./GeeqUser";

const BAD_BEHAVIOR_START_BLOCK = 2;

class GeeqNode extends NonGeeqCorpActor {
  // Getter private instance vars
  private _myBlockChain: BlockChain | null = null;
  // Private instance vars
  private simulationTime: number; // In simulation seconds
  private lastWaitForTxTime: number | undefined = undefined;
  private lastWaitForNtbsTime: number | undefined = undefined;
  // private ntbTimeInterval: number;
  private waitForNtbInterval: number;
  private waitForTxInterval: number;
  private nodeBundleTxMgr: NodeTxBundleMgr; // Stores UUTs for the next block
  private hubBundleTxMgr: HubTxBundleMgr;
  private myNodeState: NodeState; // State machine
  //private _feePublicAccount: ArrayBuffer | null = null;
  //private _feeKeys: ECKeys;
  private _nodeUser: GeeqUser;
  private _behaviors: NodeBehaviors;
  private _dishonestTarget: GeeqUser;
  private _dishonestUser: GeeqUser;
  private _partitionName = "";

  private _badBehaviorStartBlock = BAD_BEHAVIOR_START_BLOCK;
  private _isProvenDishonest = false;

  /*
   * Properties
   */
  get badBehaviorStartBlock() {
    return this._badBehaviorStartBlock
  }

  get partitionName() {
    return this._partitionName
  }

  set partitionName(value) {
    this._partitionName = value
  }

  get feePublicAccountShortFormat() {
    return this._nodeUser.publicAccountShortFormat
  }

  get behaviors() {
    return this._behaviors
  }

  get feeUser(): GeeqUser {
    return this._nodeUser
  }

  get targetUser(): GeeqUser {
    return this._dishonestTarget
  }
  
  get dishonestUser(): GeeqUser {
    return this._dishonestUser
  }

  get isHonest() {
    return this._behaviors == NodeBehaviors.HONEST;
  }

  get isProvenDishonest() {
    return this._isProvenDishonest
  }

  set isProvenDishonest(value) {
    this._isProvenDishonest = value
  }
  
  get isProvablyDishonest() {
    if (this.myBlockChain?.currentBlockNumber != null) {
      return !this.isHonest && this.myBlockChain.currentBlockNumber > this._badBehaviorStartBlock
    } else {
      return false
    }
  }

  get myBlockChain() {
    return this._myBlockChain;
  }

  get nodePublicKey() {
    return this.publicKey;
  }

  get acceptedTxCount() {
    return this.nodeBundleTxMgr.acceptedUUTs.length;
  }

  //Returns true if is node is in the HBO
  get isInHBO() {
    return this.isInHubBlockOrder();
  }

  get state() {
    return this.myNodeState.state;
  }

  get numBlocks() {
    return this.myBlockChain?.currentBlockNumber;
  }

  get clsWrapper(): ClsWrapper {
    return new ClsWrapper(this.getClsRaw)
  }

  get getClsRaw(): ArrayBuffer {
    if (this.myBlockChain !== null) {
      const currentCLS = this.myBlockChain.currentCLS
      if (currentCLS !== null) {
        return getClsRaw(currentCLS)
      } else {
        throw('Missing current CLS')
      }
    } else {
      throw('Missing block chain')
    }
  }
  get clsJSON() {

    if (this.myBlockChain !== null) {
      const currentCLS = this.myBlockChain.currentCLS
      if (currentCLS !== null) {
        return getClsJSON(currentCLS)
      } else {
        throw('Missing current CLS')
      }
    } else {
      throw('Missing block chain')
    }
  }

  get isHub() {
    if (this.publicKey == null) {
      return false;
    }

    if (this._myBlockChain !== null) {
      if (this._myBlockChain.currentHubNodePublicKey !== null) {
        return arrayBufferEquals(this.publicKey, this._myBlockChain.currentHubNodePublicKey);
      } else {
        throw "Missing current hub node number";
      }
    } else {
      throw "No blockchain found";
    }
  }

  /**
   * Returns the block number that the currently constructed NTB is targeted for.  This is either
   * the next block number or the one after that, depending on whether the NTB has been sent for the
   * current block.
   */
  get ntbTargetBlockNumber(): number {
    if (this.myBlockChain !== null) {
      if (this.myBlockChain.currentBlockNumber != null) {
        const currentBlockNumber = this.myBlockChain.currentBlockNumber;

        switch (this.myNodeState.state) {
          case STATES_TYPE.WAIT_FOR_TXS:
            return currentBlockNumber + 1;

          // non-hubs
          case STATES_TYPE.SEND_NTB:
          case STATES_TYPE.WAIT_FOR_HTB:
            return currentBlockNumber + 2;

          // hubs
          case STATES_TYPE.WAIT_FOR_NTBS:
          case STATES_TYPE.SEND_HTB:
            return currentBlockNumber + 2;
            break;

          default:
            // Not initialized yet
            return 1; // first block
        }
      } else {
        throw "missing current block number";
      }
    } else {
      throw "missing block chain";
    }
  }

  /*
   * API calls
   */

  // Non-hub node processing HTB
  public async apiProcessHTB(htbBuffer: ArrayBuffer) {
    const htbWrapper = new HtbWrapper(htbBuffer);
    const htb = htbWrapper.htb;
    const htbData: HtbData = htb.getHtbData()

    // Log processing HTB
    this.addToLog(
      `API Received HTB (1) message size = ${htbBuffer.byteLength} bytes`, htbWrapper, InfoButtonText.HTB, LogFullDescriptionEnum.HTB
    );

    this.addToLog(`Received HTB (2) block number = ${htbData.getBlockNumber()}`, null)
    const ntbs = htbData.getNodeTxBundles()
    this.addToLog(`Received HTB (3) number of NTBs = ${ntbs.getLength()}`, null)

    // DEBUG logging
    // for (let i=0; i < ntbs.getLength(); i ++) {
    //   const ntb: NTB = ntbs.get(i)
    //   const ntbData: NtbData = ntb.getNtbData()
    //   const uuts = ntbData.getUnverifiedUserTransactions()
    //   // if (this._debug_ntbs) {
    //   //   this.logger.log(`- number of UUTs ${uuts.length}`)
    //   // }
    // }

    // Process HTB
    const success = await this.processHTB(htbWrapper)
    if (success) {
      return API_RETURN_CODE.RC_400;
    } else {
      return API_RETURN_CODE.RC_400;
    }
  }

  // Process HTB whether it arrived via the api or was generated internally by the hub
  private async processHTB(htbWrapper: HtbWrapper): Promise<boolean> {
    const success = await this.hubBundleTxMgr.processHTB(htbWrapper)
    if (success) {
      if (this.isInHBO) {
        this.myNodeState.updateState(STATES_TYPE.WAIT_FOR_TXS)
      } else {
        // Not in HBO, don't process transactions, just wait for next HTB
        this.myNodeState.updateState(STATES_TYPE.WAIT_FOR_HTB)
      }
    }
    return success
  }
  
  public async apiProcessUUT(uutBuffer: ArrayBuffer) {
    const uutWrapper = new UutWrapper(uutBuffer);
    const uut = uutWrapper.uut;

    this.addToLog(
      `API received UUT (message size = ${uutBuffer.byteLength} bytes)`,
      uutWrapper,
      InfoButtonText.UUT,
      LogFullDescriptionEnum.UUT
    );

    const userPublicKey = uut
      .getUutData()
      .getSenderPublicKey()
      .toArrayBuffer();

    const isValidSignature = await uutWrapper.verifySignature(userPublicKey);
    if (isValidSignature) {
      this.nodeBundleTxMgr.processUUT(uut);
      return API_RETURN_CODE.RC_200;
    } else {
      this.nodeBundleTxMgr.recordUUTWithBadSignature(uut);
      return API_RETURN_CODE.RC_400;
    }
  }

  public async apiProcessNTB(ntbBuffer: ArrayBuffer) {
    if (this.isHub) {
      // Only the Hub processes NTBs
      const ntbWrapper = new NtbWrapper(ntbBuffer);
      const ntb = ntbWrapper.ntb;

      const ntbData = ntb.getNtbData();

      // Verify signature matches embedded public key of user
      const ntbNodePublicKey = ntbData.getNodePublicKey().toArrayBuffer();

      if (this.myBlockChain != null) {

        if (ntbNodePublicKey != null) {
          const isValidSignature = await ntbWrapper.verifySignature(ntbNodePublicKey);
          if (!isValidSignature) {
            const publicKeyShortFormat = trucatedBuf2hex(ntbNodePublicKey);

            // Log api call
            this.addToLog(
              `API Process NTB with invalid signature for node ${publicKeyShortFormat}`,
              ntbWrapper,
              InfoButtonText.NTB,
              LogFullDescriptionEnum.NTB
            );            
            return API_RETURN_CODE.RC_400;

          } else {
            // Signature is valid, so continue processing NTB

            // Get number of UUTs for logging
            const numUuts = ntbWrapper.numUuts;

            if (numUuts !== undefined) {
              // Log api call
              this.addToLog(
                `API Process NTB with valid signature (num UUTs = ${numUuts}; message size = ${ntbBuffer.byteLength} bytes)`,
                ntbWrapper,
                InfoButtonText.NTB,
                LogFullDescriptionEnum.NTB
              );

              // Forward NTB for processing
              await this.processNTB(ntbWrapper);
              return API_RETURN_CODE.RC_200;

            } else {
              throw "Missing ntb";
            }
          }
        } else {
          // Log api call
          this.addToLog(
            `API ProcessNTB with unknown node`,
            ntbWrapper
          );
        }
      } else {
        throw "Missing block chain";
      }
    } else {
      // Non-hub received an NTB
      throw "API ProcessNTB called on non-Hub node - ignore NTB.";
    }
  }

  // // TODO:  Create a new capnp message with signature.  For now just return CLS w/o message.
  // //
  // public apiGetCLS(): ArrayBuffer | null {
  //   if (this.genesisBlockWrapper !== null) {
  //     // Get existing cls
  //     const genesisBlock: GenesisBlock = this.genesisBlockWrapper.genesisBlock;
  //     const cls = genesisBlock.getBlockData().getCls();

  //     const resultMessage = new Message();
  //     const resultGenesisBlock = resultMessage.initRoot(GenesisBlock);

  //     const resultArray = resultMessage.toArrayBuffer();
  //     return resultArray;
  //   } else {
  //     return null;
  //   }
  // }

  /*
   * Simulation methods
   */
  startSimulation() {
    this.simulationTime = 0; // Start time is always 0
    this.myNodeState.updateState(STATES_TYPE.WAIT_FOR_TXS);
  }

  resetSimulation() {
    this.simulationTime = 0; // Start time is always 0
    this.myNodeState.updateState(STATES_TYPE.WAIT_FOR_SIM_START);
  }

  updateClock(
    newTick: number,
    oldTick: number,
    timeMultiplier: number
  ): boolean {
    this.simulationTime += (newTick - oldTick) * timeMultiplier;

    // Transition to Send_NTB only when time is up while in WAIT_FOR_TXS state.
    if (this.myNodeState.state == STATES_TYPE.WAIT_FOR_TXS) {
      if (this.lastWaitForTxTime !== undefined) {
        if (
          this.simulationTime - this.lastWaitForTxTime >
          this.waitForTxInterval
        ) {
          this.myNodeState.updateState(STATES_TYPE.SEND_NTB);
          this.lastWaitForTxTime = undefined;
          return true; // Internal state changed
        } 
      } else {
          throw "Undefined lastWaitForTxTime";
      }
    } else if (this.myNodeState.state == STATES_TYPE.WAIT_FOR_NTBS) {
      if (this.lastWaitForNtbsTime !== undefined) {
        if (
          this.simulationTime - this.lastWaitForNtbsTime >
          this.waitForNtbInterval
        ) {
          this.myNodeState.updateState(STATES_TYPE.SEND_HTB);
          this.lastWaitForNtbsTime = undefined;
          return true; // Internal state changed
        } 
      } else {
          throw "Undefined lastWaitForNtbsTime";
      } 
    }
    return false; // Internal state unchanged
  }

  /*
   * Constructor
   */
  constructor(
    nodeUser: GeeqUser,
    geeqCorpPublicKey: Uint8Array,
    /* ntbTimeInterval: number, */
    waitForTxInterval: number,
    waitForNtbInterval: number,
    loggerFn: { (event: LogEvent): void },
    behaviors: NodeBehaviors,
    dishonestTarget: GeeqUser,
    dishonestUser: GeeqUser
  ) {
    super(geeqCorpPublicKey, loggerFn);

    this._dishonestUser = dishonestUser;
    this._dishonestTarget = dishonestTarget;
    this._behaviors = behaviors;
    this._nodeUser = nodeUser;
    this._myBlockChain = new BlockChain(this);
    this.simulationTime = 0;
    this.lastWaitForTxTime = undefined;
    this.lastWaitForNtbsTime = undefined;
    // this.ntbTimeInterval = ntbTimeInterval;
    this.waitForNtbInterval = waitForNtbInterval;

    this.waitForTxInterval = waitForTxInterval;
    this.actorType = ACTOR_TYPE.GEEQ_NODE;
    this.myLogEventType = logTypeEnum.GEEQ_NODE;

    // Event Log styling
    this._eventColor = EventStyles.GEEQ_NODE;

    // Stores UUTs for the next block
    this.nodeBundleTxMgr = new NodeTxBundleMgr(this);

    // Stores NTBs for the next block
    this.hubBundleTxMgr = new HubTxBundleMgr(this);

    this.myNodeState = new NodeState();

    // // Create key pair for collecting fees
    // this._feeKeys = nodeUser.keys;

    // Register the callbacks that are triggered on state changes
    this.registerStateChangeCallbacks();
    return;
  }

  // Create an additional key pair for the fee collection account
  // async initKeys() {
  //   super.initKeys()

  //   // Create the key pair for the key account
  //   await this._feeKeys.newKeyPair();

  // }

  // Meant for UI to register callback when state changes
  registerAnyStateChangeCallback(fn: unknown) {
    this.myNodeState.registerAnyStateChangeCallback(fn);
  }

  /*
   * Geeq Node state transition methods
   */

  // Register the callbacks that are triggered on state changes
  registerStateChangeCallbacks() {
    this.myNodeState.registerStateCallback(STATES_TYPE.WAIT_FOR_SIM_START, () =>
      this.transitionToWaitForSimStart()
    );
    this.myNodeState.registerStateCallback(STATES_TYPE.WAIT_FOR_TXS, () =>
      this.transitionToWaitForTx()
    );
    this.myNodeState.registerStateCallback(STATES_TYPE.SEND_NTB, () =>
      this.transitionToSendNTB()
    );
    this.myNodeState.registerStateCallback(STATES_TYPE.WAIT_FOR_NTBS, () =>
      this.transitionToWaitForNTBs()
    );
    this.myNodeState.registerStateCallback(STATES_TYPE.SEND_HTB, () =>
      this.transitionToSendHTB()
    );
  }

  transitionToWaitForSimStart() {
    // Clear out all node state
    this.resetAllNodeState();
    //console.log(`${this.publicKeyShortFormat} : transitionToWaitForSimStart`);
  }

  transitionToWaitForTx() {
    // Record when we started to wait for UUTs
    this.lastWaitForTxTime = this.simulationTime;
    //console.log(`${this.publicKeyShortFormat} : transitionToWaitForTx`);
  }

  transitionToSendNTB() {
    this.internalCreateNTB();
    //console.log(`${this.publicKeyShortFormat} : transitionToSendNTB`);
  }

  transitionToWaitForNTBs() {
    this.lastWaitForNtbsTime = this.simulationTime;
    //console.log(`${this.publicKeyShortFormat} : transitionToWaitForNTBs`);
  }

  transitionToSendHTB() {
    this.internalCreateHTB();
    //console.log(`${this.publicKeyShortFormat} : transitionToSendHTB`);
  }

  resetAllNodeState() {
    this.simulationTime = 0;

    if (this.myBlockChain !== null) {
      this.myBlockChain.reset();
    }

    this.myNodeState.updateState(STATES_TYPE.UNINITIALIZED);
    this.nodeBundleTxMgr.resetState();
  }

  /*
   * Other methods
   */

  async initGenesisBlock(genesisBlockWrapper: GenesisBlockWrapper) {
    await super.initGenesisBlock(genesisBlockWrapper);
    this.myNodeState.updateState(STATES_TYPE.WAIT_FOR_SIM_START);
    await this.startChain(genesisBlockWrapper);

    // After the block is initialized, we can check for the hub node
    if (this.isHub) {
      this.myLogEventType = logTypeEnum.GEEQ_HUB_NODE;
    }

    if (this._myBlockChain != null) {
      const genesisBlock = genesisBlockWrapper.genesisBlock
      const genesisBlockData = genesisBlock.getBlockData()
      const cls = genesisBlockData.getCls()

      await this._myBlockChain.initGenesisBlockCLS(cls)
    } else {
      throw("Missing block chain in node")
    }
    
  }

  private async internalCreateHTB() {
    if (this.isHub) {
      const htbWrapper: HtbWrapper = await this.hubBundleTxMgr.createHTBWrapper();
      await htbWrapper.signHTB(this.keys);  // Sign with the hub keys

      this.addToLog('HTB generated', htbWrapper, InfoButtonText.HTB, LogFullDescriptionEnum.HTB)

      this.addToLog('Send HTB to Active Node List')

      // Send HTB to all the non-hub nodes in the ANL
      const nodeComm = new NodeCommunicator(this)
      if (this._myBlockChain !== null) {
        nodeComm.sendHTBToANLMinusHub(this._myBlockChain, htbWrapper);
      }
      
      // Process HTB locally on the hub node
      await this.processHTB(htbWrapper)

      // // TODO: What to do if this generates an error?
      // this.hubBundleTxMgr.processHTB(htbWrapper)
  
      // // Since HTB has been processed locally, we can go back to waiting for transactions for the next block
      // this.myNodeState.updateState(STATES_TYPE.WAIT_FOR_TXS)
    } else {
      throw('Non-hub requested to create HTB')
    }
  }

  /**
   * Construct NTB from current list of sanitized UUTs.  Called when timer is triggered to create NTB.
   *
   */
  private async internalCreateNTB() {
    // Create and sign NTB
    const ntbWrapper: NtbWrapper = await this.nodeBundleTxMgr.createNTBWrapper();
    await ntbWrapper.signNTB(this.keys);

    // If this node is the hub, then process the NTB locally. Otherwise forward NTB to the Hub
    if (this.isHub) {

      // Log processing NTB on hub
      const numUuts = ntbWrapper.numUuts;
      if (numUuts != undefined) {
        // Log api call
        this.addToLog(
          `Processing self generated NTB (num UUTs = ${numUuts})`, // ; message size = ${ntbWrapper.byteLength} bytes)`
          ntbWrapper,
          InfoButtonText.NTB,
          LogFullDescriptionEnum.NTB
        );
      } else {
        throw "Missing ntb";
      }

      // Process NTB internally
      await this.processNTB(ntbWrapper);

      // Since we are on the hub, we are now waiting to receive the NTBs from other nodes
      this.myNodeState.updateState(STATES_TYPE.WAIT_FOR_NTBS);
    } else {

      // Send NTB to hub
      if (this.myBlockChain !== null) {
        this.sendNTBToHub(ntbWrapper);

        // Now that the NTB has been sent to the hub, wait for the HTB from the hub
        this.myNodeState.updateState(STATES_TYPE.WAIT_FOR_HTB);
      } else {
        throw "Mising blockchain";
      }
    }
  }

  get hubIpAddr(): string {
    if (this.myBlockChain != null) {
      return this.myBlockChain.hubIpAddr
    } else {
      throw "Missing block chain";
    }
  }

  sendNTBToHub(ntbWrapper: NtbWrapper) {
    // TODO:  Fix this when we maintain the latest known CLS
    if (this.myBlockChain != null) {
      if (this.myBlockChain.currentHubNodePublicKey !== null) {
        const targetIpAddr = this.hubIpAddr;

        if (targetIpAddr !== null) {
          if (this.theNetwork != null) {
            this.theNetwork.apiCall(
              targetIpAddr,
              this,
              NETWORK_API.NODE_PROCESS_NTB,
              ntbWrapper.sourceBuffer
            );
          }
        } else {
          throw "Null ip address";
        }
      } else {
        throw "Missing hub node number";
      }
    } else {
      throw "Missing block chain";
    }
  }

  private async processNTB(ntbWrapper: NtbWrapper) {

    // Check to see node is ready to process NTBs
    switch (this.state) {
      // case STATES_TYPE.WAIT_FOR_HTB:  //  TODO: Obsolete? - Has created local NTB, can accept other NTBs
      case STATES_TYPE.WAIT_FOR_TXS: // Has not yet created local NTB, can accept other NTBs
      case STATES_TYPE.SEND_NTB: // Hub can be in this state (send an NTB to itself, but not through network)
      case STATES_TYPE.WAIT_FOR_NTBS: // Has created local NTB, can accept other NTBs
        return await this.hubBundleTxMgr.processNTB(ntbWrapper);

      case STATES_TYPE.SEND_HTB:
        this.addToLog("Process NTB unexpected state SEND_HTB ", ntbWrapper);
        return false;

      default:
        // Not ready to receive transactions (e.g. REGISTERING state)
        this.addToLog("Process NTB unexpected state (default) ", ntbWrapper);
        return false;
    }
  }

  /**
   * Starts the geekchain with the genesis block passed in.  Saves the genesis block to disk.
   */
  private async startChain(genesisBlockWrapper: GenesisBlockWrapper) {
    const validGenesisBlock = await genesisBlockWrapper.verifySignature(
      this.geeqCorpPublicKey
    );
    if (!validGenesisBlock) {
      return false;
    }

    if (this.myBlockChain) {
      const success = this.myBlockChain.saveGenesisBlock(genesisBlockWrapper);
      if (!success) {
        return false;
      }
    } else {
      throw "Missing blockchain";
    }

    // TODO:  Make sure this is obsolete before deleting
    //
    // // Determine the node number of this node based on the CLS
    // if (this.myBlockChain.currentCLS) {
    //   if (!this.updateNodeNumber(this.myBlockChain.currentCLS)) {
    //     return false;
    //   }
    // } else {
    //   throw "Missing current CLS";
    // }

    if (this.isInHBO) {
      this.myNodeState.updateState(STATES_TYPE.WAIT_FOR_TXS);
    } else {
      // Not in the HBO, just wait for HTB to process
      this.myNodeState.updateState(STATES_TYPE.WAIT_FOR_HTB);
    }
    return true;
  }

  // TODO:  Make this more efficient than a linear search through the HBO list
  private isInHubBlockOrder(): boolean {
    if (this.myBlockChain != null) {
      if (this.myBlockChain.currentCLS != null) {
        if (this.publicKey != null) {
          const cls = this.myBlockChain.currentCLS;
          const hboList = cls.getHubOrderList();
          const activeNodeList = cls.getActiveNodeList()

          for (let i=0; i < hboList.getLength(); i++) {
            const activeNodeRecordIndex = hboList.get(i)
            const activeNodeRecord = activeNodeList.get(activeNodeRecordIndex)
            const publicKey = activeNodeRecord.getNodePublicKey().toArrayBuffer()
            if (arrayBufferEquals(this.publicKey, publicKey)) {
              return true;
            }
          }  
          return false;
        } else {
          throw("Missing node public key")
        }
      } else {
        throw "Missing current cls";
      }
    } else {
      throw "Missing block chain";
    }
  }

  // TODO:  Make sure this is obsolete before deleting
  //
  // /**
  //  * Determines the node number of this node.  Searches for a matching entry in the ANL of the CLS passed in.
  //  */
  // private updateNodeNumber(cls: CLS): boolean {
  //   const anl: ActiveNodeRecord[] = cls.getActiveNodeList().toArray();
  //   if (anl == null) return false;

  //   // Returns true if there is a match with ip address and port for a node in the ANL
  //   for (let i = 0; i < anl.length; i++) {
  //     const activeNodeRecord = anl[i];
  //     // const r1 = activeNodeRecord.getIpAddr()
  //     // const r2 = this.ipaddr

  //     if (activeNodeRecord.getIpAddr() == this.ipaddr) {
  //       this._nodePublicKey = activeNodeRecord.getNodeNumber();
  //       return true;
  //     }
  //   }

  //   // No matches found in the ANL
  //   return false;
  // }
}

export { GeeqNode };
