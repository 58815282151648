// Libraries
import { Message } from "capnp-ts"

// Messages
import { UUT } from "@/generated/uut.capnp"
import { NTB } from "@/generated/ntb.capnp"
import { HTB } from "@/generated/htb.capnp";
import { Block } from "@/generated/block.capnp";

/*
 * Instantiates a UUT block message from a buffer.
 */
export function uutFromBuffer(buffer: ArrayBuffer) {
  const message = new Message(buffer);
  return message.getRoot(UUT);
}

/*
 * Instantiates a NTB block message from a buffer.
 */
export function ntbFromBuffer(buffer: ArrayBuffer) {
  const message = new Message(buffer);
  return message.getRoot(NTB);
}

/*
 * Instantiates a HTB block message from a buffer.
 */
export function htbFromBuffer(buffer: ArrayBuffer) {
  const message = new Message(buffer);
  return message.getRoot(HTB);
}

/*
 * Instantiates a Block block message from a buffer.
 */
export function blockFromBuffer(
  buffer: ArrayBuffer
) {
  const message = new Message(buffer);
  return message.getRoot(Block);
}


export default { uutFromBuffer, ntbFromBuffer, htbFromBuffer, blockFromBuffer }