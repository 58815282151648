import { BlockChain } from "@/models/actors/nodeutils/BlockChain";
import { ActiveNodeRecord } from "@/generated/cls.capnp";
import { HtbWrapper } from "@/models/capnpMessages/HtbWrapper";
import { GeeqNode } from "../GeeqNode";
import { NETWORK_API } from "@/models/TheNetwork";
import { arrayBufferEquals } from "@/models/arrayUtils";

export default class NodeCommunicator {
  node: GeeqNode;
  constructor(node: GeeqNode) {
    this.node = node;
  }

  //async sendNTBToHub(blockChain: BlockChain, ntb: NTB) {

  //    console.log("Partial Implementation - SendNTBToHub")
  //   let hub_url : string = blockChain.hub_url
  //   let ntb_buffer : Uint8Array = ntb.serializeBinary()

  //   return new Promise<string>((resolve, reject) =>
  //   {
  //       let headers = {
  //         'Content-Type': 'application/octet-stream'
  //       }

  //       if (hub_url == null) {
  //           reject("Uninitialized hub url")
  //       }

  //       request({
  //         url: hub_url + '/process_ntb',
  //         method: 'POST',
  //         body: ntb_buffer,
  //         encoding: null,
  //         headers: headers,
  //         json: false,

  //       }, (error, response, body) => {
  //         if (error) {
  //           reject(error)
  //         } else {
  //           resolve(response.body)
  //         }
  //       })
  //   })
  //}

  async sendHTBToANLMinusHub(blockChain: BlockChain, htbWrapper: HtbWrapper) {
    const htbBuffer: ArrayBuffer = htbWrapper.sourceBuffer;

    return new Promise<boolean>((resolve, reject) => {
      const cls = blockChain.currentCLS;

      if (cls !== null) {
        const anl = cls.getActiveNodeList();

        // Send HTB to each node in the ANL except the Hub
        anl.forEach((activeNodeRecord: ActiveNodeRecord) => {
          if (blockChain.currentHubNodePublicKey !== null) {
              if (arrayBufferEquals(activeNodeRecord.getNodePublicKey().toArrayBuffer(), blockChain.currentHubNodePublicKey)) {
                // Skip the hub
                return;
              }
          }

          const targetIpAddr = activeNodeRecord.getIpAddr();

          if (targetIpAddr !== null) {
            if (this.node.theNetwork != null) {

              this.node.theNetwork.apiCall(
                targetIpAddr,
                this.node,
                NETWORK_API.NODE_PROCESS_HTB,
                htbBuffer
              );
            }
          } else {
            throw "Null ip address";
          }

          //const node_url = 'http://' +  + '/process_htb'

          //   request({
          //     url: node_url,
          //     method: 'POST',
          //     body: htbBuffer,
          //     encoding: null,
          //     headers: headers,
          //     json: false,
          //   }, (error, response, body) => {
          //     if (error) {
          //       // TODO: Log error.  Also should consider retries.
          //       console.log(`Unable to send HTB to node (${node_url}).  ${error}`)
          //       return
          //     } else {
          //       return
          //     }
          //   })
        }); // end forEach

        // Fire and forget, so just return true w/o waiting for response from nodes
        resolve(true);
      }
    });
  }
}

export { NodeCommunicator };
