import { UUT } from "@/generated/uut.capnp";
import { trucatedBuf2hex, buf2hex } from "@/models/formatUtils";
import { uutFromBuffer } from "@/models/capnpMessages/MessageFromBuffer"

/*
 * Transforms the contents of the UUT to a human readable JSON format.
 */
export function uutJsonFromUUT(uut: UUT): Record<string, unknown> {
  // Signature
  const signature = uut.getSenderSignature();
  const signatureBuffer: ArrayBuffer = signature.toArrayBuffer();
  const signatureText = buf2hex(signatureBuffer);

  const uutData = uut.getUutData();

  // Header
  const chainNumber = uutData.getChainNumber();
  const blockNumber = uutData.getBlockNumber();
  const userTxNumber = uutData.getUserTxNumber();
  const nodePublicKey = trucatedBuf2hex(uutData.getNodePublicKey().toArrayBuffer())
  
  // Accounts and amount
  const senderPublicKey = trucatedBuf2hex(
    uutData.getSenderPublicKey().toArrayBuffer()
  );
  const receiverAccount = trucatedBuf2hex(
    uutData.getReceiverAccount().toArrayBuffer()
  );
  const amount = uutData.getAmount().toNumber();

  // TODO - prev cls hash

  return {
    uutData: {
      chainNumber: chainNumber,
      blockNumber: blockNumber,
      userTxNumber: userTxNumber,
      nodePublicKey: nodePublicKey,

      senderPublicKey: senderPublicKey,
      receiverAccount: receiverAccount,
      amount: amount,
    },
    Signature: signatureText,
  };
}

/*
 * Transforms the contents of the UTB from the capnp message format
 * in an ArrayBuffer to a human readable JSON format.
 */
export function uutJson(buffer: ArrayBuffer): Record<string, unknown> {
  const uut: UUT = uutFromBuffer(buffer);
  return uutJsonFromUUT(uut);
}

export default { uutJsonFromUUT, uutJson };
