/* tslint:disable */

/**
 * This file has been automatically generated by the [capnpc-ts utility](https://github.com/jdiaz5513/capnp-ts).
 */

import * as capnp from "capnp-ts";
import { ObjectSize as __O, Struct as __S } from 'capnp-ts';
export const _capnpFileId = "f7971d9a031c6f9e";
export class AuditAdminRecord extends __S {
    static readonly _capnp = { displayName: "AuditAdminRecord", id: "9e13b431c3dbbb94", size: new __O(0, 1) };
    adoptDishonestNodeList(value: capnp.Orphan<capnp.List<number>>): void { __S.adopt(value, __S.getPointer(0, this)); }
    disownDishonestNodeList(): capnp.Orphan<capnp.List<number>> { return __S.disown(this.getDishonestNodeList()); }
    getDishonestNodeList(): capnp.List<number> { return __S.getList(0, capnp.Uint32List, this); }
    hasDishonestNodeList(): boolean { return !__S.isNull(__S.getPointer(0, this)); }
    initDishonestNodeList(length: number): capnp.List<number> { return __S.initList(0, capnp.Uint32List, length, this); }
    setDishonestNodeList(value: capnp.List<number>): void { __S.copyFrom(value, __S.getPointer(0, this)); }
    toString(): string { return "AuditAdminRecord_" + super.toString(); }
}
