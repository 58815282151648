<template>
  <div class="main-content-area">
    <div class="control-bar">
      <div class="inner-margin">
        <div class="control-bar-left">
          <h1>Testing Framework v0.1</h1>
        </div>
        <!-- end control-bar-left -->

        <div class="control-bar-right">
          <div class="timer-block-wrap">
            <div class="timer-wrap">Timer: {{ elapsedTime }}</div>
            <!-- end timer-wrap -->

            <div class="timer-button-wrap">
              <button
                v-if="showStartButton"
                @click="startSim"
                class="timer-button start-button"
              >
                Start
              </button>
              <button
                v-if="showPauseButton"
                @click="pauseSim"
                class="timer-button pause-button"
              >
                Pause
              </button>
              <button
                v-if="showResumeButton"
                @click="resumeSim"
                class="timer-button resume-button"
              >
                Resume
              </button>
              <!-- <button v-if="showRestartButton" @click="resetSim">Reset</button> -->
            </div>
            <!-- end timer-button-wrap -->
          </div>
          <!-- end timer-block-wrap -->
        </div>
        <!-- end control-bar-right -->
      </div>
      <!-- end inner-margin -->
    </div>
    <!-- end control-bar-->

    <div class="content-wrapper">
      <div class="content-left">
        <div class="slider-crop system-user-crop fade-in">

          <div class="system-user-row">
            <vueper-slides
             class="no-shadow"
              :visible-slides="3"
             :touchable="false"
              :infinite="true"
              :initSlide="1"
              :disableArrowsOnEdges="true"
            >
              <template v-slot:arrow-left>
                <img
                  src="img/icons/Framework-Left-Arrow.png"
                  alt="scroll left"
                />
              </template>

              <template v-slot:arrow-right>
                <img
                  src="img/icons/Framework-Right-Arrow.png"
                  alt="scroll left"
                />
              </template>
              <vueper-slide
                v-for="(user, index) in visibleGeeqUsers"
                :key="index"
              >
                <template v-slot:content>
                  <div class="card geeq-user-card">
                    <GeeqUserCard
                      @openSendTx="pauseSimulation"
                      @closeSendTx="continueSimulation"
                      :showSendButton="!showStartButton"
                      :user="user"
                      :isPrimary="user.isPrimary"
                      :visiblePublicAccounts="visiblePublicAccounts"
                      :key="userUpdateCount"
                    />
                  </div>
                </template>
              </vueper-slide>
            </vueper-slides>
          </div>
          <!-- end system-user-row -->
        </div>
        <!-- end slider-crop.system-user-crop -->

        <div class="slider-crop system-node-crop fade-in">

          <div class="system-node-row">
            <vueper-slides
             class="no-shadow"
              :visible-slides="3"
             :touchable="false"
              :infinite="true"
              :initSlide="1"
              :disableArrowsOnEdges="true"
            >
              <template v-slot:arrow-left>
                <img
                  src="img/icons/Framework-Left-Arrow.png"
                  alt="scroll left"
                />
              </template>

              <template v-slot:arrow-right>
                <img
                  src="img/icons/Framework-Right-Arrow.png"
                  alt="scroll left"
                />
              </template>
              <vueper-slide v-for="(node, index) in geeqNodes" :key="index">
                <template v-slot:content>
                  <div class="card geeq-node-card"
                      :class="nodeCardClass(node)"
                  >
                    <GeeqNodeCard
                      :node="node"
                    />
                  </div>
                </template>
              </vueper-slide>
            </vueper-slides>
          </div><!-- end system-node-row -->

        </div><!-- end slider-crop.system-node-crop -->

        <div class="system-settings-row">
          <div class="geeq-corp-wrapper">
            <div class="card geeq-corp-card">
              <TheGeeqCorpCard
                :gCorp="theGeeqCorp"
              />
            </div>
          </div>
          <!-- end geeq-corp-wrapper -->

          <div class="card settings-wrapper">
            <form @submit.prevent="submitForms">
              <div class="card-top settings-top">
                <h2>Configuration</h2>
                <button>Save</button>
              </div>
              <!-- end settings-top -->

              <div class="card-bottom settings-bottom">
                <div class="settings-left">
                  <h3>User Settings</h3>

                  <div class="card-row username-row">
                    <label for="userName">Username</label>
                    <input
                      type="text"
                      id="userName"
                      v-model="userName"
                      class="user-input"
                      placeholder="My Name"
                    />
                  </div>
                  <!-- end card-row.username" -->

                  <div class="card-row usertype-row">
                    <label for="userType">User Type</label>
                    <select id="userType" name="userType" v-model="userType">
                      <option value="Person">Person</option>
                      <option value="Business">Business</option>
                      <option value="IOT-Device">IOT Device</option>
                      <option value="Bank">Bank</option>
                      <option value="Game">Game</option>
                    </select>
                  </div>
                  <!-- end card-row.usertype" -->

                  <div class="card-row balance-row">
                    <label for="userInitialBalance">Initial Balance</label>
                    <input
                      id="userInitialBalance"
                      v-model="userInitialBalance"
                      type="number"
                    />
                  </div>
                  <!-- end card-row.balance" -->
                </div>
                <!-- end settings-left -->

                <div class="settings-right">
                  <h3>Node Settings</h3>

                  <div class="card-row node-count-row">
                    <label for="numNodes">Node Count</label>
                    <select
                      id="numNodes"
                      name="numNodes"
                      v-model.number="numNodes"
                    >
                    <!--  <option value="1">1</option> -->
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                  <!-- end card-row.node-count-row" -->

                  <!-- <div class="card-row speed-row">
                    <label for="timeMultiplier">Speed</label>
                    <select
                      id="timeMultiplier"
                      name="timeMultiplier"
                      v-model.number="timeMultiplier"
                    >
                      <option value="1">1x</option>
                      <option value="1.25">1.25x</option>
                      <option value="1.50">1.50x</option>
                      <option value="1.75">1.75x</option>
                      <option value="2">2x</option>
                    </select>
                  </div> -->
                  <!-- end card-row.speed-row" -->

                  <div class="card-row chainname-row">
                    <label for="userChainName">Chain Name</label>
                    <input id="userChainName" type="text" v-model="chainName" class="user-input" />
                  </div>
                  <!-- end card-row.chainname-row" -->
                </div>
                <!-- end settings-right -->
              </div>
              <!-- end settings-bottom -->
            </form>
            <!-- end form -->
          </div>
          <!-- end settings-wrapper -->
        </div>
        <!-- end system-settings-row -->
      </div>
      <!-- end content-left -->

      <div class="content-right">
        <div class="event-logger-window">
          <EventLogger :eventLog="eventLog" />
        </div>
        <!-- end event-logger-window -->
      </div>
      <!-- end content-right -->
    </div>
    <!-- end content-wrapper -->
  </div>
  <!-- end main-content-area -->
</template>

<script>
const SimStateUninitialized = 0;
const SimStateInitial = 1;
const SimStateRunning = 2;
const SimStatePaused = 3;
const SimStateCompleted = 4;

const SPLIT_TIMER_TICKS = 3;

// Models
import { TheGeeqCorp } from "@/models/actors/TheGeeqCorp";
import { GeeqUser } from "@/models/actors/GeeqUser";
import { GeeqNode } from "@/models/actors/GeeqNode";
import { BLUE_NETWORK } from "@/models/TheNetwork";

import { VueperSlides, VueperSlide } from "vueperslides";

// Components
import GeeqUserCard from "@/components/actors/GeeqUserCard.vue";
import GeeqNodeCard from "@/components/actors/GeeqNodeCard.vue";
import TheGeeqCorpCard from "@/components/actors/TheGeeqCorpCard";
import EventLogger from "@/components/EventLogger";
import { logTypeEnum, LogFullDescriptionEnum, LogEvent } from "@/models/LogEvent";
import $ from "jquery";
import { NodeBehaviors } from "@/models/actors/nodeutils/NodeBehaviors";
import { NodeGroups } from "@/models/NodeGroups";
import { EventStyles } from "@/components/LogSytle";

// Define Component
export default {
  name: "Home",
  components: {
    EventLogger,
    GeeqUserCard,
    // GeeqOtherUsersCard,
    GeeqNodeCard,
    TheGeeqCorpCard,
    VueperSlides,
    VueperSlide,
  },
  data() {
    return {
      //requestProofCompleted: false,  // Start off believing everyone
      splitDetected: false,   // True, while a split detected and hasn't been resolved which node group is honest
      splitTimer: SPLIT_TIMER_TICKS,          // How many ticks to wait before resolving honesty
      dishonestUser: null,  // The dishonest user that steals Janes coins
      theGeeqCorp: null, // TheGeeqCorp singleton

      visiblePublicAccounts: [], // The user accounts that the primary account can see

      hideInspector: true,
      inspectorData: { hide: true }, // JSON data for inspector
      rawInspectorData: new ArrayBuffer(0),
      inspectorTitle: "Inspector", // Title for inspector
      inspectorPublicShortKey: "",
      inspectorSubtitle: "",
      userUpdateCount: 0,
      eventLog: [],
      simulationState: SimStateUninitialized, // Simulator state

      nodeGroups: NodeGroups,   // Contains list of sets of nodes that are cooperating

      //settings form
      numNodes: Number,
      timeMultiplier: Number,
      userInitialBalance: Number,
      systemInitialAcctFunding: Number,
      userName: String,
      userType: String,
      chainName: String,
    };
  },
  props: {},
  computed: {
    mainNetwork() {
      return this.$store.state.networkPartitions[0];
    },
    geeqUsers() {
      return this.$store.state.geeqUsers;
    },
    visibleGeeqUsers() {
      return this.geeqUsers.filter((value, index, array) => value.isVisible);
    },
    geeqNodes() {
      return this.$store.state.geeqNodes;
    },
    showStartButton() {
      return this.simulationState === SimStateInitial;
    },
    showResumeButton() {
      return this.simulationState === SimStatePaused;
    },
    showPauseButton() {
      return this.simulationState === SimStateRunning;
    },
    showRestartButton() {
      return (
        this.simulationState != SimStateInitial &&
        this.simulationState !== SimStateUninitialized
      );
    },
    showInspector() {
      return !this.hideInspector;
    },
    elapsedTime() {
      const elapsedSeconds =
        this.$store.state.clockTick * this.$store.state.timeMultiplier;
      const minutes = Math.floor(elapsedSeconds / 60) % 60;
      const seconds = elapsedSeconds % 60;
      const hours = Math.floor(elapsedSeconds / 3600);
      const hoursText = hours < 10 ? "0" + hours : hours;
      const minutesText = minutes < 10 ? "0" + minutes : minutes;
      const secondsText = seconds < 10 ? "0" + seconds : seconds;
      return "" + hoursText + ":" + minutesText + ":" + secondsText;
    },
  },
  methods: {
    nodeCardClass(node) {
      const result = `${this.cardClass(node)} ${this.partitionClass(node)}`
      return result
    },
    cardClass(node) {
      return node.isProvenDishonest ? "dishonest" : ""
    },
    partitionClass(node) {
      // Only show partition styling when we are showing a split having been detected and not resolved
      if (this.splitDetected) {
        return node.partitionName
      } else {
        return ""
      }
    },
    // Respond to closing SendTx popup
    continueSimulation() {
      this.simulationState = SimStateRunning
    },
    // Respond to opening SendTx popup
    pauseSimulation() {
      this.simulationState = SimStatePaused
    },
    //settings forms
    submitForms() {
      // Save form values to the store
      // this.$store.commit("setNumUsers", this.numUsers);
      if (this.numNodes) {
        this.$store.commit("setNumNodes", this.numNodes);
      }
      if (this.timeMultiplier) {
        this.$store.commit("setTimeMultiplier", this.timeMultiplier);
      }
      if (this.userInitialBalance) {
        this.$store.commit("setUserInitialBalance", this.userInitialBalance);
      }
       if(this.timeMultiplier){
      this.$store.commit("setTimeMultiplier", this.timeMultiplier);
       }
       if(this.userInitialBalance){
      this.$store.commit("setUserInitialBalance", this.userInitialBalance);
       }
      if(this.userName){
      this.$store.commit("setPrimaryUserName", this.userName);
       }
 if(this.userType){
      this.$store.commit("setUserType", this.userType);
       }

      if (this.chainName) {
        this.$store.commit("setChainName", this.chainName);
      }

      this.$store.commit("setClockTick", 0);
      this.$router.push("/settings");
    },
    inspectActor(publicKeyShortFormat) {
      if (
        !this.hideInspector &&
        this.inspectorPublicShortKey == publicKeyShortFormat
      ) {
        // Need to decide if we want to toggle off. For now don't toggle
        this.hideInspector = false;
        // // Toggling to hide
        // this.hideInspector = true
      } else {
        // Show different CLS
        this.hideInspector = false;
        this.inspectorPublicShortKey = publicKeyShortFormat;
      }
    },
    startSim() {
      this.simulationState = SimStateRunning;
      this.$store.state.clockTick = 0;

      // Tell all nodes to start simulation
      this.geeqNodes.forEach((node) => node.startSimulation());

      // Tell all users to start simulation
    },
    pauseSim() {
      this.simulationState = SimStatePaused;
    },
    resumeSim() {
      this.simulationState = SimStateRunning;
    },
    resetSim() {
      this.simulationState = SimStateInitial;
      this.$store.commit("setClockTick", 0);
    },
    // WARNING: Total hack for demo, grossly inefficient and hardcoded to first node in list for correct balances.
    updateUserBalances() {
      const node = this.geeqNodes[0];

      for (let j = 0; j < this.geeqUsers.length; j++) {
        const geeqUser = this.geeqUsers[j]
        let updatedGeeqUser = false

        // Very inefficient, but we will live with this hack for now
        const tokenAccountRecords = node.clsJSON["tokenAccountRecords"];
        for (let i = 0; i < tokenAccountRecords.length; i++) {
          const tokenAccountRecord = tokenAccountRecords[i]
          if (geeqUser.publicAccountShortFormat === tokenAccountRecord["userAccount"]) {
            const userBalance = tokenAccountRecord["balance"];
            geeqUser.balance = userBalance
            updatedGeeqUser = true
          }
        }
        // Check if account does not appear in the token record list because it was deleted.
        if (!updatedGeeqUser) {
          geeqUser.balance = 0
        }
      }

      // const tokenAccountRecords = node.clsJSON["tokenAccountRecords"];
      // for (let i = 0; i < tokenAccountRecords.length; i++) {
      //   const tokenAccountRecord = tokenAccountRecords[i]
      //   for (let j = 0; j < this.geeqUsers.length; j++) {
      //     const geeqUser = this.geeqUsers[j]
      //     if (geeqUser.publicAccountShortFormat === tokenAccountRecord["userAccount"]) {
      //       const userBalance = tokenAccountRecord["balance"];
      //       geeqUser.balance = userBalance
      //     }
      //   }
      // }

      // Force component update
      this.userUpdateCount += 1;
    },
    eventLogger(event) {
      this.eventLog.unshift(event);
    },
  },
   

  
  async mounted() {
    setTimeout(function() { 
       console.log("mountedz");
        $('.vueperslides__track-inner').css("transform", "translate3d(0, 0px, 0px)");
        $('.vueperslides__arrow--next').click();
    }, 600);

    
    

    if (this.$store.state.initialLoad) {
      this.chainName = "GeeqPay-" + (Math.floor(Math.random() * 100) + 1)
      this.$store.commit("setChainName", this.chainName);
      this.$store.commit("updateInitialLoad")
    }

    // Create a new network that the actors live in
    this.$store.commit("newNetwork");

    // Create TheGeeqCorp singleton
    const gCorp = new TheGeeqCorp(
      this.$store.state.geeqCorpIPAddr,
      this.eventLogger
    );

    await gCorp.initKeys();
    this.theGeeqCorp = gCorp; // Assign only after initialization completed
    this.$store.state.theNetwork.addActor(BLUE_NETWORK, this.theGeeqCorp);

    // Log that the simulation is being initialized.
    this.eventLogger(
      new LogEvent(
        null,
        logTypeEnum.SIMULATOR,
        "Starting simulation with " +
          this.$store.state.numNodes +
          " nodes" +
          " and " +
          (this.$store.state.numAltUsers + 1) +
          " accounts",
        "black",
        null
      )
    );

    // Clear the Geeq users - Note: must happen before Node users are created
    this.$store.commit("clearGeeqUsers");

    // Create the primary user
    const primaryGeeqUser = new GeeqUser(
      this.$store.state.primaryUserName,
      this.theGeeqCorp.publicKey,
      this.$store.state.userInitialBalance,
      true, // isPrimary
      false, // isNodeUser
      true, // isVisible
      this.eventLogger
    );

    await primaryGeeqUser.initKeys();
    this.$store.commit("addGeeqUser", primaryGeeqUser);
    this.$store.state.theNetwork.addActor(BLUE_NETWORK, primaryGeeqUser);
    
    // Create the other users
    let visibleAltUserCount = 0;
    const allUserCount = this.$store.state.numAltUsers;
    for (let i = 0; i < allUserCount; i++) {
      const isVisible =
        visibleAltUserCount < this.$store.state.numVisibleAltUsers; // Mark subset of users as visible
      visibleAltUserCount += 1;

      const geeqUser = new GeeqUser(
        this.$store.state.defaultAltUserName,
        this.theGeeqCorp.publicKey,
        this.$store.state.altUserInitialBalance,
        false, // isPrimary
        false, // isNodeUser
        isVisible,
        this.eventLogger
      );

      // Designate first Alt user as the dishonest user
      if (i == 0) {
        this.dishonestUser = geeqUser
      }

      await geeqUser.initKeys();
      this.$store.commit("addGeeqUser", geeqUser);
      this.$store.state.theNetwork.addActor(BLUE_NETWORK, geeqUser);

    }

    this.$store.commit("sortGeeqUsers");
    
    // Create the starting nodes
    this.$store.commit("clearGeeqNodes");
    let dishonestNodeCount = 0
    for (let i = 0; i < this.$store.state.numNodes; i++) {
      // Create corresponding user account that collects fees
      const nodeUser = new GeeqUser(
        this.$store.state.nodeUserName,
        this.theGeeqCorp.publicKey,
        this.$store.state.nodeUserInitialBalance,
        false, // isPrimary
        true, // isNodeUser
        true, // isVisible
        this.eventLogger
      );

      await nodeUser.initKeys();
      this.$store.commit("addGeeqUser", nodeUser);
      this.$store.state.theNetwork.addActor(BLUE_NETWORK, nodeUser);

      // Determine node honesty
      const isDishonestNode = ((i+1) % 2 == 0 && dishonestNodeCount < 2)  // Don't have than two dishonest nodes
      if (isDishonestNode) {
        dishonestNodeCount++
      }

      // For now, just force one dishonest behavior.  Randomly decide behavior.
      const nodeBehavior = isDishonestNode ? ((Math.random() >= 0.5) ? NodeBehaviors.DISHONESTY_STEAL_MONEY : NodeBehaviors.DISHONESTY_STEAL_MONEY)
                                           : NodeBehaviors.HONEST

      const geeqNode = new GeeqNode(
        nodeUser,
        this.theGeeqCorp.publicKey,
        /* this.$store.state.ntbTimeInterval, */
        this.$store.state.waitForTxInterval,
        this.$store.state.waitForNtbInterval,
        this.eventLogger,
        nodeBehavior,     // Honest or dishonest behavior(s)
        primaryGeeqUser,  // Target of dishonesty
        this.dishonestUser, // Recepient of stolen funds
      );

      await geeqNode.initKeys();
      this.$store.commit("addGeeqNode", geeqNode);
      // DEBUG - first node is not in the same network => i != 0 ? BLUE_NETWORK : GREEN_NETWORK
      this.$store.state.theNetwork.addActor(BLUE_NETWORK, geeqNode);
    }

    // Create the Genensis block
    const genesisBlockWrapper = await this.theGeeqCorp.createGenesisBlockWrapper(
      this.$store.state.geeqInitialBalance + this.geeqNodes.length * this.$store.state.gBBAmount,
      this.$store.state.geeqChainNumber,
      this.geeqNodes,
      this.geeqUsers
    );

    // Initialize all the Geeq Nodes with the Genesis block
    const nodePromises = [];

    this.geeqNodes.forEach(async (gNode) => {
      const nodePromise = async () => {
        // Make sure Geeq Node has been initialized with the Genesis Block before trying to validate the block
        await gNode.initGenesisBlock(genesisBlockWrapper);

        const isValidGB = await gNode.validateGenesisBlock();
        const msg = isValidGB
          ? "Validated genesis block signature"
          : "Genesis block signature is not valid";

        gNode.addToLog(msg);
      };

      nodePromises.push(nodePromise());
    });

    // Initialize all the Geeq Users with the Genesis block
    const userPromises = [];
    this.geeqUsers.forEach(async (gUser) => {
      // Check that genesisblock is signed by the Geeq Corp public key
      const userPromise = async () => {
        // Make sure Geeq User has been initialized with the Genesis Block before trying to validate the block
        await gUser.initGenesisBlock(genesisBlockWrapper);

        const isValidGB = await gUser.validateGenesisBlock();
        const msg = isValidGB
          ? "Validated genesis block signature"
          : "Genesis block signature is not valid";

        // Only log visible users
        if (gUser.isVisible) {
          gUser.addToLog(msg);
        }

        // Save the public keys of the visible users
        if (gUser.isVisible && !gUser.isPrimary) {
          this.visiblePublicAccounts.push(gUser.publicAccount);
        }
      };
      userPromises.push(userPromise());
    });

    // Initialize all nodes first, then initialize all users.
    await Promise.all(nodePromises).then((values) => {
      const event = new LogEvent(
        null,
        logTypeEnum.SIMULATOR,
        "All nodes initialized.",
        "black",
        null
      );
      this.eventLogger(event);

      Promise.all(userPromises).then((values) => {
        const event = new LogEvent(
          null,
          logTypeEnum.SIMULATOR,
          "All users initialized.",
          "black",
          null
        );
        this.eventLogger(event);

        // Simulation is now ready to start, since all Nodes and Users have been initialized.
        this.simulationState = SimStateInitial;

        this.eventLogger(
          new LogEvent(
            null,
            logTypeEnum.SIMULATOR,
            "Simulation ready to start.",
            "black",
            null
          )
        );
      });
    });

    this.nodeGroups = new NodeGroups(this.geeqNodes)
    console.log(this.nodeGroups)

    // Log that the simulation is being initialized.
    // HACK: Use timeout for now, but should fire off message when all the
    // node and user genesis blocks are initialized.
    // await new Promise((resolve) => setTimeout(resolve, 500));

    // Setup simulator clock ticks
    const fn = () => {
      if (this.simulationState == SimStateRunning) {
        // HACK for demo
        this.updateUserBalances();

        this.$store.state.clockTick++;

        if (this.splitDetected) {
          this.splitTimer--
          if (this.splitTimer == 0) {
            this.splitDetected = false
            primaryGeeqUser.addToLog(`Geeqchain discrepancy detected.  Checking honesty of nodes.`)    

            for (let i=0; i < this.geeqNodes.length; i++) {
              const node = this.geeqNodes[i]
              if (node.isProvablyDishonest) {
                node.isProvenDishonest = true  // Permanently mark as dishonest node
                primaryGeeqUser.addToLog(`Node failed Proof of Honesty: ${node.publicKeyShortFormat}`)  
              } else {
                primaryGeeqUser.addToLog(`Node passed Proof of Honesty: ${node.publicKeyShortFormat}`)
              }
            }
          }
        }


        if (
          this.$store.state.clockTick %
            (10 / this.$store.state.timeMultiplier) ==
          0
        ) {
          this.eventLogger(
            new LogEvent(
              null,
              logTypeEnum.SIMULATOR,
              `***** Elapsed Time - ${this.elapsedTime} *****`,
              "black",
              null
            )
          );

          // Detect if the network has split
          if (this.nodeGroups.updatePartition()) {
            this.splitDetected = true
            this.splitTimer = SPLIT_TIMER_TICKS   // How many ticks to wait before honesty is revealed
            this.eventLogger(
            new LogEvent(
              null,
              logTypeEnum.SIMULATOR,
              `GEEQCHAIN NETWORK HAS SPLIT: ${this.nodeGroups.partitionText}`,
              EventStyles.BLACK,
              null
            )
          );
          }
        }

        // End simulation after 10 minutes
        if (
          this.$store.state.clockTick >
          (10 * 60) / this.$store.state.timeMultiplier
        ) {
          this.eventLogger(
            new LogEvent(
              null,
              logTypeEnum.SIMULATOR,
              `***** Simulation Ended - ${this.elapsedTime} *****`,
              EventStyles.BLACK,
              null
            )
          );
          this.simulationState = SimStateCompleted;
        }
      }
    };

    // Initialize inspector to be empty
    this.inspectorData = {
      message: "Click on a button to inspect the contents.",
    };
    this.hideInspector = true;

    //add setings for values
    this.userName = this.$store.state.primaryUserName;
    this.numNodes = this.$store.state.numNodes;
    this.timeMultiplier = this.$store.state.timeMultiplier;
    this.userInitialBalance = this.$store.state.userInitialBalance;
    this.userType = this.$store.state.userType;
    this.chainName = this.$store.state.chainName;

    setInterval(fn, 1000);
  },
  beforeMount() {
    // Initialize fields from value in the store
    // this.numUsers = this.$store.state.numUsers;

    this.userName = this.$store.state.primaryUserName;
    this.userType = this.$store.state.userType;

    this.numNodes = this.$store.state.numNodes;
    this.timeMultiplier = this.$store.state.timeMultiplier;
    this.userInitialBalance = this.$store.state.userInitialBalance;
    this.geeqInitialBalance = this.$store.state.geeqInitialBalance;
    this.chainName = this.$store.state.chainName;

     this.$router.push("/");
  },

  
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>