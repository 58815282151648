// import { ECKeys, verify } from "@/models/cryptoUtils";
import { trucatedBuf2hex, buf2hex } from "@/models/formatUtils"

// Capnp messages
import { CLS, ActiveNodeRecord, TokenAccountRecord } from "@/generated/cls.capnp"
import { nodeStatusText } from "@/models/capnpMessages/GenesisBlockWrapper"


export function getClsJSON(gCLS: CLS) {

    // gBlockData.getChainNumber();
    const hubOrderList = gCLS.getHubOrderList().toArray();
    const blockNumber = gCLS.getBlockNumber();
    const epochNumber = gCLS.getEpochNumber();

    // Active Node List
    const gActiveRecordList = gCLS.getActiveNodeList();
    const activeNodeRecords = [];
    const numNodes = gActiveRecordList.getLength();

    for (let i = 0; i < numNodes; i++) {
      const gActiveNodeRecord: ActiveNodeRecord = gActiveRecordList.get(i);
  
      const nodeInfo: { [k: string]: unknown } = {};

      nodeInfo["creationBlockNumber"] = gActiveNodeRecord.getCreationBlockNumber()
      nodeInfo["lastTxBlockNumber"] = gActiveNodeRecord.getLastTxBlockNumber()
      nodeInfo["nodePublicKey"] = trucatedBuf2hex(
        gActiveNodeRecord.getNodePublicKey().toArrayBuffer()
      );
      nodeInfo["feeAccount"] = trucatedBuf2hex(
        gActiveNodeRecord.getUserAccount().toArrayBuffer()
      );
      nodeInfo["nodeStatus"] = nodeStatusText(gActiveNodeRecord.getNodeStatus());
      nodeInfo["ipAddr"] = gActiveNodeRecord.getIpAddr();
  
      activeNodeRecords.push(nodeInfo);
    }
      
    // User Account Records
    const tokenAccountRecords = [];
    const gTokenAccountRecords = gCLS.getTokenAccountRecords();
    const numUsers = gTokenAccountRecords.getLength();
  
    for (let i = 0; i < numUsers; i++) {
      const gTokenAccountRecord: TokenAccountRecord = gTokenAccountRecords.get(i);
      const userInfo: { [k: string]: unknown } = {};
  
      userInfo["creationBlockNumber"] = gTokenAccountRecord.getCreationBlockNumber()
      userInfo["lastTxBlockNumber"] = gTokenAccountRecord.getLastTxBlockNumber()
      userInfo["nonce"] = gTokenAccountRecord.getNonce()
      userInfo["balance"] = gTokenAccountRecord.getBalance();
      userInfo["userAccount"] = trucatedBuf2hex(
        gTokenAccountRecord.getUserAccount().toArrayBuffer()
      );
  
      tokenAccountRecords.push(userInfo);
    }
  
    // System Account Record
    const systemAccountInfo: { [k: string]: unknown } = {};
  
    const systemAccountRecord = gCLS.getSystemAccountRecord()
    systemAccountInfo["creationBlockNumber"] = systemAccountRecord.getCreationBlockNumber()
    systemAccountInfo["lastTxBlockNumber"] = systemAccountRecord.getLastTxBlockNumber()
    systemAccountInfo["balance"] = systemAccountRecord.getBalance()
    const SYSTEM_NETWORK_ID = 1
    systemAccountInfo["networkId"] = SYSTEM_NETWORK_ID
  
    // Get prevClsHash
    const prevClsHashBuffer = gCLS.getPrevCLSHash().toArrayBuffer()
    const prevClsHashString = buf2hex(prevClsHashBuffer)

    // If all zeros, then enter None
    let isEmpty = true
    const view = new Uint8Array(prevClsHashBuffer)
    const l = prevClsHashBuffer.byteLength
    for (let i=0; i < l; i++) {
      const element = view[i]
      if (element != 0) {
        isEmpty = false
        break
      }
    }

    return {
      prevClsHash: isEmpty ? "" : prevClsHashString,
      blockNumber: blockNumber,
      //epochNumber: epochNumber,
      hubOrderList: hubOrderList,
      activeNodeRecords: activeNodeRecords,
      tokenAccountRecords: tokenAccountRecords,
      systemAccountRecord: systemAccountInfo,
    }
  }

  export default { getClsJSON }