<template>
  <base-card>
    <sendTxPopup v-if="showSendModal" @close="closeSendTx" :node-list="nodeList" :user-list="targetUserPublicAccounts" :user-balance="userBalance"></sendTxPopup>
    
      <div class="card-top">
        
        <span class="card-icon " v-bind:class="[{ main: isPrimary}, geticon]" ></span>
        <h2>{{ user.userName }}</h2>
        <button v-if="isPrimary && showSendButton" :disabled="isSendTxDisabled" @click="sendTx">Send</button>

      </div><!-- end card-top" -->

      <div class="card-bottom">

        <div class="card-row key-row">
            <label>Public Key</label>
             <span class="card-value">{{ myPublicKey }}</span>
        </div><!-- end card-row.key-row" -->

        <div class="card-row key-row">
            <label>User Account</label>
             <span class="card-value">{{ myPublicAccount }}</span>
        </div><!-- end card-row.key-row" -->

        <div class="card-row balance-row">
          <label>Balance</label>
          <span class="card-value">{{ myBalance }}</span>
        </div><!-- end card-row.balance-row" -->

      </div><!-- end card-bottom" -->
    
    <!-- <label>IP Addr</label>
    <div>{{ node.ipaddr }}</div> -->
    <br />
  </base-card>
</template>

<script>
import { GeeqUser } from "@/models/actors/GeeqUser";
import sendTxPopup from "@/components/actors/SendTxPopup.vue";
import { trucatedBuf2hex } from "@/models/formatUtils";
import $ from "jquery";

export default {
  name: "GeeqUserCard",
  components: {
    sendTxPopup,
  },
  emits: ["openSendTx", "closeSendTx"],
  data() {
    return {
      showSendModal: false,
    };
  },
  props: {
    showSendButton: Boolean,
    user: GeeqUser,
    isPrimary: Boolean,
    visiblePublicAccounts: Array,   // Array buffer of visible public accounts
  },
  computed: {
    isSendTxDisabled() {
      const isDisabled = this.$store.state.clockTick <= 0;
      return isDisabled;
    },
    userBalance() {
      if (this.user !== undefined) {
        return this.user.balance;
      } else {
        return 0;
      }
    },
    nodeList() {
      // Return a list of indexes into the Geeq user node list
      return this.user.nodeList
    },
    targetUserPublicAccounts() {
      const publicAccounts = []; // this.user.targetUserList;

      for (let i = 0; i < this.visiblePublicAccounts.length; i++) {
        const shortPublicAccount = trucatedBuf2hex(this.visiblePublicAccounts[i]);
        publicAccounts.push(shortPublicAccount);
      }
      return publicAccounts;
    },
    myBalance() {
      return this.user.balance;
    },
    myPublicKey() {
      return this.user.publicKeyShortFormat;
    },
    myPublicAccount() {
      return this.user.publicAccountShortFormat
    },
    myIPAddr() {
      if (this.user !== undefined) {
        return this.user.ipaddr;
      } else {
        return "uninitialized";
      }
    },
    // Debugging - show genesis block
    gbDisplay() {
      if (this.user !== undefined) {
        if (this.user.genesisBlockWrapper != null) {
          return this.user.genesisBlockWrapper.json;
        } else {
          return "no genesis block";
        }
      } else {
        return "uninitialized user";
      }
    },
    //userIcon
    geticon(){
     return this.$store.state.userType;
   }
  },
  methods: {
    /*
     * Given the UI selected truncated public account text, return the original full public account array.
     */
    async getTargetUserAccountBuffer(targetUserShortPublicAccount) {   
      for (let i = 0; i < this.visiblePublicAccounts.length; i++) {
        const shortPublicAccount = trucatedBuf2hex(this.visiblePublicAccounts[i]);
        if (shortPublicAccount === targetUserShortPublicAccount) {
          const userAccount = this.visiblePublicAccounts[i]
          return userAccount;
        }
      }
      return null;
    },
    sendTx() {
      this.$emit("openSendTx")
      this.showSendModal = true;
       setTimeout(function() { 
        $('.modal-mask').insertBefore( $('.system-user-row') );
    }, 100);
      
    },
    async closeSendTx(payload) {
      this.$emit("closeSendTx")
      this.showSendModal = false;

      if (payload != null) {
        const targetUserAccountBuffer = await this.getTargetUserAccountBuffer(payload.targetUser);
        if (targetUserAccountBuffer != null) {
          const nodeIndex = payload.targetNode
          await this.user.sendTransaction(nodeIndex, targetUserAccountBuffer, payload.transactionAmount);
        } else {
          throw("Matching public key for target user not found.");
        } 
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
label {
  font-weight: bold;
}
h2 {
  margin: 40px 0 0;
  color: #1b7c50;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
