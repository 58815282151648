/* tslint:disable */

/**
 * This file has been automatically generated by the [capnpc-ts utility](https://github.com/jdiaz5513/capnp-ts).
 */

import * as capnp from "capnp-ts";
import { ObjectSize as __O, Struct as __S } from 'capnp-ts';
export const _capnpFileId = "fc9fd9e8367eae85";
export enum ActiveNodeRecordNodeStatus {
    ACTIVE,
    INACTIVE,
    LEAVING,
    SUSPENDED
}
export class ActiveNodeRecord extends __S {
    static readonly NodeStatus = ActiveNodeRecordNodeStatus;
    static readonly _capnp = { displayName: "ActiveNodeRecord", id: "f5b7b036843ff040", size: new __O(16, 3) };
    getCreationBlockNumber(): number { return __S.getUint32(0, this); }
    setCreationBlockNumber(value: number): void { __S.setUint32(0, value, this); }
    getLastTxBlockNumber(): number { return __S.getUint32(4, this); }
    setLastTxBlockNumber(value: number): void { __S.setUint32(4, value, this); }
    getNodeStatus(): ActiveNodeRecordNodeStatus { return __S.getUint16(8, this); }
    setNodeStatus(value: ActiveNodeRecordNodeStatus): void { __S.setUint16(8, value, this); }
    getIpAddr(): string { return __S.getText(0, this); }
    setIpAddr(value: string): void { __S.setText(0, value, this); }
    adoptNodePublicKey(value: capnp.Orphan<capnp.Data>): void { __S.adopt(value, __S.getPointer(1, this)); }
    disownNodePublicKey(): capnp.Orphan<capnp.Data> { return __S.disown(this.getNodePublicKey()); }
    getNodePublicKey(): capnp.Data { return __S.getData(1, this); }
    hasNodePublicKey(): boolean { return !__S.isNull(__S.getPointer(1, this)); }
    initNodePublicKey(length: number): capnp.Data { return __S.initData(1, length, this); }
    setNodePublicKey(value: capnp.Data): void { __S.copyFrom(value, __S.getPointer(1, this)); }
    getGbbAmount(): number { return __S.getUint32(12, this); }
    setGbbAmount(value: number): void { __S.setUint32(12, value, this); }
    adoptUserAccount(value: capnp.Orphan<capnp.Data>): void { __S.adopt(value, __S.getPointer(2, this)); }
    disownUserAccount(): capnp.Orphan<capnp.Data> { return __S.disown(this.getUserAccount()); }
    getUserAccount(): capnp.Data { return __S.getData(2, this); }
    hasUserAccount(): boolean { return !__S.isNull(__S.getPointer(2, this)); }
    initUserAccount(length: number): capnp.Data { return __S.initData(2, length, this); }
    setUserAccount(value: capnp.Data): void { __S.copyFrom(value, __S.getPointer(2, this)); }
    toString(): string { return "ActiveNodeRecord_" + super.toString(); }
}
export class TokenAccountRecord extends __S {
    static readonly _capnp = { displayName: "TokenAccountRecord", id: "8840f6ff2c0a65fc", size: new __O(16, 1) };
    getCreationBlockNumber(): number { return __S.getUint32(0, this); }
    setCreationBlockNumber(value: number): void { __S.setUint32(0, value, this); }
    getLastTxBlockNumber(): number { return __S.getUint32(4, this); }
    setLastTxBlockNumber(value: number): void { __S.setUint32(4, value, this); }
    getNonce(): number { return __S.getUint32(8, this); }
    setNonce(value: number): void { __S.setUint32(8, value, this); }
    getBalance(): number { return __S.getUint32(12, this); }
    setBalance(value: number): void { __S.setUint32(12, value, this); }
    adoptUserAccount(value: capnp.Orphan<capnp.Data>): void { __S.adopt(value, __S.getPointer(0, this)); }
    disownUserAccount(): capnp.Orphan<capnp.Data> { return __S.disown(this.getUserAccount()); }
    getUserAccount(): capnp.Data { return __S.getData(0, this); }
    hasUserAccount(): boolean { return !__S.isNull(__S.getPointer(0, this)); }
    initUserAccount(length: number): capnp.Data { return __S.initData(0, length, this); }
    setUserAccount(value: capnp.Data): void { __S.copyFrom(value, __S.getPointer(0, this)); }
    toString(): string { return "TokenAccountRecord_" + super.toString(); }
}
export class SystemAccountRecord extends __S {
    static readonly _capnp = { displayName: "SystemAccountRecord", id: "ab97876ad507802d", size: new __O(24, 0) };
    getBlock(): number { return __S.getUint32(0, this); }
    setBlock(value: number): void { __S.setUint32(0, value, this); }
    getCreationBlockNumber(): number { return __S.getUint32(4, this); }
    setCreationBlockNumber(value: number): void { __S.setUint32(4, value, this); }
    getLastTxBlockNumber(): number { return __S.getUint32(8, this); }
    setLastTxBlockNumber(value: number): void { __S.setUint32(8, value, this); }
    getBalance(): number { return __S.getUint32(12, this); }
    setBalance(value: number): void { __S.setUint32(12, value, this); }
    getNetworkId(): number { return __S.getUint32(16, this); }
    setNetworkId(value: number): void { __S.setUint32(16, value, this); }
    toString(): string { return "SystemAccountRecord_" + super.toString(); }
}
export class CLS extends __S {
    static readonly _capnp = { displayName: "CLS", id: "c0886df3ff380180", size: new __O(16, 6) };
    static _ActiveNodeList: capnp.ListCtor<ActiveNodeRecord>;
    static _TokenAccountRecords: capnp.ListCtor<TokenAccountRecord>;
    getBlockNumber(): number { return __S.getUint32(0, this); }
    setBlockNumber(value: number): void { __S.setUint32(0, value, this); }
    getEpochNumber(): number { return __S.getUint32(4, this); }
    setEpochNumber(value: number): void { __S.setUint32(4, value, this); }
    getChainNumber(): number { return __S.getUint32(8, this); }
    setChainNumber(value: number): void { __S.setUint32(8, value, this); }
    adoptHubOrderList(value: capnp.Orphan<capnp.List<number>>): void { __S.adopt(value, __S.getPointer(0, this)); }
    disownHubOrderList(): capnp.Orphan<capnp.List<number>> { return __S.disown(this.getHubOrderList()); }
    getHubOrderList(): capnp.List<number> { return __S.getList(0, capnp.Uint32List, this); }
    hasHubOrderList(): boolean { return !__S.isNull(__S.getPointer(0, this)); }
    initHubOrderList(length: number): capnp.List<number> { return __S.initList(0, capnp.Uint32List, length, this); }
    setHubOrderList(value: capnp.List<number>): void { __S.copyFrom(value, __S.getPointer(0, this)); }
    adoptPrevBlockHash(value: capnp.Orphan<capnp.Data>): void { __S.adopt(value, __S.getPointer(1, this)); }
    disownPrevBlockHash(): capnp.Orphan<capnp.Data> { return __S.disown(this.getPrevBlockHash()); }
    getPrevBlockHash(): capnp.Data { return __S.getData(1, this); }
    hasPrevBlockHash(): boolean { return !__S.isNull(__S.getPointer(1, this)); }
    initPrevBlockHash(length: number): capnp.Data { return __S.initData(1, length, this); }
    setPrevBlockHash(value: capnp.Data): void { __S.copyFrom(value, __S.getPointer(1, this)); }
    adoptPrevCLSHash(value: capnp.Orphan<capnp.Data>): void { __S.adopt(value, __S.getPointer(2, this)); }
    disownPrevCLSHash(): capnp.Orphan<capnp.Data> { return __S.disown(this.getPrevCLSHash()); }
    getPrevCLSHash(): capnp.Data { return __S.getData(2, this); }
    hasPrevCLSHash(): boolean { return !__S.isNull(__S.getPointer(2, this)); }
    initPrevCLSHash(length: number): capnp.Data { return __S.initData(2, length, this); }
    setPrevCLSHash(value: capnp.Data): void { __S.copyFrom(value, __S.getPointer(2, this)); }
    adoptActiveNodeList(value: capnp.Orphan<capnp.List<ActiveNodeRecord>>): void { __S.adopt(value, __S.getPointer(3, this)); }
    disownActiveNodeList(): capnp.Orphan<capnp.List<ActiveNodeRecord>> { return __S.disown(this.getActiveNodeList()); }
    getActiveNodeList(): capnp.List<ActiveNodeRecord> { return __S.getList(3, CLS._ActiveNodeList, this); }
    hasActiveNodeList(): boolean { return !__S.isNull(__S.getPointer(3, this)); }
    initActiveNodeList(length: number): capnp.List<ActiveNodeRecord> { return __S.initList(3, CLS._ActiveNodeList, length, this); }
    setActiveNodeList(value: capnp.List<ActiveNodeRecord>): void { __S.copyFrom(value, __S.getPointer(3, this)); }
    adoptTokenAccountRecords(value: capnp.Orphan<capnp.List<TokenAccountRecord>>): void { __S.adopt(value, __S.getPointer(4, this)); }
    disownTokenAccountRecords(): capnp.Orphan<capnp.List<TokenAccountRecord>> { return __S.disown(this.getTokenAccountRecords()); }
    getTokenAccountRecords(): capnp.List<TokenAccountRecord> { return __S.getList(4, CLS._TokenAccountRecords, this); }
    hasTokenAccountRecords(): boolean { return !__S.isNull(__S.getPointer(4, this)); }
    initTokenAccountRecords(length: number): capnp.List<TokenAccountRecord> { return __S.initList(4, CLS._TokenAccountRecords, length, this); }
    setTokenAccountRecords(value: capnp.List<TokenAccountRecord>): void { __S.copyFrom(value, __S.getPointer(4, this)); }
    adoptSystemAccountRecord(value: capnp.Orphan<SystemAccountRecord>): void { __S.adopt(value, __S.getPointer(5, this)); }
    disownSystemAccountRecord(): capnp.Orphan<SystemAccountRecord> { return __S.disown(this.getSystemAccountRecord()); }
    getSystemAccountRecord(): SystemAccountRecord { return __S.getStruct(5, SystemAccountRecord, this); }
    hasSystemAccountRecord(): boolean { return !__S.isNull(__S.getPointer(5, this)); }
    initSystemAccountRecord(): SystemAccountRecord { return __S.initStructAt(5, SystemAccountRecord, this); }
    setSystemAccountRecord(value: SystemAccountRecord): void { __S.copyFrom(value, __S.getPointer(5, this)); }
    toString(): string { return "CLS_" + super.toString(); }
}
CLS._ActiveNodeList = capnp.CompositeList(ActiveNodeRecord);
CLS._TokenAccountRecords = capnp.CompositeList(TokenAccountRecord);
