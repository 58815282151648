import { ConfirmationTx, ConfirmationTxUutValidationCode } from "@/generated/confirmationTx.capnp";

function validationCodeTextMapping(code: ConfirmationTxUutValidationCode) {
    switch(code) {
        case ConfirmationTxUutValidationCode.VALID:
            return "Valid Tx"
        case ConfirmationTxUutValidationCode.INVALID_ACCOUNT:
            return "Invalid: Sender account"
        case ConfirmationTxUutValidationCode.INVALID_JOINT_SOLVENCY:
            return "Invalid: Joint solvency"
        case ConfirmationTxUutValidationCode.INVALID_NOUNCE_REPLAY:
            return "Invalid: Nonce replay"
        case ConfirmationTxUutValidationCode.INVALID_NTB:
            return "Invalid NTB"
        case ConfirmationTxUutValidationCode.INVALID_SIGNATURE:
            return "Invalid UUT Signature"
        default:
            throw('Unknown validation code')
    }
}

/*
 * Transforms the contents of the VUT to a human readable JSON format.
 */
export function confTxJsonFromConfTx(confTx: ConfirmationTx): Record<string, unknown> {
    const uutIndex = confTx.getUutIndex()
    const ntbIndex = confTx.getNtbIndex()
    const validationCode = confTx.getValidationCode()
    const feeAmount = confTx.getFeeCollectionAmount().toNumber()

    return {
        ntbIndex: ntbIndex,
        uutIndex: uutIndex,
        validationCode: validationCodeTextMapping(validationCode),
        feeCollectionAmount: feeAmount
    }
}
