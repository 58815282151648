import { ECKeys } from "./cryptoUtils";
import { getRandomIPAddr } from "./ipUtils";
import { trucatedBuf2hex, buf2hex } from "@/models/formatUtils";
import { LogEvent, logTypeEnum, LogFullDescriptionEnum } from "@/models/LogEvent";
import { TheNetwork } from "@/models/TheNetwork";
import { GenesisBlockWrapper } from "@/models/capnpMessages/GenesisBlockWrapper";
import { Wrapper } from "@/models/capnpMessages/WrapperInterface"
import { arrayBufferEquals } from "@/models/arrayUtils"

enum ACTOR_TYPE {
  UNKNOWN = 0,
  GEEQ_CORP = 1,
  GEEQ_RELAY = 2,
  GEEQ_NODE = 3,
  GEEQ_USER = 4,
}

class Actor {
  actorType: ACTOR_TYPE = ACTOR_TYPE.UNKNOWN;
  theNetwork: TheNetwork | null;
  myLogEventType: logTypeEnum;
  keys: ECKeys;
  ipaddr: string;
  _eventColor = "black";

  genesisBlockWrapper: GenesisBlockWrapper | null = null;

  loggerFn: { (event: LogEvent): void };

  constructor(ipaddr: string, loggerFn: { (event: LogEvent): void }) {
    this.theNetwork = null;
    this.myLogEventType = logTypeEnum.UNKNOWN;
    this.keys = new ECKeys();
    this.ipaddr = ipaddr;
    this.loggerFn = loggerFn;
  }

  // Return true if this actor is a Geeq Node
  get isGeeqNode() {
    return this.actorType == ACTOR_TYPE.GEEQ_NODE;
  }

  joinNetwork(theNetwork: TheNetwork) {
    this.theNetwork = theNetwork;
  }

  public addToLog(message: string, wrapper: Wrapper | null = null, buttonText = "Info", fullDescriptionId = LogFullDescriptionEnum.NONE) {
    const identifier = this.publicKeyShortFormat;

    this.loggerFn(
      new LogEvent(identifier, this.myLogEventType, message, this._eventColor, wrapper, buttonText, fullDescriptionId)
    );
  }

  async initKeys() {
    await this.keys.newKeyPair();
  }

  get publicKeyShortFormat(): string {
    if (this.keys.publicKey !== null) {
      return trucatedBuf2hex(this.keys.publicKey);
    } else {
      return "uninitialized";
    }
  }

  get publicKey(): ArrayBuffer {
    if (this.keys.publicKey !== null) {
      return this.keys.publicKey;
    } else {
      throw "Keypair not initialized.";
    }
  }

  get publicAccount(): ArrayBuffer {
    if (this.keys.publicKeyDigest !== null) {
      return this.keys.publicKeyDigest
    } else {
      throw "Public key digest not initialized."
    }
  }

  get publicAccountText(): string {
    return buf2hex(this.publicAccount)
  }

  get publicAccountShortFormat(): string {
    const publicAccount = this.publicAccount
    const truncatedAccount = trucatedBuf2hex(publicAccount)
    return truncatedAccount
  }
}

/*
 * Abstract superclass for all actors except TheGeeqCorp.  (ie. GeeqUser, GeeqNode, and GeeqRelay)
 */

class NonGeeqCorpActor extends Actor {
  geeqCorpPublicKey: Uint8Array;

  constructor(
    geeqCorpPublicKey: Uint8Array,
    loggerFn: { (event: LogEvent): void }
  ) {
    super(getRandomIPAddr(), loggerFn);
    this.geeqCorpPublicKey = geeqCorpPublicKey;
  }

  async initGenesisBlock(genesisBlockWrapper: GenesisBlockWrapper) {
    this.genesisBlockWrapper = genesisBlockWrapper;
  }

  public async validateGenesisBlock(): Promise<boolean> {
    if (this.genesisBlockWrapper !== null) {
      return this.genesisBlockWrapper.verifySignature(this.geeqCorpPublicKey);
    } else {
      throw "Genesis block wrapper not initialized.";
    }
  }

  getNodeIpAddr(nodeIndex: number): string | null {
    if (this.genesisBlockWrapper !== null) {
      const gBlock = this.genesisBlockWrapper.genesisBlock
      const gCls = gBlock.getBlockData().getCls()
      const activeNodeList = gCls.getActiveNodeList()

      const activeNodeRecord = activeNodeList.get(nodeIndex)
      return activeNodeRecord.getIpAddr()
    }
    return null;
  }
}

export { ACTOR_TYPE, Actor, NonGeeqCorpActor };
