// Libraries
import { Message } from "capnp-ts"

// Messages
import { NTB } from "@/generated/ntb.capnp"

// Message Wrappers
import { Wrapper } from "@/models/capnpMessages/WrapperInterface"
import { ntbFromBuffer } from "@/models/capnpMessages/MessageFromBuffer"
import { ntbJson } from "@/models/capnpMessages/NtbJson"

// Utilities
import { ECKeys, verify } from "@/models/cryptoUtils"

/*
 *  Verifies that the provided public key was the key used to sign the
 *  Genesis block data.
 */
export async function verifyNTBBuffer(
  publicKeyBuffer: ArrayBuffer,
  ntbBlockBuffer: ArrayBuffer
): Promise<boolean> {
  // Copy block data (ie. no signature)
  const duplicateMessage = new Message();
  const dupRoot = duplicateMessage.initRoot(NTB);
  const ntb = ntbFromBuffer(ntbBlockBuffer);
  dupRoot.setNtbData(ntb.getNtbData());

  // Compute signature with blank signature
  dupRoot.initNodeSignature(64);
  const blockBuffer = new Uint8Array(duplicateMessage.toPackedArrayBuffer());

  const signature = ntb.getNodeSignature();
  const signatureBuffer = signature.toArrayBuffer();
  const result = await verify(blockBuffer, publicKeyBuffer, signatureBuffer);
  return result;
}

/*
 * This class wraps an ArrayBuffer that contains a capnp GenesisBlock message.
 */
export class NtbWrapper implements Wrapper {
  _ntbBuffer: ArrayBuffer;
  _ntb: NTB;

  // Count the number of UUTs in this message
  get numUuts(): number | undefined {
    if (this.ntb !== null) {
      const ntbData = this.ntb.getNtbData();

      // Get number of UUTs for logging
      const uuts = ntbData.getUnverifiedUserTransactions();
      const numUuts = uuts.getLength();
      return numUuts;
    } else {
      return undefined;
    }
  }

  get sourceBuffer(): ArrayBuffer {
    return this._ntbBuffer;
  }

  // Returns the json that represents the contents of this message
  get json(): Record<string, unknown> {
    return ntbJson(this._ntbBuffer);
  }

  get byteLength(): number {
    return this._ntbBuffer.byteLength;
  }

  // Returned the cached instance of a capnp NTB.  This is assuming
  // that this object is never modified by the code that obtains this object.
  get ntb(): NTB {
    return this._ntb;
  }

  // Verify that the message was signed by the public key in the message
  verifySignature(nodePublicKeyBuffer: ArrayBuffer): Promise<boolean> {
    // DEBUG - not same structure as UUT - const publicKeyBuffer = this._ntb.getNtbData().getPublicKey().toArrayBuffer();
    return verifyNTBBuffer(nodePublicKeyBuffer, this._ntbBuffer);
  }

  // Has side-effect of replacing _uut and _uutBuffer
  // One danger of doing this is if someone holds onto the old reference for the unsigned genesis block
  async signNTB(keys: ECKeys) {
    // Copy block data (ie. no signature)
    const newMessage = new Message();
    const newRoot = newMessage.initRoot(NTB);
    newRoot.setNtbData(this.ntb.getNtbData());

    // Allocate space for signature
    newRoot.initNodeSignature(64);

    // Compute signature with blank signature field
    const buffer = new Uint8Array(newMessage.toPackedArrayBuffer());
    const signature = await keys.sign(buffer);

    // Save signature to dup genesis block
    const dupBlockSignature = newRoot.getNodeSignature();
    dupBlockSignature.copyBuffer(signature);

    // if (keys.publicKey != null) {
    //   console.log("signNTB public key: " + trucatedBuf2hex(keys.publicKey));
    // }

    // Save signature to original NTB
    const blockSignature = this.ntb.getNodeSignature();
    blockSignature.copyBuffer(signature);

    // Reset internal variables since the genesis block has been replaced.
    this._ntbBuffer = newMessage.toPackedArrayBuffer();
    this._ntb = newRoot;

    // DEBUG
    // if (keys.publicKey != null) {
    //   const isTestVerified = await this.verifySignature(keys.publicKey);
    //   console.log(isTestVerified);
    // }
  }

  constructor(ntbBuffer: ArrayBuffer) {
    this._ntbBuffer = ntbBuffer;
    this._ntb = ntbFromBuffer(ntbBuffer);
  }
}

export default {
  NtbWrapper,
};
