/**
 * compare two binary arrays for equality
 * @param {(ArrayBuffer|ArrayBufferView)} a
 * @param {(ArrayBuffer|ArrayBufferView)} b
 */

function compare(a: Uint8Array, b: Uint8Array) {
  for (let i = a.length; -1 < i; i -= 1) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export function arrayBufferEquals(a: ArrayBuffer, b: ArrayBuffer) {
  if (a.byteLength != b.byteLength) return false;
  const ua = new Uint8Array(a);
  const ub = new Uint8Array(b);
  return compare(ua, ub);
}

export default { arrayBufferEquals };
