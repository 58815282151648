<template>
  <div class="faq-page" id="faq-page">

    <div class="main-content-area">

      <div class="control-bar">

        <div class="inner-margin">
          <h1>Frequently Asked Questions</h1>
        </div><!-- end inner-margin -->

      </div><!-- end control-bar-->

      <div class="content-wrapper">

        <div class="inner-margin">

          <div class="article-area">
              <h3><a href="https://geeq.io/test-framework-v01-faq-0/" alt="click to find out more" target="_blank">How Should I Get Started on v0.1?</a></h3>
              <h3><a href="https://geeq.io/what-makes-geeqs-technology-so-special/" alt="click to find out more" target="_blank">What Makes Geeq's Technology So Special?</a></h3>
              <h3><a href="https://geeq.io/current-ledger-state-cls/" alt="click to find out more" target="_blank">What is a Current Ledger State (CLS)?</a></h3>
              <h3><a href="https://geeq.io/faq-v01-4-anl/" alt="click to find out more" target="_blank">What is an Active Node List (ANL)?</a></h3>
              <h3><a href="https://geeq.io/node/" alt="click to find out more" target="_blank">What is a Node?</a></h3>
              <h3><a href="https://geeq.io/hub/" alt="click to find out more" target="_blank">What is a Hub?</a></h3>
              <h3><a href="https://geeq.io/ntb-node-transaction-bundle/" alt="click to find out more" target="_blank">What is a Node Transaction Bundle (NTB)?</a></h3>
              <h3><a href="https://geeq.io/hub-transaction-bundle-htb/" alt="click to find out more" target="_blank">What is a Hub Transaction Bundle (HTB)?</a></h3>
              <h3><a href="https://geeq.io/unverified-user-transaction-uut/" alt="click to find out more" target="_blank">What is a Unverified User Transaction (UUT)?</a></h3>
          
          </div><!-- end article-area -->

        </div><!-- end inner-margin -->

      </div><!-- end content-wrapper -->

    </div><!-- end main-content-area -->

  </div><!-- end about -->

</template>
