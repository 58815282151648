<template>
  <base-card>
      <div class="hub" v-if="isHub"></div>
      <div class="card-top">
        
        <span class="card-icon"></span>
        <span class="card-icon hub" v-if="isHub" title="Active Node"></span>
        <h2>Node</h2>
        <button v-if="blockCount > 0" @click="ClsClicked">CLS</button>

    </div><!-- end card-top -->

    <div class="card-bottom">
        <div class="card-row key-row">
          <label>Public Key</label>
          <span class="card-value">{{ myPublicKey }}</span>
        </div><!-- end card-row.key-row" -->

      <div class="card-row key-row">
        <label>Fee Account</label>
        <span class="card-value">{{ myFeePublicAccount }}</span>
      </div>
      <!-- end card-row.key-row" -->

      <div class="card-row block-count-row">
        <label>Block Count</label>
        <span class="card-value">{{ blockCount }}</span>
      </div>
      <!-- end card-row.block-count-row" -->

      <!-- <div class="card-row ip-address-row">
          <label>IP Addr</label>
          <span class="card-value">{{ node.ipaddr }}</span>
        </div> end card-row.ip-address-row"  -->
    
        <div class="card-row node-state-row">
          <label><span class="hub-notification" v-if="isHub">Hub Status</span>Status</label>
          <span class="card-value">{{ nodeStateText }}</span>
        </div><!-- end card-row.block-count-row" -->
    
    </div><!-- end card-bottom -->

    <!-- <label>Accepted Tx Count</label>
    <div>{{ acceptedUUTCount }}</div> -->

    <!-- Message popup for CLS and Genesis Block -->
    <div v-if="showCLSPopup">
      <MessagePopup @close="popupClosed" :wrapper="nodeWrapper" :key="blockCount">
        <!-- Popup Header -->
        <template #header>
          <h3>Current Ledger State</h3>
          <h4>Node ({{ node.publicKeyShortFormat }})</h4>
        </template>
        <!-- Popup Body -->
        <template #body>
          <p>When following protocol, after a node constructs Block B+1, each node updates its own Current Ledger State.  Its Current Ledger State contains: the hash of its previous CLS for Block B; its Active Node List information; its updated balance for any {ChainName}’s user accounts if the balance is positive; and its updated balance for the system account.</p>
        </template>
      </MessagePopup>
    </div>
  </base-card>
</template>

<script>
import { GeeqNode } from "@/models/actors/GeeqNode";
import { STATES_TYPE } from "@/models/actors/nodeutils/NodeState";
import MessagePopup from "@/components/MessagePopup";
import $ from "jquery";
export default {
  name: "GeeqNode",
  data() {
    return {
      nodeState: STATES_TYPE.UNINITIALIZED,
      isHub: false,
      blockCount: 0,
      showCLSPopup: false,
      nodeWrapper: null,
    };
  },
  props: {
    node: GeeqNode,
  },
  computed: {
    nodeHonestyText() {
      if (this.node.isHonest) {
        return "Yes"
      } else {
        return "No"
      }
    },
    acceptedUUTCount() {
      return this.node.acceptedTxCount;
    },
    nodeStateText() {
      switch (this.nodeState) {
        case STATES_TYPE.UNINITIALIZED:
          return "Uninitialized";
        case STATES_TYPE.WAIT_FOR_SIM_START:
          return "Waiting for test to start";
        case STATES_TYPE.WAIT_FOR_TXS:
          return "Waiting for txs";
        case STATES_TYPE.INITIAL:
          return "Initial";
        case STATES_TYPE.WAIT_FOR_NTBS: // Hub only state
          if (this.node.isHub) {
            return "Wait for NTBs";
          } else {
            throw "Hub state found in non-hub node.";
          }
        case STATES_TYPE.SEND_HTB: // Hub only state
          if (this.node.isHub) {
            return "Send HTB";
          } else {
            throw "Hub state found in non-hub node.";
          }
        case STATES_TYPE.SEND_NTB: // Note: Hub can send NTB to itself, not through the network
          return "Send NTB";
        case STATES_TYPE.WAIT_FOR_HTB:
          return "Wait for HTB";
        default:
          return "Unknown";
      }
    },
    clockTick() {
      return this.$store.state.clockTick;
    },
    myPublicKey() {
      return this.node.publicKeyShortFormat;
    },
    myFeePublicAccount() {
      if (this.node.feePublicAccountShortFormat != null) {
        return this.node.feePublicAccountShortFormat;
      } else {
        return "-";
      }
    },
    // isHub() {
    //   return this.node.isHub;
    // },
    // blockCount() {
    //   return this.node.numBlocks;
    // },
  },
  watch: {
    clockTick(newTick, oldTick) {
      const stateChanged = this.node.updateClock(
        newTick,
        oldTick,
        this.$store.state.timeMultiplier
      );

      this.isHub = this.node.isHub;
      const lastBlockCount = this.blockCount
      this.blockCount = this.node.numBlocks;
      this.nodeWrapper = this.node.clsWrapper;
      // if (this.blockCount != lastBlockCount) {
      //   this.$forceUpdate();  // Force update so that the nodeWrapper will refresh
      // }
    },
  },
  methods: {
    popupClosed() {
      this.showCLSPopup = false;
    },
    ClsClicked() {
      this.showCLSPopup = true;
       setTimeout(function() { 
        $('.modal-mask').insertBefore( $('.system-node-row') );
        }, 200);
    },
  },
  components: {
    MessagePopup,
  },
  mounted() {
    // Setup node state and also track it as it changes.
    this.nodeState = this.node.state;
    this.node.registerAnyStateChangeCallback(
      () => (this.nodeState = this.node.state)
    );
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
