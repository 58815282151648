/**
 * HubTxBundleMgr class handles construction of the HTB to be sent to the nodes in the ANL.
 */
import { NtbData } from "@/generated/ntb.capnp";
import { HTB } from "@/generated/htb.capnp";
import { NtbWrapper } from "@/models/capnpMessages/NtbWrapper";
import { Message } from "capnp-ts";

import { GeeqNode } from "../GeeqNode";
import { HtbWrapper } from "@/models/capnpMessages/HtbWrapper";
import { Wrapper } from "@/models/capnpMessages/WrapperInterface"
import { InfoButtonText } from "@/assets/resources";
import { HASH_BYTE_LENGTH } from "@/models/cryptoUtils"
import { LogFullDescriptionEnum } from "@/models/LogEvent";

export default class HubTxBundleMgr {
  private _rejectedNTBs: NtbWrapper[] = []; // List of NTBs received that were rejected for inclusion in next block
  private _acceptedNTBs: NtbWrapper[] = []; // List of NTBs received that were accepted for inclusion in next block

  private _myGeeqNode: GeeqNode | null = null; // This node

  // Debug flags
  private _debugNTB = false;
  private _debugHTB = false;

  constructor(myGeeqNode: GeeqNode) {
    this._myGeeqNode = myGeeqNode;
  }

  get rejectedNTBs() {
    return this._rejectedNTBs;
  }

  get acceptedNTBs() {
    return this._acceptedNTBs;
  }

  /**
   * Process HTB.  Performance sanity check on HTB and uses data in HTB to create the next block.
   */
  async processHTB(htbWrapper: HtbWrapper): Promise<boolean> {
    this.addToLog("Process HTB", htbWrapper, InfoButtonText.HTB, LogFullDescriptionEnum.HTB);

    if (this.isValidHTB(htbWrapper)) {

        this.addToLog("Validated HTB signature")

        if (this._myGeeqNode !== null) {
          if (this._myGeeqNode.myBlockChain !== null) {
            const result = await this._myGeeqNode.myBlockChain.createNextBlock(htbWrapper)
            return result
          } else {
            throw('Missing block chain')
          }
        } else {
          throw('Missing Geeq Node')
        }

    } else {
        return false
    }
  }

  /**
   * Checks to see if HTB is valid.
   * - Performs signature check using hub's public key. (done)
   * - Checks block number is the next one in the chain (done)
   * - Check epoch (done)
   * - Check it is for the same chain
   * - Checks if previous block CLS hash matches
   *
   * - What about HBO?
   *
   */
  async isValidHTB(htbWrapper: HtbWrapper) {

    // Check HTB signature
    if (this._myGeeqNode !== null) {
      if (this._myGeeqNode.myBlockChain != null) {
        const hubPublicKey = this._myGeeqNode.myBlockChain.getPublicKeyForHub(); // Node that generated the NTB

        if (hubPublicKey != null) {
          const isValidSignature = await htbWrapper.verifySignature(hubPublicKey as Uint8Array);
          return isValidSignature;
        } else {
          throw `Missing hub public key`
        }
      } else {
        throw "Missing block chain"
      }
    } else {
      throw "Missing Geeq Node"
    }
  }

  addToLog(message: string, wrapper: Wrapper | null = null, buttonText = "Info", fullDescriptionId = LogFullDescriptionEnum.NONE) {
    if (this._myGeeqNode) {
      this._myGeeqNode?.addToLog(message, wrapper, buttonText, fullDescriptionId);
    } else {
      throw "Missing Geeq node";
    }
  }

  /**
   * Process NTBs.  Perform sanity check on the NTB and place them in an accepted or rejected list.
   *
   * TODO: Record reason for rejections.
   *
   */
  async processNTB(ntbWrapper: NtbWrapper): Promise<boolean> {
    // Process NTB for the next block to be saved

    if (await this.isValidNTB(ntbWrapper)) {
      this.addToLog("Accepted valid NTB");
      this._acceptedNTBs.push(ntbWrapper); // sanitized list to be added to HTB
      return true;
    } else {
      this.addToLog("Rejected invalid NTB", ntbWrapper);
      this._rejectedNTBs.push(ntbWrapper); // unsanitized list
      return false;
    }
  }

  /**
   * Checks NTB for sanity to be included in next block
   * - Check node signature (Done)
   * - TODO: block number, epoch number and chain number are correct
   * - TODO: HBO is correct
   */
  async isValidNTB(ntbWrapper: NtbWrapper) {
    // Check NTB signature
    if (this._myGeeqNode !== null) {
      if (this._myGeeqNode.myBlockChain != null) {
        const ntbData: NtbData = ntbWrapper.ntb.getNtbData();
        const verifyKey = ntbData.getNodePublicKey().toArrayBuffer(); // Node that generated the NTB

        if (verifyKey != null) {
          const isValidSignature = await ntbWrapper.verifySignature(verifyKey);
          return isValidSignature;
        } else {
          throw `Missing public key for node`;
        }
      } else {
        throw "Missing block chain";
      }
    } else {
      throw "Missing Geeq Node";
    }
  }

  async createHTBWrapper() {
    const acceptedN = this._acceptedNTBs.length;
    const rejectedN = this._rejectedNTBs.length;

    this.addToLog(
      `Create HTB.  NTBs: accepted=${acceptedN}; rejected=${rejectedN}; total=${acceptedN +
        rejectedN}`,
        null
    );

    const message = new Message();
    const htb = message.initRoot(HTB);
    const htbData = htb.getHtbData();

    if (this._myGeeqNode !== null) {
      if (this._myGeeqNode.myBlockChain !== null) {
        htbData.setBlockNumber(this._myGeeqNode.myBlockChain.nextBlockNumber);
        htbData.setEpochNumber(
          this._myGeeqNode.myBlockChain.nextBlockEpochNumber
        );
        if (this._myGeeqNode.myBlockChain.chainNumber != null) {
          htbData.setChainNumber(this._myGeeqNode.myBlockChain.chainNumber);
        }

        // Note: previous CLS has for the next block, is the current block CLS hash
        if (this._myGeeqNode.myBlockChain.currentClsHash !== null) {
          htbData.initPrevClsHash(HASH_BYTE_LENGTH)
          htbData
            .getPrevClsHash()
            .copyBuffer(this._myGeeqNode.myBlockChain.currentClsHash);
        }

        // Add sanitized NTBs, first sort by signature (see spec)
        this._acceptedNTBs.sort(this.NTBWrapperSortFn);
        const gNTBs = htbData.initNodeTxBundles(this._acceptedNTBs.length);

        for (let i = 0; i < this._acceptedNTBs.length; i++) {
          const gNTB = gNTBs.get(i);
          gNTB.setNtbData(this._acceptedNTBs[i].ntb.getNtbData());
          gNTB.setNodeSignature(this._acceptedNTBs[i].ntb.getNodeSignature());
        }
        htbData.setNodeTxBundles(gNTBs);
      } else {
        throw "Missing block chain";
      }
    } else {
      throw "Missing Geeq Node";
    }

    htb.setHtbData(htbData);

    // Allocate space for signature
    htb.initHubSignature(64);

    const htbwrapper = new HtbWrapper(message.toPackedArrayBuffer());

    this.reset();
    return htbwrapper;
  }

  /**
   * Sort sanitized UUTs by signature
   */
  NTBWrapperSortFn(a: NtbWrapper, b: NtbWrapper): number {
    const sigA = a.ntb?.getNodeSignature();
    const sigB = b.ntb?.getNodeSignature();

    if (sigA < sigB) {
      return -1;
    } else if (sigA > sigB) {
      return 1;
    } else {
      return 0;
    }
  }

  /**
   * Throw away state related to received NTBs.
   */
  reset() {
    this._rejectedNTBs = [];
    this._acceptedNTBs = [];
  }
}

export { HubTxBundleMgr };
