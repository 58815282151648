import { Block } from "@/generated/block.capnp";
import { ConfirmationTx } from "@/generated/confirmationTx.capnp";

import { buf2hex } from "@/models/formatUtils";
import { blockFromBuffer } from "@/models/capnpMessages/MessageFromBuffer";

import { confTxJsonFromConfTx } from "@/models/capnpMessages/ConfTxJson";
import { htbJsonFromHTB } from "@/models/capnpMessages/HtbJson";

/*
 * Transforms the contents of the Block from the capnp message format
 * in an ArrayBuffer to a human readable JSON format.
 */
export function blockJson(buffer: ArrayBuffer): Record<string, unknown> {
  const block: Block = blockFromBuffer(buffer);

  // Signature
  const signature = block.getNodeSignature();
  const signatureBuffer: ArrayBuffer = signature.toArrayBuffer();
  const signatureText = buf2hex(signatureBuffer);

  const blockData = block.getBlockData();

  // Header
  const blockNumber = blockData.getBlockNumber();
  const chainNumber = blockData.getChainNumber();
  const epochNumber = blockData.getEpochNumber();

  // TODO last10HtbHash
  const prevClsHashBuffer = blockData.getPrevCLSHash().toArrayBuffer()
  const prevClsHashString = buf2hex(prevClsHashBuffer)

  // Validated User Transactions
  const vutList = [];
  const confTxList = blockData.getConfTxList();
  for (let i = 0; i < confTxList.getLength(); i++) {
    const confTx: ConfirmationTx = confTxList.get(i);
    const json = confTxJsonFromConfTx(confTx);
    vutList.push(json);
  }

  // Audit Record
  const auditRecord = blockData.getAuditRecord()
  const dishonestNodeList = auditRecord.getDishonestNodeList()
  const displayNodeList = []
  for (let i=0; i < dishonestNodeList.getLength(); i++) {
    const nodeIndex = dishonestNodeList.get(i)
    displayNodeList.push(nodeIndex)
  }

  const htbJson = htbJsonFromHTB(blockData.getHtb())

  // TODO - Add signature back in when we figure out bug.
  return {
    BlockData: {
      blockNumber: blockNumber,
      //epochNumber: epochNumber,
      chainNumber: chainNumber,
      prevClsHash: prevClsHashString,
      vuts: vutList,
      auditRecord: {
        dishonestNodeList: displayNodeList
      },
      processedHtb: htbJson
    },
    Signature: signatureText,
  };
}
