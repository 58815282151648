import { Message } from "capnp-ts";

import { Block } from "@/generated/block.capnp";

import { Wrapper } from "@/models/capnpMessages/WrapperInterface";
import { blockFromBuffer } from "@/models/capnpMessages/MessageFromBuffer";

import { ECKeys, verify, HASH_BYTE_LENGTH } from "@/models/cryptoUtils";

import { blockJson } from "@/models/capnpMessages/BlockJson";

/*
 *  Verifies that the provided public key was the key used to sign the
 *  Genesis block data.
 */
async function verifyBlockBuffer(
  publicKeyBuffer: ArrayBuffer,
  blockBuffer: ArrayBuffer
): Promise<boolean> {
  // Copy block data (ie. no signature)
  const duplicateMessage = new Message();
  const dupRoot = duplicateMessage.initRoot(Block);
  const block = blockFromBuffer(blockBuffer);
  dupRoot.setBlockData(block.getBlockData());

  // Allocate space for signature
  dupRoot.initNodeSignature(64);

  // Compute signature
  const buffer = new Uint8Array(duplicateMessage.toPackedArrayBuffer());

  const signature = block.getNodeSignature();
  const signatureBuffer = signature.toArrayBuffer();
  const result = await verify(buffer, publicKeyBuffer, signatureBuffer);
  return result;
}

/*
 * This class wraps an ArrayBuffer that contains a capnp GenesisBlock message.
 */
export class BlockWrapper implements Wrapper {
  _blockBuffer: ArrayBuffer;
  _block: Block;

  get sourceBuffer(): ArrayBuffer {
    return this._blockBuffer;
  }

  // Returns the json that represents the contents of this message
  get json(): Record<string, unknown> {
    return blockJson(this._blockBuffer);
  }

  // Returned the cached instance of a capnp GenesisBlock.  This is assuming
  // that this object is never modified by the code that obtains this object.
  get block(): Block {
    return this._block;
  }

  // Verify that the message was signed by the public key in the message
  verifySignature(publicKeyBuffer: ArrayBuffer): Promise<boolean> {
    return verifyBlockBuffer(publicKeyBuffer, this._blockBuffer);
  }

  copyBlock(targetBlock: Block, sourceBlock: Block) {
    try {

      //B Block signature
      targetBlock.initNodeSignature(64)
      targetBlock.getNodeSignature().copyBuffer(sourceBlock.getNodeSignature().toArrayBuffer())
      
      // Block Data Header
      const targetBlockData = targetBlock.getBlockData()
      const sourceBlockData = sourceBlock.getBlockData()

      targetBlockData.setBlockNumber(sourceBlockData.getBlockNumber())

      targetBlockData.setEpochNumber(sourceBlockData.getEpochNumber())
      targetBlockData.setChainNumber(sourceBlockData.getChainNumber())

      targetBlockData.initPrevCLSHash(HASH_BYTE_LENGTH)
      targetBlockData.getPrevCLSHash().copyBuffer(sourceBlockData.getPrevCLSHash().toArrayBuffer())

      targetBlockData.initLast10HtbHash(HASH_BYTE_LENGTH)
      targetBlockData.getLast10HtbHash().copyBuffer(sourceBlockData.getLast10HtbHash().toArrayBuffer())

      const sourceVuts = sourceBlock.getBlockData().getConfTxList()
      const numVuts = sourceVuts.getLength()
      const targetVuts = targetBlockData.initConfTxList(numVuts)
      for (let i = 0; i < numVuts; i++) {
          const targetVut = targetVuts.get(i)
          const sourceVut = sourceVuts.get(i)

          targetVut.setNtbIndex(sourceVut.getNtbIndex())
          targetVut.setUutIndex(sourceVut.getUutIndex())
          targetVut.setValidationCode(sourceVut.getValidationCode())
          targetVut.setFeeCollectionAmount(sourceVut.getFeeCollectionAmount())      
      }

      const sourceAuditRecord = sourceBlock.getBlockData().getAuditRecord()
      targetBlockData.setAuditRecord(sourceAuditRecord)

      targetBlockData.setHtb(sourceBlockData.getHtb())

    } catch(err)
    {
      console.log(err)
    }
  }

  // Has side-effect of replacing _block and _blockBuffer
  // One danger of doing this is if someone holds onto the old reference for the unsigned genesis block
  async signBlock(keys: ECKeys) {

      // Copy block data (ie. no signature)
      const newMessage = new Message();
      const newRoot = newMessage.initRoot(Block);
      this.copyBlock(newRoot, this.block)

      // Compute signature
      const buffer = new Uint8Array(newMessage.toPackedArrayBuffer());
      const signature = await keys.sign(buffer);

      // Save signature to dup genesis block
      const dupBlockSignature = newRoot.getNodeSignature();
      dupBlockSignature.copyBuffer(signature);

      // Save signature to original genesis block
      const blockSignature = this.block.getNodeSignature();
      blockSignature.copyBuffer(signature);

      // Reset internal variables since the genesis block has been replaced.
      this._blockBuffer = newMessage.toPackedArrayBuffer();
      this._block = newRoot;
  }

  constructor(blockBuffer: ArrayBuffer) {
    this._blockBuffer = blockBuffer;
    this._block = blockFromBuffer(blockBuffer);
  }
}

export function getBufferFromBlock(block: Block): ArrayBuffer {
  console.log(block)
  throw "Not implemented yet";
  // Tried this and didn't seem to work
  // const message = new Message()
  // const pointer = new Pointer(block.segment, block.byteOffset)
  // message.setRoot(pointer)
  // return message.toArrayBuffer()
}

export default {
  BlockWrapper,
  getBufferFromBlock,
};
