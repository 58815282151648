/* tslint:disable */

/**
 * This file has been automatically generated by the [capnpc-ts utility](https://github.com/jdiaz5513/capnp-ts).
 */

import * as capnp from "capnp-ts";
import { ObjectSize as __O, Struct as __S } from 'capnp-ts';
import { UutData, UUT } from "./uut.capnp";
export const _capnpFileId = "d8358620a477801c";
export class NtbData extends __S {
    static readonly _capnp = { displayName: "NtbData", id: "d8d3ac6a2f260871", size: new __O(16, 4) };
    static _UnverifiedUserTransactions: capnp.ListCtor<UUT>;
    getChainNumber(): number { return __S.getUint32(0, this); }
    setChainNumber(value: number): void { __S.setUint32(0, value, this); }
    getBlockNumber(): number { return __S.getUint32(4, this); }
    setBlockNumber(value: number): void { __S.setUint32(4, value, this); }
    getEpochNumber(): number { return __S.getUint32(8, this); }
    setEpochNumber(value: number): void { __S.setUint32(8, value, this); }
    adoptCurrentHubPublicKey(value: capnp.Orphan<capnp.Data>): void { __S.adopt(value, __S.getPointer(0, this)); }
    disownCurrentHubPublicKey(): capnp.Orphan<capnp.Data> { return __S.disown(this.getCurrentHubPublicKey()); }
    getCurrentHubPublicKey(): capnp.Data { return __S.getData(0, this); }
    hasCurrentHubPublicKey(): boolean { return !__S.isNull(__S.getPointer(0, this)); }
    initCurrentHubPublicKey(length: number): capnp.Data { return __S.initData(0, length, this); }
    setCurrentHubPublicKey(value: capnp.Data): void { __S.copyFrom(value, __S.getPointer(0, this)); }
    adoptNodePublicKey(value: capnp.Orphan<capnp.Data>): void { __S.adopt(value, __S.getPointer(1, this)); }
    disownNodePublicKey(): capnp.Orphan<capnp.Data> { return __S.disown(this.getNodePublicKey()); }
    getNodePublicKey(): capnp.Data { return __S.getData(1, this); }
    hasNodePublicKey(): boolean { return !__S.isNull(__S.getPointer(1, this)); }
    initNodePublicKey(length: number): capnp.Data { return __S.initData(1, length, this); }
    setNodePublicKey(value: capnp.Data): void { __S.copyFrom(value, __S.getPointer(1, this)); }
    adoptPrevClsHash(value: capnp.Orphan<capnp.Data>): void { __S.adopt(value, __S.getPointer(2, this)); }
    disownPrevClsHash(): capnp.Orphan<capnp.Data> { return __S.disown(this.getPrevClsHash()); }
    getPrevClsHash(): capnp.Data { return __S.getData(2, this); }
    hasPrevClsHash(): boolean { return !__S.isNull(__S.getPointer(2, this)); }
    initPrevClsHash(length: number): capnp.Data { return __S.initData(2, length, this); }
    setPrevClsHash(value: capnp.Data): void { __S.copyFrom(value, __S.getPointer(2, this)); }
    adoptUnverifiedUserTransactions(value: capnp.Orphan<capnp.List<UUT>>): void { __S.adopt(value, __S.getPointer(3, this)); }
    disownUnverifiedUserTransactions(): capnp.Orphan<capnp.List<UUT>> { return __S.disown(this.getUnverifiedUserTransactions()); }
    getUnverifiedUserTransactions(): capnp.List<UUT> { return __S.getList(3, NtbData._UnverifiedUserTransactions, this); }
    hasUnverifiedUserTransactions(): boolean { return !__S.isNull(__S.getPointer(3, this)); }
    initUnverifiedUserTransactions(length: number): capnp.List<UUT> { return __S.initList(3, NtbData._UnverifiedUserTransactions, length, this); }
    setUnverifiedUserTransactions(value: capnp.List<UUT>): void { __S.copyFrom(value, __S.getPointer(3, this)); }
    toString(): string { return "NtbData_" + super.toString(); }
}
export class NTB extends __S {
    static readonly _capnp = { displayName: "NTB", id: "a89f87d27d0c65a2", size: new __O(0, 2) };
    adoptNtbData(value: capnp.Orphan<NtbData>): void { __S.adopt(value, __S.getPointer(0, this)); }
    disownNtbData(): capnp.Orphan<NtbData> { return __S.disown(this.getNtbData()); }
    getNtbData(): NtbData { return __S.getStruct(0, NtbData, this); }
    hasNtbData(): boolean { return !__S.isNull(__S.getPointer(0, this)); }
    initNtbData(): NtbData { return __S.initStructAt(0, NtbData, this); }
    setNtbData(value: NtbData): void { __S.copyFrom(value, __S.getPointer(0, this)); }
    adoptNodeSignature(value: capnp.Orphan<capnp.Data>): void { __S.adopt(value, __S.getPointer(1, this)); }
    disownNodeSignature(): capnp.Orphan<capnp.Data> { return __S.disown(this.getNodeSignature()); }
    getNodeSignature(): capnp.Data { return __S.getData(1, this); }
    hasNodeSignature(): boolean { return !__S.isNull(__S.getPointer(1, this)); }
    initNodeSignature(length: number): capnp.Data { return __S.initData(1, length, this); }
    setNodeSignature(value: capnp.Data): void { __S.copyFrom(value, __S.getPointer(1, this)); }
    toString(): string { return "NTB_" + super.toString(); }
}
NtbData._UnverifiedUserTransactions = capnp.CompositeList(UUT);
