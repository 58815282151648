import { GeeqNode } from "@/models/actors/GeeqNode"
import { trucatedBuf2hex } from "@/models/formatUtils"
import { eqStringSet } from "@/models/setUtils"

class NodeGroups {

    _allNodes: GeeqNode[] = []
    _currentPartition: Set<string>[] = []

    constructor(nodeList: GeeqNode[]) {
        this._allNodes = nodeList
        this.updatePartition()
    }

    get partitionText() {
        const result = []

        for (let i=0; i < this._currentPartition.length; i++) {
            const singleList = []

            for (const entry of this._currentPartition[i]) {
                singleList.push(entry)
            }
            result.push(`(${singleList.toString()})`)
        }

        const resultString = `[${result.toString()}]`
        return resultString
    }

    get partition() {
        return this._currentPartition
    }

    logPartition() {
        console.log(`Partition:`)
        console.log(this.partitionText)
    }

    // Returns a list of sets of Geeq Nodes that are cooperating with each other
    updatePartition(): boolean {
        const newPartition: Set<string>[] = []

        for (let i =0; i < this._allNodes.length; i++) {

            const nodeGroup = new Set<string>()

            // For a single node, find out who is in its node group
            const node = this._allNodes[i]
            const nodeCLS = node.clsWrapper.cls
            const activeNodeList = nodeCLS.getActiveNodeList()
            for (let j=0; j < activeNodeList.getLength(); j++) {
                const activeNodeRecord = activeNodeList.get(j)
                const nodePublicKey = activeNodeRecord.getNodePublicKey()
                nodeGroup.add(trucatedBuf2hex(nodePublicKey.toArrayBuffer()))
            }

            // Check if set is already in result
            let found = false
            for (let k=0; k < newPartition.length; k++) {
                if (eqStringSet(newPartition[k], nodeGroup)) {
                    found = true
                }
            }
            if (!found) {
                newPartition.push(nodeGroup)
            }
        }

        const previousPartition = this._currentPartition
        this._currentPartition = newPartition

        const changed = this.comparePartitions(previousPartition, this._currentPartition)
        if (changed) {

            // Update each node with it's partition name
            // If there is a single group then partiton name is the empty string
            // otherwise "group-<index>" where index is 1-based.  (e.g. group-1, group-2, etc)
            const singleGroup = (this._currentPartition.length == 1)
            for (let i=0; i < this._currentPartition.length; i++) {
                const partitionName = singleGroup ? "" : `group-${i+1}`
                const nodeGroupTruncatedKeys = this._currentPartition[i]
                nodeGroupTruncatedKeys.forEach((nodeShortKey) => {
                    // Find node that matches key
                    for (let j=0; j< this._allNodes.length; j++) {
                        const node =  this._allNodes[j]
                        if (node.publicKeyShortFormat == nodeShortKey) {
                            console.log(nodeShortKey + ':' + partitionName)
                            node.partitionName = partitionName
                        }
                    }
                })   
            }
            this.logPartition()
        }
        return changed
    }

    comparePartitions(p1: Set<string>[], p2: Set<string>[]) {
        // Hack for now, since we know we can just get away with checking the number of groups within a partition
        return p1.length != p2.length
    }

}

export { NodeGroups }