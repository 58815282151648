import { HTB } from "@/generated/htb.capnp"
import { NTB } from "@/generated/ntb.capnp"

import { htbFromBuffer } from "@/models/capnpMessages/MessageFromBuffer"
import { ntbJsonFromNTB } from "@/models/capnpMessages/NtbJson"

import { buf2hex } from "@/models/formatUtils"

export function htbJsonFromHTB(htb: HTB) {
  // Signature
  const signature = htb.getHubSignature();
  const signatureBuffer: ArrayBuffer = signature.toArrayBuffer();
  const signatureText = buf2hex(signatureBuffer);

  const htbData = htb.getHtbData();

  // Header
  const blockNumber = htbData.getBlockNumber();
  const chainNumber = htbData.getChainNumber();
  const epochNumber = htbData.getEpochNumber();

  // NTBs
  const ntbs = htbData.getNodeTxBundles();
  const ntbList = []
  for (let i=0; i < ntbs.getLength(); i++) {
    const ntb: NTB = ntbs.get(i)
    const json = ntbJsonFromNTB(ntb)
    ntbList.push(json)
  }

  // TODO - prev block hash

  return {
    HtbBlockData: {
      blockNumber : blockNumber,
      epochNumber : epochNumber,
      chainNumber : chainNumber,

      nodeTxBundles: ntbList,

      // TODOs: "prev block hash"
    },
    Signature : signatureText
  };
}

/*
 * Transforms the contents of the HTB from the capnp message format
 * in an ArrayBuffer to a human readable JSON format.
 */
export function htbJson(buffer: ArrayBuffer): Record<string, unknown>  {

    const htb: HTB = htbFromBuffer(buffer);
    return htbJsonFromHTB(htb)
    
  }
  
  export default { htbJson, htbJsonFromHTB }