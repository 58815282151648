import { Actor } from "../Actor";

import { Data, Message } from "capnp-ts";
import {
  GenesisBlock,
  GenesisBlockData,
  FixedChainParameters,
} from "@/generated/genesisBlock.capnp";

import { ActiveNodeRecordNodeStatus, CLS } from "@/generated/cls.capnp";
import { GenesisBlockWrapper } from "@/models/capnpMessages/GenesisBlockWrapper";
import { GeeqUser } from "@/models/actors/GeeqUser";
import { GeeqNode } from "@/models/actors/GeeqNode";
import { logTypeEnum, LogFullDescriptionEnum, LogEvent } from "@/models/LogEvent";
import { HASH_BYTE_LENGTH, accountFromPubicKey } from "@/models/cryptoUtils"
import { EventStyles } from "@/components/LogSytle"

const CLS_STARTING_BLOCK_NUMBER = 0;
const CLS_STARTING_EPOCH_NUMBER = 0;

class TheGeeqCorp extends Actor {
  genesisBlockWrapper: GenesisBlockWrapper | null = null;

  constructor(ipaddr: string, loggerFn: { ( event: LogEvent): void }) {
    super(ipaddr, loggerFn);
    this.myLogEventType = logTypeEnum.GEEQ_CORP;
    this.loggerFn = loggerFn;

    // Event Log styling
    this._eventColor = EventStyles.GEEQ_CORP;
  }

  public async createGenesisBlockWrapper(
      geeqInitialBalance: number,
      chainNumber: number,
      geeqNodes: GeeqNode[],
      geeqUsers: GeeqUser[]
    ): Promise<GenesisBlockWrapper> {

      const gbBuffer: ArrayBuffer = this.createGenesisBlock(geeqInitialBalance, chainNumber, geeqNodes, geeqUsers);
      const genesisBlockWrapper = new GenesisBlockWrapper(gbBuffer);
      await genesisBlockWrapper.signGenesisBlock(this.keys);

      this.genesisBlockWrapper = genesisBlockWrapper;
      return this.genesisBlockWrapper;
  }

  /*
   * Create a genesis block based on the input parameter, and store it in a newly allocated ArrayBuffer.
   */
  private createGenesisBlock(
    geeqInitialBalance: number,
    chainNumber: number,
    geeqNodes: GeeqNode[],
    geeqUsers: GeeqUser[]
  ): Uint8Array {
    // Create envelope for genesis block data
    const message = new Message();
    const gBlock = message.initRoot(GenesisBlock);

    const gBlockData: GenesisBlockData = this.createGBlockData(
      geeqInitialBalance,
      chainNumber,
      geeqNodes,
      geeqUsers
    );

    // Initialize block with data
    gBlock.setBlockData(gBlockData);
    
    // Allocate space for signature
    gBlock.initGeeqcorpSignature(64);

    // Return packed ArrayBuffer containing Genesis block
    const genesisBlockBuffer = new Uint8Array(message.toPackedArrayBuffer());

    // Log 
    this.addToLog("Genesis Block issued by Geeq Corp.", null);

    return genesisBlockBuffer;
  }

  private createGBlockData(
    geeqInitialBalance: number,
    chainNumber: number,
    geeqNodes: GeeqNode[],
    geeqUsers: GeeqUser[]
  ): GenesisBlockData {
    // Create new block data
    const innerMessage = new Message();
    const gBlockData = innerMessage.initRoot(GenesisBlockData);

    // Initialize header
    gBlockData.setBlockNumber(CLS_STARTING_BLOCK_NUMBER);
    gBlockData.setChainNumber(chainNumber);

    // Initialize FixedChainParameters
    if (this.keys.publicKey !== null) {
      const gParams = this.createFixedParamsMessage(this.keys.publicKey);
      gBlockData.setFixedParameters(gParams);
    }

    // Initialize CLS
    const gCLS: CLS = this.createCLSMessage(
      geeqInitialBalance,
      chainNumber,
      geeqNodes,
      geeqUsers
    );
    gBlockData.setCls(gCLS);

    return gBlockData;
  }

  private createClsANL(gCLS: CLS, geeqNodes: GeeqNode[]) {
    // Initilize size of Active Node List
    const gActiveNodeList = gCLS.initActiveNodeList(geeqNodes.length);

    // Create one ActiveNodeRecord per node in geeqNodes
    for (let index = 0; index < geeqNodes.length; index++) {
      const node = geeqNodes[index]      
      const gActiveNodeRecord = gActiveNodeList.get(index);

      // block numbers
      gActiveNodeRecord.setCreationBlockNumber(0)
      gActiveNodeRecord.setLastTxBlockNumber(0)
            
      // Set Node Status
      gActiveNodeRecord.setNodeStatus(ActiveNodeRecordNodeStatus.ACTIVE);

      // Set IP address field
      gActiveNodeRecord.setIpAddr(node.ipaddr);

      // Set public key field
      const nodePublicKey: ArrayBuffer = node.publicKey;
      gActiveNodeRecord.initNodePublicKey(nodePublicKey.byteLength);
      const nodePublicKeyField = gActiveNodeRecord.getNodePublicKey();
      nodePublicKeyField.copyBuffer(nodePublicKey);

      // gbb
      gActiveNodeRecord.setGbbAmount(200)

      // Set account field to the fee account
      const userAccount = node.feeUser.publicAccount
      gActiveNodeRecord.initUserAccount(userAccount.byteLength);
      const accountPublicKeyField = gActiveNodeRecord.getUserAccount();
      accountPublicKeyField.copyBuffer(userAccount);

    }

    gCLS.setActiveNodeList(gActiveNodeList);
  }

  private createClsBalances(
    gCLS: CLS,
    geeqInitialBalance: number,
    geeqUsers: GeeqUser[]
  ) {
    const systemAccountRecord = gCLS.getSystemAccountRecord();
    systemAccountRecord.setBalance(geeqInitialBalance);

    // Initilize size of Active Node List
    const gTokenAccountRecords = gCLS.initTokenAccountRecords(geeqUsers.length);

    // Create one ActiveNodeRecord per node in geeqNodes
    geeqUsers.forEach(async (user, index) => {
      const gTokenAccountRecord = gTokenAccountRecords.get(index);

      // Set public key field
      const userAccount: ArrayBuffer = user.publicAccount
      gTokenAccountRecord.initUserAccount(userAccount.byteLength);
      const publicKeyField = gTokenAccountRecord.getUserAccount();
      publicKeyField.copyBuffer(userAccount);

      // Initial balance
      gTokenAccountRecord.setBalance(user.initialBalance);    
    });
    
  }

  private createCLSMessage(
    geeqInitialBalance: number,
    chainNumber: number,
    geeqNodes: GeeqNode[],
    geeqUsers: GeeqUser[]
  ): CLS {
    // Create CLS
    const clsMessage = new Message();
    const gCLS = clsMessage.initRoot(CLS);

    // Initialize header
    gCLS.setChainNumber(chainNumber);
    gCLS.setBlockNumber(CLS_STARTING_BLOCK_NUMBER);
    gCLS.setEpochNumber(CLS_STARTING_EPOCH_NUMBER);

    // Initialize Active Node List
    this.createClsANL(gCLS, geeqNodes);

    // // Initalize Balances
    this.createClsBalances(gCLS, geeqInitialBalance, geeqUsers);

    // Clear hashes
    gCLS.initPrevBlockHash(HASH_BYTE_LENGTH)
    gCLS.initPrevCLSHash(HASH_BYTE_LENGTH)

    // Initalize Hub Order List in the same order as the Active Node List
    gCLS.initHubOrderList(geeqNodes.length);
    const gHubOrderList = gCLS.getHubOrderList();
    for (let i = 0; i < geeqNodes.length; i++) {  
      gHubOrderList.set(i,i)
    }
    gCLS.setHubOrderList(gHubOrderList)
    
    return gCLS;
  }

  private createFixedParamsMessage(publicKey: ArrayBuffer) {
    const chainParamMessage = new Message();
    const gParams = chainParamMessage.initRoot(FixedChainParameters);

    // Initialize FixChainParameters public key
    gParams.initGeeqCorpPublicKey(publicKey.byteLength);

    const paramsGeeqCorpPublicKey = gParams.getGeeqCorpPublicKey();
    paramsGeeqCorpPublicKey.copyBuffer(publicKey);
    return gParams;
  }
}

export { TheGeeqCorp };
