<template>
  <div class="home">
    <Simulator/>
  </div>
</template>

<script>
import Simulator from '@/components/Simulator.vue'; // @ is an alias to /src

export default {
  name: 'Home',
  components: {
  Simulator  },
};
</script>
