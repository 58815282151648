import { Message } from "capnp-ts"

import { HTB } from "@/generated/htb.capnp"

import { Wrapper } from "@/models/capnpMessages/WrapperInterface"
import { htbJson } from "@/models/capnpMessages/HtbJson"

import { ECKeys, verify } from "@/models/cryptoUtils"
import { htbFromBuffer } from "@/models/capnpMessages/MessageFromBuffer"

/*
 *  Verifies that the provided public key was the key used to sign the
 *  Genesis block data.
 */
export async function verifyHTBBuffer(
  publicKeyBuffer: ArrayBuffer,
  htbBlockBuffer: ArrayBuffer
): Promise<boolean> {
  // Copy block data (ie. no signature)
  const duplicateMessage = new Message();
  const dupRoot = duplicateMessage.initRoot(HTB);
  const htb = htbFromBuffer(htbBlockBuffer);
  dupRoot.setHtbData(htb.getHtbData());

  // Compute signature with blank signature
  dupRoot.initHubSignature(64);
  const blockBuffer = new Uint8Array(duplicateMessage.toPackedArrayBuffer());

  const signature = htb.getHubSignature();
  const signatureBuffer = signature.toArrayBuffer();
  const result = await verify(blockBuffer, publicKeyBuffer, signatureBuffer);
  return result;
}

/*
 * This class wraps an ArrayBuffer that contains a capnp GenesisBlock message.
 */
export class HtbWrapper implements Wrapper {
  _htbBuffer: ArrayBuffer;
  _htb: HTB;

  // Count the number of NTBs in this message
  get numNTBs(): number | undefined {
    if (this.htb !== null) {
      const htbData = this.htb.getHtbData();

      // Get number of UUTs for logging
      const ntbs = htbData.getNodeTxBundles();
      const numNTBs = ntbs.getLength();
      return numNTBs;
    } else {
      return undefined;
    }
  }

  get sourceBuffer(): ArrayBuffer {
    return this._htbBuffer;
  }

  // Returns the json that represents the contents of this message
  get json(): Record<string, unknown> {
    return htbJson(this._htbBuffer);
  }

  get byteLength(): number {
    return this._htbBuffer.byteLength;
  }

  // Returned the cached instance of a capnp HTB.  This is assuming
  // that this object is never modified by the code that obtains this object.
  get htb(): HTB {
    return this._htb;
  }

  // Verify that the message was signed by the public key in the message
  verifySignature(hubPublicKeyBuffer: ArrayBuffer): Promise<boolean> {
    // DEBUG - not same structure as UUT - const publicKeyBuffer = this._htb.getHtbData().getPublicKey().toArrayBuffer();
    return verifyHTBBuffer(hubPublicKeyBuffer, this._htbBuffer);
  }

  // Has side-effect of replacing _htb and _htbBuffer
  // One danger of doing this is if someone holds onto the old reference for the unsigned genesis block
  async signHTB(keys: ECKeys) {
    // Copy block data (ie. no signature)
    const newMessage = new Message();
    const newRoot = newMessage.initRoot(HTB);
    newRoot.setHtbData(this.htb.getHtbData());

    // Allocate space for signature
    newRoot.initHubSignature(64);

    // Compute signature with blank signature field
    const buffer = new Uint8Array(newMessage.toPackedArrayBuffer());
    const signature = await keys.sign(buffer);

    // Save signature to dup genesis block
    const dupBlockSignature = newRoot.getHubSignature();
    dupBlockSignature.copyBuffer(signature);

    // if (keys.publicKey != null) {
    //   console.log("signHTB public key: " + trucatedBuf2hex(keys.publicKey));
    // }

    // Save signature to original HTB
    const blockSignature = this.htb.getHubSignature();
    blockSignature.copyBuffer(signature);

    // Reset internal variables since the genesis block has been replaced.
    this._htbBuffer = newMessage.toPackedArrayBuffer();
    this._htb = newRoot;

    // DEBUG
    // if (keys.publicKey != null) {
    //   const isTestVerified = await this.verifySignature(keys.publicKey);
    //   console.log(isTestVerified);
    // }
  }

  constructor(htbBuffer: ArrayBuffer) {
    this._htbBuffer = htbBuffer;
    this._htb = htbFromBuffer(htbBuffer);
  }
}

export default {
  HtbWrapper,
};
