<template>
  <base-card>
      <div class="card-top">
        
        <h2>Geeq Corp</h2>
        
        <button @click="GBClicked">Genesis Block</button>

      </div><!-- end card-top -->

      <div class="card-bottom">
    
        <!-- <label>IP Addr</label>
        <div>{{ myIPAddr }}</div>-->
      
        <div class="card-row key-row">
          <label>Public Key</label>
          <span class="card-value">{{ myPublicKey }}</span>
        </div><!-- end card-row.key-row" -->

        <div class="card-row chain-name-row">
          <label>Chain Name</label>
          <span class="card-value">{{ myChainName }}</span>
        </div><!-- end card-row.chain-name" -->
          
    </div><!-- end card-bottom -->

        <!-- Message popup for CLS and Genesis Block -->
    <div v-if="showGenesisBlockPopup">
      <MessagePopup @close="popupClosed" :wrapper="gbWrapper">
        <!-- Popup Header -->
        <template #header>
          <h3>Genesis Block</h3>
        </template>
        <!-- Popup Body -->
        <template #body>
          <p>The genesis block, Block (B=0), of {ChainName} contains information about: the nodes in its initial Active Node List; the initial Current Ledger State for any {ChainName}’s user accounts with a positive initial balance; a system account; and is signed with the private key of Geeq Corp.</p>
        </template>
      </MessagePopup>
      </div>
  </base-card>
</template>

<script>
import { trucatedBuf2hex } from "@/models/formatUtils";
import { TheGeeqCorp } from "@/models/actors/TheGeeqCorp";
import MessagePopup from "@/components/MessagePopup";


export default {
  name: "TheGeeqCorpCard",
  data() {
    return {
      showGenesisBlockPopup: false,
    };
  },
  props: {
    gCorp: TheGeeqCorp,
  },
  computed: {
    gbWrapper() {
      const wrapper = this.gCorp.genesisBlockWrapper
      return wrapper
    },
    myPublicKey() {
      if (this.gCorp !== null) {
        return trucatedBuf2hex(this.gCorp.publicKey);
      } else {
        return "uninitialized";
      }
    },
    myChainName() {
      if (this.$store.state.chainName) {
        return this.$store.state.chainName
      }
      return "Chain Name"
    },
    myIPAddr() {
      if (this.gCorp !== null) {
        return this.gCorp.ipaddr;
      } else {
        return "uninitialized";
      }
    },
  },
  methods: {
    popupClosed() {
      this.showGenesisBlockPopup = false;
    },
    GBClicked () {
      this.showGenesisBlockPopup = true;
    }
  },
  components: {
    MessagePopup,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
label {
  font-weight: bold;
}
h2 {
  margin: 40px 0 0;
  color: #1b7c50;
}
h3 {
  text-align: center;
  color: black;
}
h4 {
  text-align: center;
  color: black;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
