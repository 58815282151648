import { Wrapper } from "@/models/capnpMessages/WrapperInterface";

enum logTypeEnum {
  UNKNOWN = 0,
  GEEQ_CORP = 1,
  GEEQ_NODE = 2,
  GEEQ_HUB_NODE = 3,
  GEEQ_USER = 4,
  SIMULATOR = 5,
}

enum LogFullDescriptionEnum {
  NONE,
  UUT,
  NTB,
  HTB,
  BLOCK,
}

export default class LogEvent {
  logType: logTypeEnum = logTypeEnum.UNKNOWN;
  _publicKeyText: string | null;
  description: string;
  _color = "black";
  _wrapper: Wrapper | null;
  _buttonText: string;
  _fullDescriptionId: LogFullDescriptionEnum;

  static logTypeText(logType: logTypeEnum) {
    switch (logType) {
      case logTypeEnum.GEEQ_CORP:
        return "GEEQ CORP";
      case logTypeEnum.GEEQ_NODE:
        return "NODE";
      case logTypeEnum.GEEQ_HUB_NODE:
          return "HUB";
      case logTypeEnum.GEEQ_USER:
        return "USER ACCT";
      case logTypeEnum.SIMULATOR:
        return "SIMULATOR";
      case logTypeEnum.UNKNOWN:
        return "UNKNOWN";
    }
  }

  get buttonText() {
    return this._buttonText
  }

  get hasWrapper() {
    return this._wrapper !== null
  }

  get hasPublicKey() {
    return this._publicKeyText !== null;
  }

  get publicKeyText() {
    return this._publicKeyText;
  }

  get logTypeDescription() {
      return LogEvent.logTypeText(this.logType);
  }

  get isUUTLog() {
    return this._fullDescriptionId == LogFullDescriptionEnum.UUT
  }
  
  get isNTBLog() {
    return this._fullDescriptionId == LogFullDescriptionEnum.NTB
  }

  get isHTBLog() {
    return this._fullDescriptionId == LogFullDescriptionEnum.HTB
  }

  get isBlockLog() {
    return this._fullDescriptionId == LogFullDescriptionEnum.BLOCK
  }

  get fullDescription() {
    switch (this._fullDescriptionId) {
      case LogFullDescriptionEnum.HTB:
        return "Htb description"
      default:
        return "Full description"
    }
  }

  get colorStyle() {
    return "color : " + this._color;
  }

  constructor(publicKeyText: string | null, logType: logTypeEnum, description: string, color: string, wrapper: Wrapper | null = null, buttonText = "Info", fullDescriptionId = LogFullDescriptionEnum.NONE ) {
    this._buttonText = buttonText
    this._wrapper = wrapper
    this.logType = logType
    this.description = description
    this._publicKeyText = publicKeyText
    this._color = color
    this._fullDescriptionId = fullDescriptionId
  }
}

export { logTypeEnum, LogFullDescriptionEnum, LogEvent };
