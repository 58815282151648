<template>
  <div class="about" id="about-page">

    <div class="main-content-area">

      <div class="control-bar">

        <div class="inner-margin">
          <h1>About the Geeq Testing Framework v0.1</h1>
        </div><!-- end inner-margin -->

      </div><!-- end control-bar-->

      <div class="content-wrapper">

        <div class="inner-margin">

          <div class="article-area">

            <h3>Why did we name it v0.1?<br/>Because we want you to know there is much more ahead.</h3>
            <h5 class="date">Date: March 25, 2021</h5>
            <h4>About Geeq</h4>                                                                                  
            <p>Geeq is a multi-chain blockchain platform, with no main chain. It is an ecosystem of bespoke blockchain applications, each of which is validated by its own permissionless network of nodes.</p>
            <p>Geeq is application-agnostic.  All blockchain applications are validated by Geeq’s Proof of Honesty (PoH) which provides industry-leading security for every application in the Geeq ecosystem.</p>
            <p>To learn more about Geeq, please visit <a href="https://geeq.io/" target="_blank" title="go to geeq.io">geeq.io</a></p>
            <h4>About v0.1</h4>
            <p>Geeq’s testing framework allows you to download, run and test a portion of our prototype code. V0.1 highlights the messaging structure between users and nodes.  It also is designed to preview the power provided by a highly simplified version of our proprietary protocol, Proof of Honesty.</p>
            <p>V0.1 builds a single blockchain in its simplest form.  It is a decentralized blockchain initialized to transfer tokens, so any user can make payments in a completely permissionless way.</p>
            <p>Please join the discussion in <a href="https://t.me/GeeqOfficial" target="_blank" title="go to GeeqOfficial">t.me/GeeqOfficial</a>.  Geeq welcomes everyone.  Be a Geeq!</p>
          </div><!-- end article-area -->

        </div><!-- end inner-margin -->

      </div><!-- end content-wrapper -->

    </div><!-- end main-content-area -->

  </div><!-- end about -->

</template>
