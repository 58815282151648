<template>

  <div class="event-logger-top">
    <h2>Event Log</h2>
  </div><!-- end event-logger-top -->

  <div class="event-logger-bottom">

    <nav>
      <ul>
        <li v-for="(event, index) in eventLog" :key="event">
          <div v-if="event.hasPublicKey">
            <span style="color: #D66339; margin-right: .4em">{{ eventNumber(index) }}</span>
            <b
              ><span :style="event.colorStyle">{{
                event.logTypeDescription
              }}</span>
              ({{ event.publicKeyText }}):</b
            >
            {{ event.description }}
            <span v-if="showMoreInfo(index)">
              <button @click="eventClick(eventNumber(index))">
                {{ event.buttonText }}
              </button>
              <div v-if="isOpen(eventNumber(index))">
                <!-- Message popup that is visible when the event's button is clicked 
                    the event wrapper contains the Message Wrapper to be rendered.
                -->
                <MessagePopup
                  @close="popupClosed"
                  :wrapper="event._wrapper"
                  :itemIndex="eventNumber(index)"
                >
                  <!-- Popup Header -->
                  <template #header>
                    <h3>{{ event.buttonText }}</h3>
                    <h4>
                      <!-- <span :style="event.colorStyle"
                        >-->
                      {{ event.logTypeDescription.charAt(0) + event.logTypeDescription.toLowerCase().slice(1) }}
                      <!-- </span> -->
                      ({{ event.publicKeyText }})
                    </h4>
                  </template>
                  <!-- Popup Body -->
                  <template #body>
                    <div class="message-body-text">
                      <div v-if="event.isUUTLog">
                      <p>In v0.1, an unverified user transaction is restricted to a SEND token transaction.  This UUT is sent to a node.  An honest user will be careful to send their UUT to a node they know is honest.  The UUT is included specifically so you can follow it through the workflow of the node and see how it is processed into its NTB (or not) in the Event Log.</p>
                      </div>
                      <div v-if="event.isNTBLog">
                      <p>When following protocol, every node on an Active Node List, including the hub, bundles the transactions it has received or generated itself for Block B+1 into a Node Transaction Block (NTB) for Block B+1, includes the hash of its own previous Current Ledger State for Block B, signs it, and sends it to its hub.</p>
                      <br/>
                      <p>Each node’s NTB for Block B+1 reflects its own activity. If a node has been honest so far, the hash it includes of its previous Current Ledger State for Block B is provably honest.</p>
                      </div>
                      <div v-if="event.isHTBLog">
                      <p>When following protocol, a hub bundles all received node transaction bundles (NTB) for Block B+1, including its own, into its Hub Transaction Bundle (HTB) for Block B+1, signs it, and sends it back to each node on its Active Node List.</p>
                      <br/>
                      <p>Each node on the hub’s Active Node List should receive the same HTB. If not, the hub has been dishonest. A future version of the testing framework will show how this dishonesty is provable.</p>
                      </div>
                      <div v-if="event.isBlockLog">
                      <p>Each node has its own Current Ledger State (CLS) for Block B. After each node receives the Hub Transaction Bundle (HTB) for Block B+1, it validates the transactions independently and constructs its own Block B+1.</p>
                      <br/>
                      <p>A node’s Block B+1 contains: the block Number (B+1); the chain Number; the hash of its previous CLS for Block B; information about the transactions it has accepted from each node’s transaction bundle (NTB); and any information associated with audits.</p>
                      </div>
                    </div>
                  </template>
                </MessagePopup>
              </div>
            </span>
          </div>
          <div v-else @click="eventClick(eventNumber(index))">
            <span style="color: #D66339; margin-right: .4em">{{ eventNumber(index) }}</span>
            <b>{{ event.logTypeDescription }}:</b>
            {{ event.description }}
          </div>
        </li>
      </ul>
    </nav>
  </div><!-- end event-logger-bottom -->

</template>

<script lang>
/*
 * There is one popup window component per event.  Only one window is open at one time, which is the one indexed by "eventPopupVisualIndex".
 */
import MessagePopup from "@/components/MessagePopup";

export default {
  data() {
    return {
      eventPopupVisualIndex: 0, // 0 => no popup, > 1 => index of event with popup showing
    };
  },
  components: {
    MessagePopup,
  },
  props: {
    eventLog: Array, // Array of LogType
  },
  computed: {
    open() {
      return this.eventPopupVisualIndex !== 0;
    },
  },
  methods: {
    eventNumber(index) {
      return (this.eventLog.length - index)
    },
    showMoreInfo(visualIndex) {
      const event = this.eventLog[visualIndex];
      return event.hasWrapper;
    },
    /*
     * Open state of the popup corresponding to the eventIndex.
     */
    isOpen(visualIndex) {
      const eventIndex = this.eventLog.length - visualIndex;
      const event = this.eventLog[eventIndex];
      const hasWrapper = event.hasWrapper;

      return (
        this.eventPopupVisualIndex != 0 && // real event in log
        hasWrapper && // has a message wrapper
        visualIndex === this.eventPopupVisualIndex // not already open
      );
    },
    /*
     * Called when the popup is closed.
     */
    popupClosed(visualIndex) {
      if (this.eventPopupVisualIndex == visualIndex) {
        // Match state to the fact that the popup window has closed
        this.eventPopupVisualIndex = 0;
      }
    },
    /*
     * Called when an event in the Event Log is clicked.  Make its popup visible.
     */
    eventClick(visualIndex) {
      this.eventPopupVisualIndex = visualIndex;
    },
  },
  beforeMount() {
    // Initialize to no popup
    this.eventPopupVisualIndex = 0;
  },
};
</script>

<style scoped>
</style>
