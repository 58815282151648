/**
 * The BlockChain class contains the current state of the blockchain, and methods that produce information that can be derived from the current state.
 */

// Libraries
import { Message, Uint64 } from "capnp-ts";

// Capnp Messages
import {
  CLS,
  ActiveNodeRecord,
} from "@/generated/cls.capnp";
import { HtbData } from "@/generated/htb.capnp";
import { Block, BlockData } from "@/generated/block.capnp";
import { AuditAdminRecord } from "@/generated/audit.capnp"
import { ConfirmationTx, ConfirmationTxUutValidationCode } from "@/generated/confirmationTx.capnp";

// Message Wrappers
import { HtbWrapper } from "@/models/capnpMessages/HtbWrapper";
import { BlockWrapper } from "@/models/capnpMessages/BlockWrapper";
import { verifyUUT } from "@/models/capnpMessages/UutWrapper";
import { GenesisBlockWrapper } from "@/models/capnpMessages/GenesisBlockWrapper";

// Models
import { GeeqNode } from "@/models/actors/GeeqNode";
import GeeqConstants from "@/models/actors/nodeutils/GeeqConstants";
import { buf2hex, trucatedBuf2hex } from "@/models/formatUtils";
import { InfoButtonText } from "@/assets/resources";
import { arrayBufferEquals } from "@/models/arrayUtils";
import { UutWithId, UutValidationCode, UutValidationState } from "@/models/actors/nodeutils/UutWithId";

// Utilities
import { accountFromPubicKey, hashBuffer } from "@/models/cryptoUtils"
import { NodeBehaviors } from "./NodeBehaviors";
import { getClsRaw } from "@/models/capnpMessages/ClsWrapper";
import { LogFullDescriptionEnum } from "@/models/LogEvent";

// function buf2Key(buffer: ArrayBuffer): string {
//   return buf2hex(buffer);
// }

// Same truncation format as trucatedBuf2hex
// function truncatedKey(key: string) {
//     const l = key.length;
//     return "..." + key.substring(l-6,l);
// }


export default class BlockChain {
  private _genesisBlockWrapper: GenesisBlockWrapper | null = null; // Cache of genesis block
  private _genesisCLS: CLS | null = null; // Cache of genesis block CLS
  private _lastBlocks: Block[] = []; // Cache of last N non-genesis block
  private _lastClsList: CLS[] = []; // Cache of last N non-genesis cls
  private _chainNumber: number | null = null; // Cache of fixed chain number as defined in the genesis block
  private _maxActiveNodes: number | null = null; // Max active validating nodes
  private _geeqNode: GeeqNode;

  private _keyToBufMap: Map<string, ArrayBuffer> = new Map<string, ArrayBuffer>();   // Maps the string value of a user account to the ArrayBuffer version of the key

  private _currentClsHash: ArrayBuffer;

  get isHonest() {
    return this._geeqNode.isHonest
  }

  get nodeBehaviors () {
    // Start behaving dishonestly as specified by the node's badBehaviorStartBlock
    if (this.nextBlockNumber >= this._geeqNode.badBehaviorStartBlock) {
      return this._geeqNode.behaviors
    } else {
      return NodeBehaviors.HONEST
    }
  }

  constructor(geeqNode: GeeqNode) {
    this._currentClsHash = new ArrayBuffer(0)
    this._geeqNode = geeqNode
  }

  // Initialize with the Genesis block CLS when first created.
  async initGenesisBlockCLS(cls: CLS) {
    this._currentClsHash =  await hashBuffer(getClsRaw(cls))

    //console.log(`Initial ClS hash ${buf2hex(this._currentClsHash)}`)
  }

  // Returns the url for the hub by examining the current CLS
  get hubUrl(): string | null {
    const hub = this.currentHub;
    if (hub == null) {
      return null;
    }

    return hub.getIpAddr();
  }

  get currentHub(): ActiveNodeRecord | null {
    // Find the hub's node number
    const hubNodePublicKey = this.currentHubNodePublicKey;
    if (hubNodePublicKey == null) {
      return null;
    }

    const anl = this.currentCLS?.getActiveNodeList();
    if (anl == null) {
      return null;
    }

    // TODO:  Replace with map instead of linearly searching through list of active node records
    // Returns as the hub, the node that has the matching node number
    for (let i = 0; i < anl.getLength(); i++) {
      const activeNodeRecord = anl.get(i);
      if (arrayBufferEquals(activeNodeRecord.getNodePublicKey().toArrayBuffer(), hubNodePublicKey)) {
        return activeNodeRecord;
      }
    }

    // No match found
    return null;
  }

  // Returns the current hub node number by examining the current CLS
  get currentHubNodePublicKey(): ArrayBuffer | null {
    const cls = this.currentCLS;
    if (cls == null) {
      return null;
    }

    const blockNumber = this.currentBlockNumber;
    if (blockNumber == null) {
      return null;
    }

    const hbo = cls.getHubOrderList();
    if (hbo == null) {
      return null;
    }

    if (this._maxActiveNodes == null) {
      return null;
    }

    const hubIndex = blockNumber % hbo.getLength();
    const activeNodeIndex = hbo.get(hubIndex)

    const hubActiveNodeRecord = cls.getActiveNodeList().get(activeNodeIndex)
    const hubPublicKey = hubActiveNodeRecord.getNodePublicKey().toArrayBuffer()
    return hubPublicKey
  }

  get genesisBlockWrapper() {
    return this._genesisBlockWrapper;
  }

  get currentCLS() {
    if (this._lastClsList.length > 0) {
      return this._lastClsList[0];
    }

    if (this._genesisCLS) {
      return this._genesisCLS;
    }

    return null;
  }

  get currentClsHash(): ArrayBuffer {
    return this._currentClsHash
  }

  get chainNumber() {
    return this._chainNumber;
  }

  get maxActiveNodes() {
    return this._maxActiveNodes;
  }

  get hubActiveNodeIndex(): number {
    const cls = this.currentCLS
    if (cls != null) {
      const hubOrderList = cls.getHubOrderList()
      const blockNumber = cls.getBlockNumber()

      const hubIndex = blockNumber % hubOrderList.getLength();
      const hubActiveNodeIndex = hubOrderList.get(hubIndex)
      return hubActiveNodeIndex
    } else {
      throw("Missing blockchain CLS")
    }
  }

  // Returns the ip address of the current hub
  get hubIpAddr(): string {
    const cls = this.currentCLS
    if (cls != null) {
      const activeNodeRecord = cls.getActiveNodeList().get(this.hubActiveNodeIndex)
      const ipAddr = activeNodeRecord.getIpAddr()
      return ipAddr
    } else {
      throw("Missing blockchain CLS")
    }
  }

  // Returns true of public key of node passed is the same as the hub's public key
  isHub(nodePublicKey: ArrayBuffer): boolean {
    if (this.currentHubNodePublicKey != null) {
      return arrayBufferEquals(nodePublicKey, this.currentHubNodePublicKey)
    } else {
      throw("missing current hub node public key")
    }
  }

  /**
   * Compute has of CLS
   */
  async getCLSHash(cls: CLS): Promise<ArrayBuffer> {
    const buffer = getClsRaw(cls)
    return await hashBuffer(buffer)
  }

  /**
   * Returns the epoch number for a given block number.  For the genesis block (n=0), return 0.
   * For other blocks (n >= 1), return ((n-1) div max_active_node + 1).
   * For example if max_active_node = 2, then epoch 1 contains blocks 1 and 2.
   */
  private getEpochNumber(blockNumber: number): number {
    if (blockNumber == 0) {
      // Genesis block
      return 0;
    } else {
      let epochNumber = 0;

      if (this._maxActiveNodes != null) {
        epochNumber = Math.floor((blockNumber - 1) / this._maxActiveNodes) + 1;
      } else {
        epochNumber = 0;
      }
      return epochNumber;
    }
  }

  /*
   * Returns a list of invalid node public keys
   */
  async validateNTBs(htbWrapper: HtbWrapper) {

    const result = []
    const htbData: HtbData = htbWrapper.htb.getHtbData();
    const ntbs = htbData.getNodeTxBundles();

    // Loop through NTBs embedded in the HTB
    for (let ntbIndex = 0; ntbIndex < ntbs.getLength(); ntbIndex++) {
      const ntb = ntbs.get(ntbIndex)
      const ntbData = ntb.getNtbData()
      const ntbDataPrevCls = ntbData.getPrevClsHash()
      const ntbDataPrevClsBuffer = ntbDataPrevCls.toArrayBuffer()
      if (!arrayBufferEquals(this._currentClsHash, ntbDataPrevClsBuffer)) {
        const nodePublicKey = ntbData.getNodePublicKey()
        result.push(nodePublicKey.toArrayBuffer())
      }
    }
    return result
  }

  /* 
   * Return the index of the matching active node, or -1 if none found.
   */
  getNodeIndexFromPublicKey(cls: CLS, nodePublicKeyBuffer: ArrayBuffer): number {
    const activeNodeList = cls.getActiveNodeList()
    for (let i = 0; i < activeNodeList.getLength(); i++) {
      const currentNodePublicKeyBuffer = activeNodeList.get(i).getNodePublicKey().toArrayBuffer()
      if (arrayBufferEquals(currentNodePublicKeyBuffer, nodePublicKeyBuffer)) {
        return i;
      }
    }
    return -1
  }

  /**
   * Create next block on the block chain based on the contents of the HTB.
   *
   * UUT checks
   * - Single solvency. Ttotal amount + transaction fees must be greater than amount in account.
   * - Reject duplicate User Transactions numbers.  Note: Need to check back up to 10 blocks in past, since duplicate TX may have already been recorded.
   *     TODO: Check with John of duplicate case in same HTB vs across blocks.
   * - Correct Chain
   * - Correct Block
   * - Correct Epoch
   */
  async createNextBlock(htbWrapper: HtbWrapper): Promise<boolean> {
    // this._geeqNode.addToLog(`Create next block - ${this.nextBlockNumber}`, htbWrapper);

    const dishonestNodes = await this.validateNTBs(htbWrapper)
    const dishonestNodeList = []
    const dishonestNodesSet: Set<number> = new Set()
    const dishonestNodeTextList = []
    if (dishonestNodes.length > 0) {

      const numDishonest = dishonestNodes.length
      if (this.currentCLS != null) {
        for (let i=0; i < numDishonest; i++) {
            const nodePublicKeyBuffer = dishonestNodes[i]
            dishonestNodeTextList[i] = trucatedBuf2hex(nodePublicKeyBuffer)
            const nodeIndex = this.getNodeIndexFromPublicKey(this.currentCLS, nodePublicKeyBuffer)
            dishonestNodeList.push(nodeIndex)
            dishonestNodesSet.add(nodeIndex)
        }
      } else {
        throw('Missing CLS in createNextBlock.')
      }
      this._geeqNode.addToLog(`Dishonest nodes detected and removed from network. (${dishonestNodeTextList})`)
    }

    // Gather all the UUTs in one list from the embedded NTBs.  Also make sure they pass the uut signature, and valid NTB checks.
    const uutWithIds = this.gatherUUTsFromHTB(htbWrapper, dishonestNodesSet);

    //console.log(validatedSignatureUUTs);

    // Go through Uuts checking for joint solvency, has side-effect of marking elements with new rejected status if they don't pass joint tenancy.
    await this.jointSolvencyValidation(uutWithIds);

    // At this point, if no error is found in a UUT, then it must be valid
    for (let i=0; i < uutWithIds.length; i++) {
      const uutWithId = uutWithIds[i]
      if (uutWithId.validationCode == null) {
        uutWithIds[i].validationCode = ConfirmationTxUutValidationCode.VALID
      }
    }

    // Allocate the block message
    const blockMessage = new Message();
    const block = blockMessage.initRoot(Block);
    const blockData: BlockData = block.getBlockData()

    // Initialize Audit Record
    const auditMessage = new Message();
    const auditRecord = auditMessage.initRoot(AuditAdminRecord)
    auditRecord.initDishonestNodeList(dishonestNodeList.length)

    const auditRecordNodes = auditRecord.getDishonestNodeList()
    for (let i = 0; i < dishonestNodes.length; i++) {  
      auditRecordNodes.set(i,dishonestNodeList[i])
    }

    auditRecord.setDishonestNodeList(auditRecordNodes)

    blockData.initAuditRecord()
    blockData.setAuditRecord(auditRecord)

    // Initialize chain number
    if (this.chainNumber !== null) {
      blockData.setChainNumber(this.chainNumber);
    } else {
      throw "Missing chain number";
    }

    // Initialize epoch and block numbers
    blockData.setBlockNumber(this.nextBlockNumber);
    blockData.setEpochNumber(this.nextBlockEpochNumber);

    if (this.currentClsHash) {
      blockData.initPrevCLSHash(this.currentClsHash.byteLength);
      blockData.getPrevCLSHash().copyBuffer(this.currentClsHash);
    } else {
      throw "Missing current CLS";
    }

    if (this.currentClsHash) {
      // TODO:  Get the real hash
      blockData.initLast10HtbHash(this.currentClsHash.byteLength);
      blockData.getLast10HtbHash().copyBuffer(this.currentClsHash);
    } else {
      throw "Missing current CLS";
    }

    const confTxList = blockData.initConfTxList(uutWithIds.length);
    for (let i = 0; i < uutWithIds.length; i++) {
      const confTx = confTxList.get(i)
      const uutWithId = uutWithIds[i];

      const [ntbIndex, uutIndex] = uutWithId.indexes
      confTx.setNtbIndex(ntbIndex)
      confTx.setUutIndex(uutIndex)

      const code = uutWithId.validationCode
      if (code == null) {
        throw('Unxpected null validation code in confTx')
      }
      confTx.setValidationCode(code)
      console.log(`validation code: ${confTx.getValidationCode()}`)

      confTx.setFeeCollectionAmount(Uint64.fromNumber(uutWithId.feeAmount))
     
    }
    // Not sure if we need to save it
    blockData.setConfTxList(confTxList)

    //const vutList = blockData.getVuts()
    // console.log(`bc - vutList length = ${vutList.getLength()}`)

    // Add processed htb to block
    blockData.setHtb(htbWrapper.htb)

    // Fill with constructed block data
    block.setBlockData(blockData);

    // Allocate space for signature
    block.initNodeSignature(64);

    // Write block to disk, don't need to wait for it to complete

    const blockWrapper = new BlockWrapper(blockMessage.toPackedArrayBuffer())

    blockWrapper.signBlock(this._geeqNode.keys)
    await this.saveBlock(blockWrapper)
    
    // Must call updateCLS() after updating the cache
    await this.updateCLS(dishonestNodeList);
    return true;
  }

  /**
   */
  async jointSolvencyValidation(uutWithIds: UutWithId[]) {

    // Perform joint solvency check.  All accounts with less than zero failed check.
    const newBalances: Map<
      string,
      number
    > = await this.nextBlockBalances(uutWithIds);

    for (let i=0; i < uutWithIds.length; i++) {
      const uutWithId = uutWithIds[i]

      // Leave it alone if it has already been rejected, other wise test for joint solvency

      if (uutWithId.validationState != UutValidationState.REJECTED) {
        // Convert sender public key into an user account 
        const senderPublicKey = uutWithId.uut.getUutData().getSenderPublicKey().toArrayBuffer()
        const senderAccount = await accountFromPubicKey(senderPublicKey)
        const senderAccountString = buf2hex(senderAccount)
        const senderPublicKeyShortFormat = trucatedBuf2hex(senderPublicKey);

        const senderAccountBalance = newBalances.get(senderAccountString)
        if (senderAccountBalance != undefined) {
          if (senderAccountBalance < 0) {
            uutWithId.validationCode = UutValidationCode.INVALID_JOINT_SOLVENCY

            const receiverAccount = uutWithId.uut.getUutData()
            .getReceiverAccount()
            .toArrayBuffer();
            const receiverAccountShortFormat = trucatedBuf2hex(receiverAccount);
            const amount = uutWithId.uut.getUutData().getAmount()
            this._geeqNode.addToLog(
              `UUT Failure - Joint Solvency. Sender ${senderPublicKeyShortFormat} to ${receiverAccountShortFormat} for ${amount}`,
              null
            );
          }
        } else {
          throw('Unexpected undefined key to newBalances')
        }
      }
      
      
    }
    // //Find failed accounts and log failure
    // for (const [userAccount, bal] of newBalances) {
    //   if (bal !== undefined) {
    //     if (bal < 0) {
    //       // Log failure
    //       const userAccountBuffer = this._keyToBufMap.get(userAccount)
    //       if (userAccountBuffer !== undefined) {
    //         this._geeqNode.addToLog(
    //           `Failed joint solvency check - account = ${buf2hex(
    //             userAccountBuffer
    //           )}`,
    //           null
    //         );
    //       } else {
    //         throw('Missing map entry for key')
    //       }
    //     }
    //   }
    // }
  }

  // Gather all validly signed UUTs from the HTB
  gatherUUTsFromHTB(htbWrapper: HtbWrapper, dishonestNodeSet: Set<number>): UutWithId[] {
    const htbData: HtbData = htbWrapper.htb.getHtbData();
    const ntbs = htbData.getNodeTxBundles();

    const result: UutWithId[] = [];

    // Loop through NTBs embedded in the HTB
    for (let ntbIndex = 0; ntbIndex < ntbs.getLength(); ntbIndex++) {
      const ntb = ntbs.get(ntbIndex);

      const uuts = ntb.getNtbData().getUnverifiedUserTransactions();

      const isInvalidNTB = dishonestNodeSet.has(ntbIndex)

      for (let uutIndex = 0; uutIndex < uuts.getLength(); uutIndex++) {
        const uut = uuts.get(uutIndex);
        const uutWithId = new UutWithId(ntbIndex, uutIndex, uut)

        if (isInvalidNTB) {
          uutWithId.validationCode = UutValidationCode.INVALID_NTB
        } else {

          // Check to see if it is signed correctly
          const verified = verifyUUT(uut);
          if (!verified) {
            // Mark as rejected because of invalid signature
            uutWithId.validationCode = UutValidationCode.INVALID_SIGNATURE
          } 
          // Otherwise, leave in unverified state
        }
        result.push(uutWithId);
      }
    }
    return result;
  }

  /**
   * Compute the balances for the next block, based on all the UUTs in the HTB.  Accounts with insufficient funds are marked with -1 in the balance.
   *
   * Returns a Map with the key being the Public Key and the values being the new balance for the account.  (ie. Public Key -> New Balance)
   */
  async nextBlockBalances(uutsWithId: UutWithId[]): Promise<Map<string, number>> {
    let newBalances = new Map<string, number>(); // default empty

    if (this.currentCLS !== null) {
      newBalances = this.getBalancesFromCLS(this.currentCLS);

      for (let i = 0; i < uutsWithId.length; i++) {
        const uutWithId = uutsWithId[i];
        const uut = uutWithId.uut;
        const uutData = uut.getUutData();
        const senderPublicKey = uutData.getSenderPublicKey().toArrayBuffer();
        const senderPublicKeyShortFormat = trucatedBuf2hex(senderPublicKey);
        const receiverAccount = uutData
          .getReceiverAccount()
          .toArrayBuffer();
        const amount = uutData.getAmount().toNumber();

        // Convert sender public key into an user account 
        const senderAccount = await accountFromPubicKey(senderPublicKey)
        const senderAccountString = buf2hex(senderAccount)
        const receiverAccountShortFormat = trucatedBuf2hex(receiverAccount);

        const currentBal = newBalances.get(senderAccountString);
        if (currentBal !== undefined) {
          // Account exists on CLS of last block
          const uutAmount = uutData.getAmount().toNumber();
          let newBal = 0;
          if (uutAmount > currentBal) {
            // Do it this way to avoid arithmetic overflow issues
            newBal = -1; // Mark as insufficent funds
          } else {
            newBal = currentBal - uutAmount;
          }
          newBalances.set(senderAccountString, newBal);
        } else {
          // Source account doesn't exist
          uutWithId.validationCode = UutValidationCode.INVALID_ACCOUNT
          this._geeqNode.addToLog(
            `UUT Failure - Invalid sender account. Sender ${senderPublicKeyShortFormat} to ${receiverAccountShortFormat} for ${amount}`,
            null
          );
        }
      }
    } else {
      console.log("Missing CLS"); // DEBUG - Should we throw exception
    }
    return newBalances;
  }

  /**
   * Returns a map (public key => amount) of balances in a CLS.
   */
  getBalancesFromCLS(cls: CLS): Map<string, number> {
    const balances = new Map<string, number>();
    const tokenAccountRecords = cls.getTokenAccountRecords();

    // Initialize balances from current CLS
    // console.log('getBalancesFromCLS')
    for (let i = 0; i < tokenAccountRecords.getLength(); i++) {
      const tokenAccountRecord = tokenAccountRecords.get(i);
      const userAccount = tokenAccountRecord.getUserAccount().toArrayBuffer();
      const userAccountString = buf2hex(userAccount)
      this._keyToBufMap.set(userAccountString, userAccount) // Save translation
      const balance = tokenAccountRecord.getBalance();
      // console.log(`key: ${buf2hex(publicKey)}, amount: ${balance}`)
      balances.set(userAccountString, balance);
    }
    return balances;
  }

  getPublicKeyForHub(): ArrayBuffer | null {
    if (this.currentHubNodePublicKey !== null) {
      return this.currentHubNodePublicKey;
    } else {
      return null;
    }
  }

  // DEBUG - temporary blank implementation
  saveGenesisBlock(genesisBlockWrapper: GenesisBlockWrapper): boolean {
    this.initGenesisBlock(genesisBlockWrapper);
    return true;
  }
  // async saveGenesisBlock(genesisBlock: GenesisBlock): Promise <boolean> {

  //     return new Promise<boolean>((resolve, reject)  => {

  //         // Write the binary contents of the genesis block to disk
  //         let buffer = new Buffer(genesisBlock())

  //         writeFile('./chain/genesis_block.blo', buffer, {encoding : null }, (err) => {
  //             if (err == null) {
  //                 this.initGenesisBlock(genesisBlock)
  //                 resolve(true)
  //             } else {
  //                 resolve(false)
  //             }
  //         })
  //     })
  // }

  /**
   * Initializes this object with a validated genesis block
   */
  initGenesisBlock(genesisBlockWrapper: GenesisBlockWrapper) {
    // Genesis block successfully written to disk
    this._genesisBlockWrapper = genesisBlockWrapper; // Cache the genesis block
    const genesisBlockData = genesisBlockWrapper.genesisBlock.getBlockData();
    this._genesisCLS = genesisBlockData.getCls(); // Initialize the CLS
    this._chainNumber = genesisBlockData.getChainNumber();

    // DEBUG - Hard code for now, but should gotten from genesis block
    this._maxActiveNodes = 10; // this._genesis_cls.getFixedParameters().getMaxActiveNodes()
  }

  async saveBlock(blockWrapper: BlockWrapper): Promise<boolean> {
    await this.updateBlockChain(blockWrapper);
    return true;

    // In this version of the protocol, we don't write the blocks to disk.
    //
    //     return new Promise<boolean>((resolve, reject)  => {

    //         // Write the binary contents of the genesis block to disk
    //         let buffer = new Buffer(block.serializeBinary())

    //         let block_data = block.getBlockData()
    //         let block_num = block_data.getBlockNumber()

    //         writeFile('./chain/block' + block_num + '.blo', buffer, {encoding : null }, (err) => {
    //             if (err == null) {
    //                 this.updateBlockChain(block)
    //                 resolve(true)
    //             } else {
    //                 resolve(false)
    //             }
    //         })
    //     })
  }

  /**
   * Update blockchain with latest block
   */
  async updateBlockChain(blockWrapper: BlockWrapper) {
    const block = blockWrapper.block
    const blockNumber = block.getBlockData().getBlockNumber()
    // Update cache
    await this.saveBlockToCache(block);

    this._geeqNode.addToLog(`New block added to chain`, blockWrapper, `${InfoButtonText.BLOCK} ${blockNumber}`, LogFullDescriptionEnum.BLOCK);

  }

  /**
   * Save block to cache.  Keep last BLOCK_CACHE_SIZE blocks.
   */
  saveBlockToCache(block: Block): void {
    if (this._lastBlocks.length >= GeeqConstants.BLOCK_CACHE_SIZE) {
      // Remove oldest block from cache
      this._lastBlocks.pop();
    }
    this._lastBlocks.unshift(block);
  }

  /**
   * Save cls to cache.  Keep last BLOCK_CACHE_SIZE blocks.
   */
  saveCLSToCache(cls: CLS): void {
    if (this._lastClsList.length >= GeeqConstants.BLOCK_CACHE_SIZE) {
      // Remove oldest block from cache
      this._lastClsList.pop();
    }
    this._lastClsList.unshift(cls);
  }

  get lastCLS(): CLS {
    // Set HBO
    let lastCLS = null;
    if (this._lastClsList.length > 0) {
      lastCLS = this._lastClsList[0];
    } else {
      if (this._genesisBlockWrapper) {
        lastCLS = this._genesisBlockWrapper.genesisBlock
          .getBlockData()
          .getCls();
      } else {
        throw "Missing Genesis block";
      }
    }
    return lastCLS
  }

  convertListToSet(list: number[]): Set<number> {
    const result: Set<number> = new Set()
    list.forEach( (index) => {
      result.add(index)
    })
    return result
  }

  retainedNodeIndexAndMap(prevNodeCount: number, dishonestNodeIndexSet: Set<number>): [ number[], Map<number, number>] {
    // Create a list of indexes that are retained after removing dishonest ones
    const retainedIndexes = []

    // Map of old index value to new index value
    const indexMap: Map<number, number> = new Map()
    
    let newIndex = 0
    for (let i=0; i < prevNodeCount; i++) {
      // Only keep index if it is not one of the dishonest node indexes
      if (!dishonestNodeIndexSet.has(i)) {
        retainedIndexes.push(i)
        indexMap.set(i, newIndex)
        newIndex++
      }
    }

    return [retainedIndexes, indexMap]
  }


  /**
   * Update CLS based on contents of new block.  Assumes latest block is in the cache.
   */
  async updateCLS(dishonestNodeIndexList: Array<number>): Promise<void> {
    const latestBlock = this._lastBlocks[0];

    const latestBlockData = latestBlock.getBlockData();

    const message = new Message();
    const newCLS: CLS = message.initRoot(CLS);

    // Initialize new CLS header
    const blockNumber = latestBlockData.getBlockNumber();
    const epochNumber = latestBlockData.getEpochNumber(); // TODO: Handle Epochs
    newCLS.setBlockNumber(blockNumber);
    newCLS.setEpochNumber(epochNumber);

    if (this.chainNumber !== null) {
      newCLS.setChainNumber(this.chainNumber);
    } else {
      newCLS.setChainNumber(0);
    }

    // Set Fixed parameters
    // DEBUG - Not implemented yet
    // let fixed_params : FixedChainParameters = new FixedChainParameters()
    // fixed_params.setMaxActiveNodes(this._max_active_nodes)  // Use cached value from Genesis block
    // new_cls.setFixedParameters(fixed_params)

    const lastCLS = this.lastCLS

    // console.log(`Dishonest node list: ${dishonestNodeIndexList}`)

    const oldANL = lastCLS.getActiveNodeList();

    const numPrevBlockNodes = oldANL.getLength()
    const numNewBlockNodes = numPrevBlockNodes - dishonestNodeIndexList.length

    // Create a set of dishonest node indexes
    const dishonestNodeIndexSet = this.convertListToSet(dishonestNodeIndexList)
    
    const [retainedIndexes, indexMap] = this.retainedNodeIndexAndMap(numPrevBlockNodes, dishonestNodeIndexSet)

    if (numNewBlockNodes != retainedIndexes.length) {
      throw("Node index counts don't match")
    }

    if (dishonestNodeIndexSet.size == 0) {
      // No dishonest nodes, just use last ANL
      newCLS.setActiveNodeList(oldANL);      
    } else {

      // Shrink the ANL
      const newANL = newCLS.initActiveNodeList(numNewBlockNodes)

      // Update ANL
      for (let i=0; i < numPrevBlockNodes; i++) {
        // Only copy the account record if it is not one of the dishonest node indexes
        if (!dishonestNodeIndexSet.has(i)) {
          const oldActiveNodeRecord = oldANL.get(i)
          const newIndex = indexMap.get(i)
          if (newIndex != undefined) {
            // This is a bit of a hack to copy the old ActiveNodeRecord.  There must be a better way.
            const newActiveNodeRecord = newANL.get(newIndex)
            newActiveNodeRecord.setCreationBlockNumber(oldActiveNodeRecord.getCreationBlockNumber())
            newActiveNodeRecord.setLastTxBlockNumber(oldActiveNodeRecord.getLastTxBlockNumber())
            newActiveNodeRecord.setNodeStatus(oldActiveNodeRecord.getNodeStatus())
            newActiveNodeRecord.setIpAddr(oldActiveNodeRecord.getIpAddr())
            newActiveNodeRecord.initNodePublicKey(oldActiveNodeRecord.getNodePublicKey().getLength())
            newActiveNodeRecord.getNodePublicKey().copyBuffer(oldActiveNodeRecord.getNodePublicKey().toArrayBuffer())
            newActiveNodeRecord.setGbbAmount(oldActiveNodeRecord.getGbbAmount())
            newActiveNodeRecord.initUserAccount(oldActiveNodeRecord.getUserAccount().getLength())
            newActiveNodeRecord.getUserAccount().copyBuffer(oldActiveNodeRecord.getUserAccount().toArrayBuffer())
            newANL.set(newIndex, newActiveNodeRecord) 
          } else {
            throw('IndexMap has bad entry')
          }
        }
      }
      newCLS.setActiveNodeList(newANL);
    }

    // Update HBO
    newCLS.initHubOrderList(numNewBlockNodes)
    const oldHBO = lastCLS.getHubOrderList()
    const newHBO = newCLS.getHubOrderList()

    let newHboIndex = 0
    for (let i=0; i < numPrevBlockNodes; i++) {
      if (!dishonestNodeIndexSet.has(i)) {
        // Remap the current entry
        const oldIndex = oldHBO.get(i)
        const newIndex  = indexMap.get(oldIndex)

        if (newIndex == undefined) {
          throw("Invalid new index")
        }

        // Save remapped index to the current entry
        newHBO.set(newHboIndex, newIndex)
        newHboIndex++
      }
    }
    newCLS.setHubOrderList(newHBO);
    
    // console.log(`Old HBO: ${lastCLS.getHubOrderList().toArray()}`)
    // console.log(`New HBO: ${newCLS.getHubOrderList().toArray()}`)

    
    // TODO: ANL - Handle Epochs
    // TODO: HBO - Handle Epochs and re-order hbo

    // TODO: Get hashes
    const blockHash: Uint8Array = new Uint8Array(0);
    const clsHash: Uint8Array = this._currentClsHash as Uint8Array

    newCLS.initPrevBlockHash(blockHash.byteLength);
    newCLS.getPrevBlockHash().copyBuffer(blockHash);

    newCLS.initPrevCLSHash(clsHash.byteLength);
    newCLS.getPrevCLSHash().copyBuffer(clsHash);

    // Update balances based on confirmation txs
    const confTxList = latestBlockData.getConfTxList().toArray();
    const htbData = latestBlockData.getHtb().getHtbData();
    await this.updateBalances(lastCLS, newCLS, htbData, confTxList);

    // Save computed CLS to cache
    this.saveCLSToCache(newCLS);

    // Save the hash of the new CLS
    this._currentClsHash = await hashBuffer(message.toArrayBuffer())
  }

  /*
   * Steal money from the target account by transferring money all the funds from it to the destination account.
   */
  stealMoney(balances: Map<string, number>, targetPublicAccount: ArrayBuffer, destinationPublicAccount: ArrayBuffer): void {
    const targetKey = buf2hex(targetPublicAccount)
    const destinationKey = buf2hex(destinationPublicAccount)
    const targetBalance = balances.get(targetKey)
    const destinationBalance = balances.get(destinationKey)

    if (targetBalance !== undefined && destinationBalance != undefined) {
      balances.delete(targetKey)   // Remove zero entry
      balances.set(destinationKey, destinationBalance + targetBalance)
    }
  }

  /*
   * Returns a copy of the original map, but sorted in key order
   */
  sortMapByValue(map: Map<string, number>):  Map<string, number>{
    const tupleArray: [string, number | undefined][] = [];
    for (const [key, value] of map) {
      tupleArray.push([key, value]);
    }
    tupleArray.sort(function (a: [string, number | undefined], b: [string, number | undefined]) {
      if (a[0] < b[0]) {
        return -1
      } else if (a[0] > b[0]) {
        return 1
      } else {
        return 0
      }
    });
    const sortedMap: Map<string, number> = new Map();

    for (let i =0; i < tupleArray.length; i++) {
      const [key, value] = tupleArray[i]
      if (value != undefined) {
        sortedMap.set(key, value)
      }
    }
    return sortedMap;
  }

  /**
   * Update the new CLS balances by the transfers in the VUT list.
   */
  async updateBalances(lastCLS: CLS, newCLS: CLS, htbData: HtbData, confTxList: ConfirmationTx[]) {
    const currentBalances = this.getBalancesFromCLS(lastCLS);
    const lastSystemAccountRecord = lastCLS.getSystemAccountRecord();
    let systemBalance = lastSystemAccountRecord.getBalance();

    // Update current balances with transfers
    for (let i=0; i < confTxList.length; i++) {
      const confTx = confTxList[i]
      const ntbData = htbData.getNodeTxBundles().get(confTx.getNtbIndex()).getNtbData()
      const uut = ntbData.getUnverifiedUserTransactions().get(confTx.getUutIndex())
      const uutData = uut.getUutData();

      if (confTx.getValidationCode() === ConfirmationTxUutValidationCode.VALID) {
        const senderPublicKey = uutData.getSenderPublicKey().toArrayBuffer();
        const senderAccount = await accountFromPubicKey(senderPublicKey)
        const senderAccountString = buf2hex(senderAccount)
        const receiverAccount = uutData.getReceiverAccount().toArrayBuffer();

        const transferAmount = uutData.getAmount().toNumber();
        const feeAmount = 0 // vut.getFeeCollectionAmount().toNumber();  - Deal with Fees later

        const senderBal = currentBalances.get(senderAccountString);
        if (senderBal !== undefined) {

          const receiverAccountString = buf2hex(receiverAccount)
          const receiverBal = currentBalances.get(receiverAccountString);
          if (receiverBal != undefined) {

            // Transfer amount from sender account to receiver account
            currentBalances.set(senderAccountString, senderBal - transferAmount);
            currentBalances.set(receiverAccountString, receiverBal + transferAmount);
            // console.log(`transfer ${transferAmount} from`)
            // console.log(`sender account: ${senderAccountString} (${senderBal}) `)
            // console.log(`receiver account: ${receiverAccountString} (${receiverBal})}`)
          } else {
            throw "Receiver balance is undefined";
          }
        } else {
          throw "Sender balance is undefined";
        }

        // Transfer into system account
        systemBalance += feeAmount;
        }
    }

    // Update account balances

    // Get entries that have non-zero balance
    const validBalances: Map<string, number> = new Map();
    for (const [userAccountString, balance] of currentBalances) {
      // Note: Zero-balance accounts are removed from the system
      if (balance > 0) {
        validBalances.set(userAccountString, balance);
      }
    }

    // 
    if (this.nodeBehaviors & NodeBehaviors.DISHONESTY_STEAL_MONEY) {
      this.stealMoney(validBalances, this._geeqNode.targetUser.publicAccount, this._geeqNode.dishonestUser.publicAccount)
    }

    const numEntries = validBalances.size
    const bTokenAccountRecords = newCLS.initTokenAccountRecords(
      numEntries
    );

    const sortedValidBalances = this.sortMapByValue(validBalances)

    let i = 0
    for (const [userAccountString, balance] of sortedValidBalances) {
        
      // Note: Zero-balance accounts are removed from the system
      if (balance > 0) {
        const tokenAccountRecord = bTokenAccountRecords.get(i);

        const userAccountBuffer = this._keyToBufMap.get(userAccountString)
        if (userAccountBuffer !== undefined) {
          tokenAccountRecord.initUserAccount(userAccountBuffer.byteLength);
          tokenAccountRecord.getUserAccount().copyBuffer(userAccountBuffer);
          tokenAccountRecord.setBalance(balance);
        } else {
          throw('Can not find public key in map')
        }
      } else {
        // Should have been filtered previously
        throw "Zero balance encountered";
      }

      i++
    }

    // Update system balance
    newCLS.getSystemAccountRecord().setBalance(systemBalance);
  }

  /**
   * Checks to see if user account passed in exists in the CLS
   */
  async isAccountInCLS(testUserAccount: Uint8Array): Promise<boolean> {
    if (this.currentCLS != null) {
      const tokenAccountRecords = this.currentCLS.getTokenAccountRecords();

      for (let i = 0; i < tokenAccountRecords.getLength(); i++) {
        const userAccount = tokenAccountRecords
          .get(i)
          .getUserAccount()
          .toUint8Array();
        if (this.isBufferEqual(testUserAccount, userAccount)) {
          return true; // found matching public key
        }
      }
      return false;
    }

    return false; // No match found
  }

  isBufferEqual(buf1: Uint8Array, buf2: Uint8Array) {
    if (buf1.byteLength != buf2.byteLength) return false;
    for (let i = 0; i != buf1.byteLength; i++) {
      if (buf1[i] != buf2[i]) return false;
    }
    return true;
  }

  /**
   * Checks to see if user account passed in exists in the CLS
   */
  async getAccountBalanceInCLS(testUserAccount: Uint8Array): Promise<number | null> {
    if (this.currentCLS !== null) {
      const tokenAccountRecords = this.currentCLS.getTokenAccountRecords();

      for (let i = 0; i < tokenAccountRecords.getLength(); i++) {
        const userAccount = tokenAccountRecords
          .get(i)
          .getUserAccount()
          .toUint8Array();
        if (this.isBufferEqual(testUserAccount, userAccount)) {
          return tokenAccountRecords.get(i).getBalance(); // found matching public key
        }
      }
    }
    return null; // No match found
  }

  /**
   * Resets the state of the block chain.
   */
  reset() {
    this._genesisBlockWrapper = null;
    this._genesisCLS = null;
    this._lastBlocks = [];
    this._lastClsList = [];
    this._chainNumber = null;
  }

  /**
   * Determine epoch number for next block.
   */
  get nextBlockEpochNumber() {
    return this.getEpochNumber(this.nextBlockNumber);
  }

  /**
   * Gets current block number, returns null if genesis block has not been received.
   */
  get currentBlockNumber() {
    if (this._genesisBlockWrapper == null) {
      return null;
    } else {
      if (this.currentCLS !== null) {
        return this.currentCLS.getBlockNumber();
      } else {
        return null;
      }
    }
  }

  /**
   * Block number for next block to be written.
   */
  get nextBlockNumber() {
    if (this.currentBlockNumber == null) {
      return 0; // Next block is genesis block
    } else {
      return this.currentBlockNumber + 1;
    }
  }

  // /**
  //  * Sort sanitized VUTs by signature
  //  */
  // VUTSortFn(a: VUT, b: VUT): number {
  //   const uutA: UUT = a.getUut();
  //   const uutB: UUT = b.getUut();

  //   const sigA = uutA.getSenderSignature().toString;
  //   const sigB = uutB.getSenderSignature().toString;

  //   if (sigA < sigB) {
  //     return -1;
  //   } else if (sigA > sigB) {
  //     return 1;
  //   } else {
  //     return 0;
  //   }
  // }
}

export { BlockChain };
