/* tslint:disable */

/**
 * This file has been automatically generated by the [capnpc-ts utility](https://github.com/jdiaz5513/capnp-ts).
 */

import * as capnp from "capnp-ts";
import { ObjectSize as __O, Struct as __S } from 'capnp-ts';
import { ActiveNodeRecord, TokenAccountRecord, SystemAccountRecord, CLS } from "./cls.capnp";
export const _capnpFileId = "b8e0c7405f6f776d";
export class FixedChainParameters extends __S {
    static readonly _capnp = { displayName: "FixedChainParameters", id: "a9147ea4c3f656bb", size: new __O(8, 1) };
    adoptGeeqCorpPublicKey(value: capnp.Orphan<capnp.Data>): void { __S.adopt(value, __S.getPointer(0, this)); }
    disownGeeqCorpPublicKey(): capnp.Orphan<capnp.Data> { return __S.disown(this.getGeeqCorpPublicKey()); }
    getGeeqCorpPublicKey(): capnp.Data { return __S.getData(0, this); }
    hasGeeqCorpPublicKey(): boolean { return !__S.isNull(__S.getPointer(0, this)); }
    initGeeqCorpPublicKey(length: number): capnp.Data { return __S.initData(0, length, this); }
    setGeeqCorpPublicKey(value: capnp.Data): void { __S.copyFrom(value, __S.getPointer(0, this)); }
    getMaxActiveNodes(): number { return __S.getUint32(0, this); }
    setMaxActiveNodes(value: number): void { __S.setUint32(0, value, this); }
    getBlockTimeArrival(): number { return __S.getUint32(4, this); }
    setBlockTimeArrival(value: number): void { __S.setUint32(4, value, this); }
    toString(): string { return "FixedChainParameters_" + super.toString(); }
}
export class GenesisBlockData extends __S {
    static readonly _capnp = { displayName: "GenesisBlockData", id: "9b1d0e66c1002f9f", size: new __O(8, 2) };
    getBlockNumber(): number { return __S.getUint32(0, this); }
    setBlockNumber(value: number): void { __S.setUint32(0, value, this); }
    getChainNumber(): number { return __S.getUint32(4, this); }
    setChainNumber(value: number): void { __S.setUint32(4, value, this); }
    adoptCls(value: capnp.Orphan<CLS>): void { __S.adopt(value, __S.getPointer(0, this)); }
    disownCls(): capnp.Orphan<CLS> { return __S.disown(this.getCls()); }
    getCls(): CLS { return __S.getStruct(0, CLS, this); }
    hasCls(): boolean { return !__S.isNull(__S.getPointer(0, this)); }
    initCls(): CLS { return __S.initStructAt(0, CLS, this); }
    setCls(value: CLS): void { __S.copyFrom(value, __S.getPointer(0, this)); }
    adoptFixedParameters(value: capnp.Orphan<FixedChainParameters>): void { __S.adopt(value, __S.getPointer(1, this)); }
    disownFixedParameters(): capnp.Orphan<FixedChainParameters> { return __S.disown(this.getFixedParameters()); }
    getFixedParameters(): FixedChainParameters { return __S.getStruct(1, FixedChainParameters, this); }
    hasFixedParameters(): boolean { return !__S.isNull(__S.getPointer(1, this)); }
    initFixedParameters(): FixedChainParameters { return __S.initStructAt(1, FixedChainParameters, this); }
    setFixedParameters(value: FixedChainParameters): void { __S.copyFrom(value, __S.getPointer(1, this)); }
    toString(): string { return "GenesisBlockData_" + super.toString(); }
}
export class GenesisBlock extends __S {
    static readonly _capnp = { displayName: "GenesisBlock", id: "f2034aa2633546c1", size: new __O(0, 2) };
    adoptBlockData(value: capnp.Orphan<GenesisBlockData>): void { __S.adopt(value, __S.getPointer(0, this)); }
    disownBlockData(): capnp.Orphan<GenesisBlockData> { return __S.disown(this.getBlockData()); }
    getBlockData(): GenesisBlockData { return __S.getStruct(0, GenesisBlockData, this); }
    hasBlockData(): boolean { return !__S.isNull(__S.getPointer(0, this)); }
    initBlockData(): GenesisBlockData { return __S.initStructAt(0, GenesisBlockData, this); }
    setBlockData(value: GenesisBlockData): void { __S.copyFrom(value, __S.getPointer(0, this)); }
    adoptGeeqcorpSignature(value: capnp.Orphan<capnp.Data>): void { __S.adopt(value, __S.getPointer(1, this)); }
    disownGeeqcorpSignature(): capnp.Orphan<capnp.Data> { return __S.disown(this.getGeeqcorpSignature()); }
    getGeeqcorpSignature(): capnp.Data { return __S.getData(1, this); }
    hasGeeqcorpSignature(): boolean { return !__S.isNull(__S.getPointer(1, this)); }
    initGeeqcorpSignature(length: number): capnp.Data { return __S.initData(1, length, this); }
    setGeeqcorpSignature(value: capnp.Data): void { __S.copyFrom(value, __S.getPointer(1, this)); }
    toString(): string { return "GenesisBlock_" + super.toString(); }
}
