/**
 * NodeTxBundle class handles construction of the NTB to be sent to the hub.
 */
import { UUT } from '@/generated/uut.capnp'
import { NTB, NtbData } from '@/generated/ntb.capnp'
import { Message, Uint64 } from "capnp-ts";
import { GeeqNode } from "@/models/actors/GeeqNode"
import { trucatedBuf2hex } from "@/models/formatUtils"
import GeeqConstants from "@/models/actors/nodeutils/GeeqConstants"
import { NtbWrapper } from "@/models/capnpMessages/NtbWrapper"

// import { NTB, NTB_Data } from '../../generated/src/protobuffer/ntb_pb'
// import GeeqConstants from './GeeqContants'
// import GeeqNode from './GeeqNode'
// import EventLogger from './EventLogger';

export default class NodeTxBundleMgr {

    private _rejectedUUTs: UUT[] = []   // List of UUTs received that were rejected for inclusion in next block
    private _acceptedUUTs: UUT[] = []    // List of UUTs that passed sanity test for inclusion in next block

    private _myGeeqNode: GeeqNode  // Block chain storage (Same singleton as GeeqNode's block chain)

    // Debug flags
    private _debugRejection = false

    constructor(myGeeqNode: GeeqNode) {
        this._myGeeqNode = myGeeqNode
    }

    get rejectedUUTs() {
        return this._rejectedUUTs
    }

    get acceptedUUTs() {
        return this._acceptedUUTs
    }

    // Simulation reset
    resetState() {
        // Note:  Can't reset these instance vars to [], since the reference have been copied by the Geeq Node Card UI
        this._rejectedUUTs.length = 0;
        this._acceptedUUTs.length = 0;
    }

    /**
     * Process UUTs.  Perform sanity checks on the UUTs and place them in an accepted or rejected list.
     * 
     * TODO: Record reason for rejections.
     */
    processUUT(uut: UUT): boolean {
        // Process UUT for the next block to be saved
        const uutData = uut.getUutData();
        const amount = uutData.getAmount();
        const senderPublicKey: ArrayBuffer = uutData.getSenderPublicKey().toArrayBuffer();
        const receiverAccount: ArrayBuffer = uutData.getReceiverAccount().toArrayBuffer();

        const { isValid, reason } = this.isValidUUT(uut);

        if (isValid) {
            this._acceptedUUTs.push(uut)  // sanitized list
            this._myGeeqNode.addToLog(
              `Processed valid UUT. (From: ${trucatedBuf2hex(senderPublicKey)}, To: ${trucatedBuf2hex(receiverAccount)}, Amount: ${amount})`,
              null);
              
            return true
        } else {
            this._myGeeqNode.addToLog(
                `Invalid UUT received - ${reason} (From: ${trucatedBuf2hex(senderPublicKey)}, To: ${trucatedBuf2hex(receiverAccount)}, Amount: ${amount})`,
                null);

            this._rejectedUUTs.push(uut) // unsanitized list
            return false
        }
    }

    recordUUTWithBadSignature(uut: UUT) {
        // Process UUT for the next block to be saved
        const uutData = uut.getUutData();
        const amount = uutData.getAmount();
        const senderPublicKey: ArrayBuffer = uutData.getSenderPublicKey().toArrayBuffer();
        const receiverAccount: ArrayBuffer = uutData.getReceiverAccount().toArrayBuffer();

        this._myGeeqNode.addToLog(
            `Invalid UUT - bad signature. (From: ${trucatedBuf2hex(senderPublicKey)}, To: ${trucatedBuf2hex(receiverAccount)}, Amount: ${amount})`,
            null);

        // Add to unsanitized list
        this._rejectedUUTs.push(uut) 
    }

    /**
     * Construct NTB from current list of sanitized UUTs.  Also has side-effect of resetting the 
     * current state.  This is in preparation of construction an NTB for the next block.
     */
    public async createNTBWrapper(): Promise<NtbWrapper> {
        const acceptedN = this.acceptedUUTs.length;
        const rejectedN = this.rejectedUUTs.length;

        this._myGeeqNode.addToLog(`Created NTB (UUTs accepted = ${acceptedN}, rejected = ${rejectedN}, total = ${acceptedN + rejectedN})`, null)

        // Create new block data
        const message = new Message();
        const ntb = message.initRoot(NTB);
        const innerMessage = new Message();
        const ntbData = innerMessage.initRoot(NtbData);

        if (this._myGeeqNode.myBlockChain != null) {

            // Chain, block and epoch numbers
            if (this._myGeeqNode.myBlockChain.chainNumber != null) {
                ntbData.setChainNumber(this._myGeeqNode.myBlockChain.chainNumber)
            } else { 
                throw("Missing chain number")
            }
            ntbData.setBlockNumber(this._myGeeqNode.myBlockChain.nextBlockNumber)
            ntbData.setEpochNumber(this._myGeeqNode.myBlockChain.nextBlockEpochNumber);

            // Hub public key
            if (this._myGeeqNode.myBlockChain.currentHubNodePublicKey != null) {
                const hubPublicKey = this._myGeeqNode.myBlockChain.currentHubNodePublicKey
                ntbData.initCurrentHubPublicKey(hubPublicKey.byteLength)
                ntbData.getCurrentHubPublicKey().copyBuffer(hubPublicKey)
            } else {
                throw("Missing current hub public key")
            }

            // Node public key
            if (this._myGeeqNode.publicKey != null) {
                const publicKey = this._myGeeqNode.publicKey
                ntbData.initNodePublicKey(publicKey.byteLength)
                const nodePublicKey = ntbData.getNodePublicKey();
                nodePublicKey.copyBuffer(publicKey);
            } else {
                throw("Missing node public key")
            }

            if (this._myGeeqNode.myBlockChain.currentClsHash != null) {
                ntbData.initPrevClsHash(this._myGeeqNode.myBlockChain.currentClsHash.byteLength)
                ntbData.getPrevClsHash().copyBuffer(this._myGeeqNode.myBlockChain.currentClsHash)
            } else {
                throw("Missing current cls hash")
            }

        } else {
            throw("Missing MyGeeqNode")
        }

        // Add sanitized UUTs, first sort by signature (see spec)
        this.acceptedUUTs.sort(this.UUTSortFn)
        const gUUTs = ntbData.initUnverifiedUserTransactions(this.acceptedUUTs.length)
        for (let i = 0; i < this.acceptedUUTs.length; i++) {
            const gUUT = gUUTs.get(i);
            gUUT.setUutData(this.acceptedUUTs[i].getUutData())
            gUUT.setSenderSignature(this.acceptedUUTs[i].getSenderSignature())
        }
        ntbData.setUnverifiedUserTransactions(gUUTs);

        ntb.setNtbData(ntbData)
        ntb.initNodeSignature(64);  // Allocate space for signature

        // Reset state in preparation for next NTB to be created
        this.reset()

        // Create wrapper and sign
        const ntbWrapper: NtbWrapper = new NtbWrapper(message.toPackedArrayBuffer());
        return ntbWrapper;
    }

    /**
     * Checks UUT for sanity to be included in next block
     */
    isValidUUT(uut: UUT) {
        const uutData = uut.getUutData()

        // Check if UUT block number is valid
        const ntbBlockNumber = this._myGeeqNode.ntbTargetBlockNumber;
        if (!this.isValidUUTForBlockNumber(uut, ntbBlockNumber)  ) {
            return { isValid: false, reason: "Invalid block number" }
        }

        // Test for zero amount
        const amount = uutData.getAmount();
        if (amount.equals(Uint64.fromNumber(0))) {
            return { isValid: false, reason: "Amount cannot be zero." }
        }

        // Test for self transfer
        // let sender_key_b64 = uut_data.getSenderPublicKey_asB64()
        // let receiver_key_b64 = uut_data.getReceiverPublicKey_asB64()
        // if (sender_key_b64 === receiver_key_b64) {
        //     this.logRejection(`self transfer`)
        //     return false
        // }

        // Test account exists
        // let public_key = uut_data.getSenderPublicKey_asU8()
        // if (!this._my_geeq_node.block_chain.isAccountInCLS(public_key)) {
        //     this.logRejection(`bad public key`)
        //     return false
        // }

        // Test to see if there are enough Geeqcoin in the account
        // let tx_amount = uut_data.getAmount()
        // let account_bal = this._my_geeq_node.block_chain.getAccountBalanceInCLS(public_key)
        // if (account_bal == null) {
        //     this.logRejection(`no account`)
        //     return false
        // } else {
        //     if (tx_amount > account_bal) {
        //         this.logRejection(`amount greater than account balance`)
        //         return false
        //     }
        // }

        // Passed all tests
        return { isValid: true, reason: "" }
    }

    // logRejection(message : string) : void {
    //     if (this._debug_rejection) {
    //         this.logger.log(`${this._my_geeq_node.node_name} rejected ` + message)
    //     }
    // }

    /**
     * Check UUT block number validity.
     */
    isValidUUTForBlockNumber(uut: UUT, nextBlockNumber: number): boolean {

        // UUT target block 
        const targetBlockNumber = uut.getUutData().getBlockNumber()

        // Check validity of target_block_number
        if (targetBlockNumber > nextBlockNumber + 1) {
            // Do not accept transactions that are too far into the future
            return false
        }

        // Check to see tx is intended for a block that is too far in the past
        if (targetBlockNumber + GeeqConstants.UUT_MAX_BLOCK_DELAY < nextBlockNumber + 1) {
            return false
        }

        return true
    }

    // Note: Validate signature moved to before calling processUUT()

    /**
     * Sort sanitized UUTs by signature
     */
    UUTSortFn(a: UUT, b: UUT): number {
        const sigA = a.getSenderSignature().toUint8Array().toString();
        const sigB = b.getSenderSignature().toUint8Array().toString();

        if (sigA < sigB) {
            return -1
        } else if (sigA > sigB) {
            return 1
        } else {
            return 0
        }
    }

    /**
     * Throw away state related to received UUTs.
     */
    reset() {
        this._rejectedUUTs = []
        this._acceptedUUTs = []
    }
}

export { NodeTxBundleMgr }