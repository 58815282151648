// Libraries
import { Message, Uint64 } from 'capnp-ts';

// Components
import { EventStyles } from "@/components/LogSytle"

// Capnp messages
import { UUT, UutData } from '@/generated/uut.capnp'
import { UutWrapper } from "@/models/capnpMessages/UutWrapper";

// Model utilities
import { LogEvent, logTypeEnum, LogFullDescriptionEnum} from '@/models/LogEvent'
import { trucatedBuf2hex } from '@/models/formatUtils'

// Actor related
import { NETWORK_API } from '@/models/TheNetwork'
import { NonGeeqCorpActor } from '@/models/Actor'
import { InfoButtonText } from '@/assets/resources';


class GeeqUser extends NonGeeqCorpActor {
  _initialBalance: number;
  _balance: number;
  genesisBlockBuffer: Uint8Array | null = null;
  _isVisible: boolean;
  _userName: string;
  _isPrimary: boolean;
  _userTxNumber: number;
  _currentBlockNumber: number;
  _isNodeUser: boolean;

  get isNodeUser(): boolean {
    return this._isNodeUser
  }

  get isPrimary(): boolean {
    return this._isPrimary;
  }
  get userName(): string {
    return this._userName;
  }

  get isVisible(): boolean {
    return this._isVisible;
  }

  get initialBalance() {
    return this._initialBalance;
  }

  get balance() {
    return this._balance;
  }

  set balance(value: number) {
    this._balance = value
  }

  // Hack for now, since we are getting the node list from the Genesis block instead of asking a Node
  get nodeList(): Array<number> {
    if (this.genesisBlockWrapper !== null) {
      const gBlock = this.genesisBlockWrapper.genesisBlock
      const gCls = gBlock.getBlockData().getCls()

      const hboList = gCls.getHubOrderList()

      return hboList.toArray()
    }
    return [];
  }

  get targetUserList(): Array<string> {
    if (this.genesisBlockWrapper !== null) {
      const clsJSON = this.genesisBlockWrapper.clsJSON;
      const tokenAccountRecords: { [k: string]: unknown }[] = clsJSON.tokenAccountRecords;
      const publicKeyList: Array<string> = [];
      tokenAccountRecords.forEach((tokenAccountRecord: { [k: string]: unknown }) => {
        console.log(tokenAccountRecord['publicKey']);
        const publicKey: string = tokenAccountRecord['publicKey'] as string;
        publicKeyList.push(publicKey);
      });
      return publicKeyList;
    }
    return [];
  }

  constructor(userName: string, geeqCorpPublicKey: Uint8Array, initialBalance: number, isPrimary: boolean, isNodeUser: boolean, isVisible: boolean, loggerFn: { ( event: LogEvent): void }) {
    super(geeqCorpPublicKey, loggerFn);
    this.myLogEventType = logTypeEnum.GEEQ_USER;
    this._initialBalance = initialBalance;
    this._balance = this._initialBalance;
    this._isPrimary = isPrimary;
    this._isNodeUser = isNodeUser;
    this._isVisible = isVisible;
    this._userName = userName;
    this._userTxNumber = 0;
    this._currentBlockNumber = 0;

    // Event Log styling
    this._eventColor = EventStyles.GEEQ_USER;
    return;
  }

  nextUserTxNumber(blockNumber: number) {
      if (blockNumber == this._currentBlockNumber) {
        this._userTxNumber++
      } else {
        this._currentBlockNumber = blockNumber
        this._userTxNumber = 0
      }
      return  this._userTxNumber
  }

  get targetChainNumber () {
    if (this.genesisBlockWrapper != null) {
      return this.genesisBlockWrapper.genesisBlock.getBlockData().getChainNumber()
    } else {
      throw('Missing Genesis Block')
    }
  }

  // Hack, should get CLS that it trusts
  getNodePublicKey(nodeIndex: number): ArrayBuffer | null {
    if (this.genesisBlockWrapper !== null) {
      const gBlock = this.genesisBlockWrapper.genesisBlock
      const gCls = gBlock.getBlockData().getCls()
      const activeNodeList = gCls.getActiveNodeList()

      const activeNodeRecord = activeNodeList.get(nodeIndex)
      return activeNodeRecord.getNodePublicKey().toArrayBuffer()
    }
    return null;
  }

  async sendTransaction(nodeIndex: number, targetUserAccountBuffer: ArrayBuffer, amount: number): Promise<void> {
    // Create new block data
    const message = new Message();
    const uut = message.initRoot(UUT);

    // Obtain node public key
    const nodePublicKey = this.getNodePublicKey(nodeIndex)

    // TODO: Need to get 
    const targetBlockNumber = 0
    const userTxNumber = this.nextUserTxNumber(targetBlockNumber)

    if (nodePublicKey != null) {
      const uutData: UutData = this.createUutData(this.targetChainNumber, targetBlockNumber, userTxNumber, nodePublicKey, targetUserAccountBuffer, amount);
      // Initialize block with data
      uut.setUutData(uutData);
    } else {
      throw("Missing node public key")
    }

    // Allocate space for signature
    uut.initSenderSignature(64);

    // Add signature
    const uutWrapper = new UutWrapper(message.toPackedArrayBuffer())
    await uutWrapper.signUUT(this.keys)

    // Log that we created the UUT
    const uutAmount = uut.getUutData().getAmount();
    const shortPublicKey = trucatedBuf2hex(targetUserAccountBuffer);
    this.addToLog(`UUT generated (destination nodeIndex=${nodeIndex}, recepient key = ${shortPublicKey}, amt=${uutAmount})`, uutWrapper, InfoButtonText.UUT), LogFullDescriptionEnum.UUT;
    
    // Send UUT the node
    this.sendUUT(nodeIndex, uutWrapper.sourceBuffer);

  }

  private sendUUT(nodeIndex: number, uutBuffer: ArrayBuffer) {
    // TODO:  Fix this when we maintain the latest known CLS
    const targetIpAddr = this.getNodeIpAddr(nodeIndex);

    if (targetIpAddr !== null) {
      this.theNetwork?.apiCall(targetIpAddr, this, NETWORK_API.NODE_PROCESS_UTB, uutBuffer);
    } else {
      throw('Null ip address');
    }
 
  }

  private createUutData(chainNumber: number, blockNumber: number, txNumber: number, nodePublicKey: ArrayBuffer, targetUserAccountBuffer: ArrayBuffer, amount: number) {
    const innerMessage = new Message();
    const uutData = innerMessage.initRoot(UutData);

    // Initialize header
    uutData.initNodePublicKey(nodePublicKey.byteLength)
    uutData.getNodePublicKey().copyBuffer(nodePublicKey);
    uutData.setAmount(Uint64.fromNumber(amount));

    uutData.setChainNumber(chainNumber)
    uutData.setBlockNumber(blockNumber)
    uutData.setUserTxNumber(txNumber)

    // Save my public key
    uutData.initSenderPublicKey(this.publicKey.byteLength);
    const senderPublicKeyField = uutData.getSenderPublicKey();
    senderPublicKeyField.copyBuffer(this.publicKey);

    // Save my target public key
    uutData.initReceiverAccount(targetUserAccountBuffer.byteLength);
    const receiverAccountField = uutData.getReceiverAccount();
    receiverAccountField.copyBuffer(targetUserAccountBuffer);
    return uutData;
  }

}

export { GeeqUser };
