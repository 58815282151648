/*
 * Fun with crypto.  Using the browser crypto library
 * https://github.com/diafygi/webcrypto-examples#ecdsa---sign
 * https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto
 *
 */

import { buf2hex } from "./formatUtils"

const HASH_BYTE_LENGTH = 64

/*
 * Private functions
 */
async function importPublicKey(
  buffer: ArrayBuffer,
  format: string
): Promise<CryptoKey> {
  return new Promise((resolve) => {
    window.crypto.subtle
      .importKey(
        format, //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
        buffer,
        {
          //these are the algorithm options
          name: "ECDSA",
          namedCurve: "P-256", //can be "P-256", "P-384", or "P-521"
        },
        true, //whether the key is extractable (i.e. can be used in exportKey)
        ["verify"] //"verify" for public key import, "sign" for private key imports
      )
      .then(function(publicKey) {
        resolve(publicKey);
        //returns a publicKey (or privateKey if you are importing a private key)
      });
  });
}

/*
 * Public functions
 */
async function verify(
  message: ArrayBuffer,
  publicKeyBuffer: ArrayBuffer,
  signature: ArrayBuffer
): Promise<boolean> {
  const publicKey = await importPublicKey(publicKeyBuffer, "spki");

  return new Promise((resolve) => {
    window.crypto.subtle
      .verify(
        {
          name: "ECDSA",
          hash: { name: "SHA-256" }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
        },
        publicKey, //from generateKey or importKey above
        signature, //ArrayBuffer of the signature
        message //ArrayBuffer of the data
      )
      .then(function(isvalid) {
        resolve(isvalid);
        //returns a boolean on whether the signature is true or not
      });
  });
}

/*
 * Hashes an array buffer and returns the digest.  Returning the specified number of leading bytes.  (-1 => all bytes)
 */
async function hashBuffer(buffer: ArrayBuffer, numBytes = -1) {
  const fullDigest = await crypto.subtle.digest("SHA-512", buffer);

  if (numBytes < 0) {
    return fullDigest
  } else {
    // Make sure we don't go beyond the end of the digest
    const n = Math.min(numBytes, fullDigest.byteLength)
    return fullDigest.slice(0, n)
  }
}

/*
 * Produces an account by taking the first 32 bytes of the hash of the public key.
 */
async function accountFromPubicKey(publicKey: ArrayBuffer) {
  return hashBuffer(publicKey, 32)
}

/*
 * Public classes
 */

 /*
  *  Represents a keypair created using ECDSA (P-256) 
  */
class ECKeys {
  publicKey: ArrayBuffer | null = null;

  private keyPair: CryptoKeyPair | null = null;
  private privateKey: CryptoKey | null = null;
  private _publicKeyDigest: ArrayBuffer | null = null;

  /* 
   * Generate the keypair, and expose the public key as an ArrayBuffer.
   */
  async newKeyPair() {
    this.keyPair = await this.getKeyPair();
    this.publicKey = await this.extractPublicKey(
      this.keyPair.publicKey,
      "spki"
    );
    this.privateKey = this.keyPair.privateKey;

    // Get hash of public key
    const digest = await accountFromPubicKey(this.publicKey)
    this._publicKeyDigest = digest
  }

  /*
   * Sign a message using the private key.
   */
  async sign(message: ArrayBuffer): Promise<ArrayBuffer> {
    if (this.keyPair !== null) {
      const privateKey = this.keyPair.privateKey;

      return new Promise((resolve) => {
        window.crypto.subtle
          .sign(
            {
              name: "ECDSA",
              hash: { name: "SHA-256" }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
            },
            privateKey, //from generateKey or importKey above
            message //ArrayBuffer of data you want to sign
          )
          .then(function(signature) {
            resolve(signature);
            //returns an ArrayBuffer containing the signature
          });
      });
    } else {
      throw "Uninitialized KeyPair";
    }
  }

  /*
   * Generate a hash digest of the public key
   */
  get publicKeyDigest(): ArrayBuffer {
    if (this._publicKeyDigest != null) {
      return this._publicKeyDigest
    } else {
      throw("public key not available yet")
    }
  }


  private async getKeyPair(): Promise<CryptoKeyPair> {
    return new Promise((resolve) => {
      window.crypto.subtle
        .generateKey(
          {
            name: "ECDSA",
            namedCurve: "P-256",
          },
          true, // Extractable?
          ["sign", "verify"] //can be any combination of "sign" and "verify"
        )
        .then(function(key: CryptoKeyPair) {
          resolve(key);
          // console.log("Generate Key");
          // console.log(key);
          // console.log(key.publicKey);
          // console.log(key.privateKey);
        });
    });
  }

  private async extractPublicKey(
    publicKey: CryptoKey,
    format: string
  ): Promise<ArrayBuffer> {
    return new Promise((resolve) => {
      window.crypto.subtle
        .exportKey(
          format, //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
          publicKey //can be a publicKey or privateKey, as long as extractable was true
        )
        .then(function(keydata) {
          resolve(keydata as ArrayBuffer);
        });
    });
  }
}

// import { trucatedBuf2hex } from "@/models/formatUtils";

// async function testCrypto() {
//   const k1: ECKeys = new ECKeys();
//   await k1.newKeyPair();
//   const k1PubKey = k1.publicKey;
//   if (k1PubKey != null) {
//     console.log(trucatedBuf2hex(k1PubKey));
//   }

//   const testMessage: ArrayBuffer = new ArrayBuffer(10);
//   const testMessage2: ArrayBuffer = new ArrayBuffer(5);

//   const k2: ECKeys = new ECKeys();
//   await k2.newKeyPair();
//   const k2PubKey = k2.publicKey;
//   if (k2PubKey != null) {
//     console.log(trucatedBuf2hex(k2PubKey));
//   }

//   if (k1PubKey) {
//     const k1sig = await k1.sign(testMessage);
//     console.log(await verify(testMessage, k1PubKey, k1sig));

//     const bufView: Uint8Array = new Uint8Array(k1PubKey);

//     console.log(bufView.toString());
//   }

//   if (k2PubKey) {
//     const k2sig = await k2.sign(testMessage);
//     console.log(await verify(testMessage, k2PubKey, k2sig));

//     const bufView: Uint8Array = new Uint8Array(k2PubKey);

//     console.log(bufView.toString());
//   }
  
// }

export { ECKeys, verify, accountFromPubicKey, hashBuffer, HASH_BYTE_LENGTH };
