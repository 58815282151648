enum STATES_TYPE {
    UNINITIALIZED = 'UNINITIALIZED',                // Starting state when node is created
    WAIT_FOR_SIM_START = 'WAIT_FOR_SIM_START',      // Genesis block initialized, waiting for signal that simulation started       
    WAIT_FOR_TXS = 'WAIT_FOR_TXS',                  // Waiting for UUTs to place into the next block
    WAIT_FOR_NTBS = 'WAIT_FOR_NTBS',                // Hub only: Waiting for NTBs to place into the next HTB
    SEND_HTB = 'SEND_HTB',                          // Hub only: Send HTB to all nodes
    SEND_NTB = 'SEND_NTB',                          // Non-hub only: Send NTB to hub
    WAIT_FOR_HTB = 'WAIT_FOR_HTB'                   // Waiting for HTB.
}

export default class NodeState {

    state: STATES_TYPE;
    myCallbacks: Map<STATES_TYPE, any> = new Map<STATES_TYPE, any>();
    anyChangeCallback: any = null;

    constructor() {
        this.state = STATES_TYPE.UNINITIALIZED;
    }

    // The registered callback will be invoked anytime a state is changed
    registerAnyStateChangeCallback(fn: any) {
        this.anyChangeCallback = fn;
    }

    // The registered callback will be invoked when the state is set to the specified state
    registerStateCallback(state: STATES_TYPE, callback: any) {
        this.myCallbacks.set(state, callback);
    }

    updateState = (state: STATES_TYPE) => {
        this.state = state

        if (this.myCallbacks.has(state)) {
            const fn = this.myCallbacks.get(state);
            fn();
        }

        if (this.anyChangeCallback != null) {
            const fn2 = this.anyChangeCallback;
            fn2();
        }
    }

}

export { NodeState, STATES_TYPE }
