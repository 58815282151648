import { Message } from "capnp-ts";

import { Wrapper } from "@/models/capnpMessages/WrapperInterface"

// Capnp messages
import { CLS } from "@/generated/cls.capnp"

// Utilities
import { getClsJSON } from "@/models/capnpMessages/ClsJson"
import { hashBuffer } from "@/models/cryptoUtils"

/*
 * Returns a newly created message buffer from an existing CLS.
 */
export function getClsRaw(gCLS: CLS) {
  const message = new Message()
  const cls = message.initRoot(CLS)

  // Copy header 
  cls.setBlockNumber(gCLS.getBlockNumber())
  cls.setEpochNumber(gCLS.getEpochNumber())
  cls.setChainNumber(gCLS.getChainNumber())

  cls.initHubOrderList(gCLS.getHubOrderList().getLength())
  cls.setHubOrderList(gCLS.getHubOrderList())

  const prevBlockHash = gCLS.getPrevBlockHash()
  cls.initPrevBlockHash(prevBlockHash.getLength())
  cls.getPrevBlockHash().copyBuffer(prevBlockHash.toArrayBuffer())
  
  const prevCLSHash = gCLS.getPrevCLSHash()
  cls.initPrevCLSHash(prevCLSHash.getLength())
  cls.getPrevCLSHash().copyBuffer(prevCLSHash.toArrayBuffer())

  // Copy Records
  cls.initActiveNodeList(gCLS.getActiveNodeList().getLength())
  cls.setActiveNodeList(gCLS.getActiveNodeList())

  cls.initTokenAccountRecords(gCLS.getTokenAccountRecords().getLength())
  cls.setTokenAccountRecords(gCLS.getTokenAccountRecords())

  cls.setSystemAccountRecord(gCLS.getSystemAccountRecord())

  return message.toPackedArrayBuffer()

}

/*
 * Instantiates a CLS block message from a buffer.
 */
function clsFromBuffer(
  buffer: ArrayBuffer
) {
  const message = new Message(buffer);
  return message.getRoot(CLS);
}

/*
 * Transforms the contents of the UTB from the capnp message format
 * in an ArrayBuffer to a human readable JSON format.
 */
export function clsJson(buffer: ArrayBuffer): Record<string, unknown>  {

  const cls: CLS = clsFromBuffer(buffer);

  return getClsJSON(cls)
}

/*
 * This class wraps an ArrayBuffer that contains a capnp GenesisBlock message.
 */
export class ClsWrapper implements Wrapper {
  _clsBuffer: ArrayBuffer;
  _cls: CLS;

  get sourceBuffer(): ArrayBuffer {
    return this._clsBuffer;
  }

  // Returns the json that represents the contents of this message
  get json(): Record<string, unknown> {
    return clsJson(this._clsBuffer);
  }

  // Returned the cached instance of a capnp GenesisBlock.  This is assuming
  // that this object is never modified by the code that obtains this object.
  get cls(): CLS {
    return this._cls;
  }

  constructor(uutBuffer: ArrayBuffer) {
    this._clsBuffer = uutBuffer;
    this._cls = clsFromBuffer(uutBuffer);
  }

  async getHash(): Promise<ArrayBuffer> {
    return await hashBuffer(this._clsBuffer)
  }
}

export default {
    ClsWrapper, getClsRaw
};
