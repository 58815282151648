import { Message } from "capnp-ts";
import {
  ActiveNodeRecordNodeStatus,
} from "@/generated/cls.capnp";

import { GenesisBlock } from "@/generated/genesisBlock.capnp";
import { verify, ECKeys } from "@/models/cryptoUtils";

import { buf2hex } from "@/models/formatUtils";
import { Wrapper } from "@/models/capnpMessages/WrapperInterface"
import { getClsJSON } from "@/models/capnpMessages/ClsJson"

/*
 * Instantiates a Genesis block message from a buffer.
 */
function genesisBlockFromBuffer(
  buffer: ArrayBuffer
) {
  const message = new Message(buffer);
  return message.getRoot(GenesisBlock);
}

/*
 *  Verifies that the provided public key was the key used to sign the
 *  Genesis block data.
 */
async function verifyGenesisBlockBuffer(
  publicKeyBuffer: ArrayBuffer,
  gBlockBuffer: ArrayBuffer
): Promise<boolean> {
  // Copy block data (ie. no signature)
  const duplicateMessage = new Message();
  const dupRoot = duplicateMessage.initRoot(GenesisBlock);
  const gBlock = genesisBlockFromBuffer(gBlockBuffer);
  dupRoot.setBlockData(gBlock.getBlockData());

  // Allocate space for signature
  dupRoot.initGeeqcorpSignature(64);

  // Compute signature
  const blockBuffer = new Uint8Array(duplicateMessage.toPackedArrayBuffer());

  const signature = gBlock.getGeeqcorpSignature();
  const signatureBuffer = signature.toArrayBuffer();
  const result = await verify(blockBuffer, publicKeyBuffer, signatureBuffer);
  return result;
}

/*
 * Maps constants for Active Node Record status to a human readable text string.
 */
export function nodeStatusText(nodeStatus: ActiveNodeRecordNodeStatus): string {
  switch (nodeStatus) {
    case ActiveNodeRecordNodeStatus.ACTIVE:
      return "Active";
    case ActiveNodeRecordNodeStatus.INACTIVE:
      return "Active";
    case ActiveNodeRecordNodeStatus.LEAVING:
      return "Active";
    case ActiveNodeRecordNodeStatus.SUSPENDED:
      return "Active";
  }
  return "Unknown";
}





/*
 * Transforms the contents of the Genesis block from the capnp message format
 * in an ArrayBuffer to a human readable JSON format.
 */
export function genesisBlockJson(buffer: ArrayBuffer): Record<string, unknown>  {

  const gBlock: GenesisBlock = genesisBlockFromBuffer(buffer);

  // Signature
  const signature = gBlock.getGeeqcorpSignature();
  const signatureBuffer: ArrayBuffer = signature.toArrayBuffer();
  const signatureText = buf2hex(signatureBuffer);

  const gBlockData = gBlock.getBlockData();

  // Header
  const blockNumber = gBlockData.getBlockNumber();
  const chainNumber = gBlockData.getChainNumber();

  // CLS
  const clsJSON = getClsJSON(gBlockData.getCls());

  return {
    GenesisBlockData: {
      chainNumber : chainNumber,
      blockNumber : blockNumber,
      CLS : clsJSON
    },
    Signature : signatureText
  };
}

/*
 * This class wraps an ArrayBuffer that contains a capnp GenesisBlock message.
 */
export class GenesisBlockWrapper implements Wrapper {
  _genesisBlockBuffer: ArrayBuffer;
  _genesisBlock: GenesisBlock;

  get sourceBuffer(): ArrayBuffer {
    return this._genesisBlockBuffer;
  }

  // get sourceBufferUint8Array(): Uint8Array {
  //   return this._genesisBlockBuffer as Uint8Array;
  // }

  // Returns the json that represents the contents of this message
  get json(): Record<string, unknown> {
    return genesisBlockJson(this._genesisBlockBuffer);
  }

  // Returns the json that represents the CLS embedded in this message
  get clsJSON() {
    return getClsJSON(this._genesisBlock.getBlockData().getCls());
  }

  // Returned the cached instance of a capnp GenesisBlock.  This is assuming
  // that this object is never modified by the code that obtains this object.
  get genesisBlock(): GenesisBlock {
    return this._genesisBlock;
  }

  // Verify that the message was signed by the provided public key
  verifySignature(publicKeyBuffer: ArrayBuffer): Promise<boolean> {
    return verifyGenesisBlockBuffer(publicKeyBuffer, this._genesisBlockBuffer);
  }

  // Has side-effect of replacing _genesisBlock and _genesisBlockBuffer
  // One danger of doing this is if someone holds onto the old reference for the unsigned genesis block
  async signGenesisBlock(keys: ECKeys) {

    // Copy block data (ie. no signature)
    const newMessage = new Message();
    const newRoot = newMessage.initRoot(GenesisBlock);
    newRoot.setBlockData(this.genesisBlock.getBlockData());

    // Allocate space for signature
    newRoot.initGeeqcorpSignature(64);

    // Compute signature
    const buffer = new Uint8Array(newMessage.toPackedArrayBuffer());
    const signature = await keys.sign(buffer);

    // // Hack to force bad signature for debugging
    // const dupSignature = signature.slice(0);
    // const badSignature = new Uint8Array(dupSignature);
    // badSignature[0] = 0;

    // Genearate signature

    // Save signature to dup genesis block
    const dupBlockSignature = newRoot.getGeeqcorpSignature();
    dupBlockSignature.copyBuffer(signature);

    // Save signature to original genesis block
    const blockSignature = this.genesisBlock.getGeeqcorpSignature();
    blockSignature.copyBuffer(signature);
    
    // Reset internal variables since the genesis block has been replaced.
    this._genesisBlockBuffer = newMessage.toPackedArrayBuffer();
    this._genesisBlock = newRoot;  


    // DEBUG
    // if (keys.publicKey != null) {
    //   const isTestVerified = await verifyGenesisBlockBuffer(keys.publicKey, this._genesisBlockBuffer );
    //   console.log(isTestVerified);
    // }
  }

  // Creates an unsigned Genesis Block Message Wrapper.  Call signGenesisBlock method to sign message.
  constructor(gbBuffer: ArrayBuffer) {
    this._genesisBlockBuffer = gbBuffer;
    this._genesisBlock = genesisBlockFromBuffer(gbBuffer);
  }
}


export default {
  GenesisBlockWrapper, nodeStatusText
};
