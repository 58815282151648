import { Message } from "capnp-ts";
import { UUT } from "@/generated/uut.capnp";
import { ECKeys, verify } from "@/models/cryptoUtils";
import { Wrapper } from "@/models/capnpMessages/WrapperInterface"
import { uutJson } from "@/models/capnpMessages/UutJson"
import { uutFromBuffer } from "@/models/capnpMessages/MessageFromBuffer"

/*
 *  Verifies that the provided public key was the key used to sign the
 *  Genesis block data.
 */
async function verifyUUTBuffer(
  publicKeyBuffer: ArrayBuffer,
  uutBlockBuffer: ArrayBuffer
): Promise<boolean> {
  // Copy block data (ie. no signature)
  const duplicateMessage = new Message();
  const dupRoot = duplicateMessage.initRoot(UUT);
  const uut = uutFromBuffer(uutBlockBuffer);
  dupRoot.setUutData(uut.getUutData());

  // Allocate space for signature
  dupRoot.initSenderSignature(64);

  // Compute signature
  const blockBuffer = new Uint8Array(duplicateMessage.toPackedArrayBuffer());

  const signature = uut.getSenderSignature();
  const signatureBuffer = signature.toArrayBuffer();
  const result = await verify(blockBuffer, publicKeyBuffer, signatureBuffer);
  return result;
}

/*
 * Verify that the transaction was signed by the public key embedded in the transaction.
 */
export async function verifyUUT(uut: UUT) {

  // Make a copy
  const message = new Message()
  const dupUUT: UUT = message.initRoot(UUT)
  dupUUT.setUutData(uut.getUutData())
  dupUUT.setSenderSignature(uut.getSenderSignature())
  const uutBuffer = message.toPackedArrayBuffer()

  // Get sender account key
  const senderPublicKey = dupUUT.getUutData().getSenderPublicKey().toArrayBuffer()

  return await verifyUUTBuffer(senderPublicKey, uutBuffer)
}

/*
 * This class wraps an ArrayBuffer that contains a capnp GenesisBlock message.
 */
export class UutWrapper implements Wrapper {
  _uutBuffer: ArrayBuffer;
  _uut: UUT;

  get sourceBuffer(): ArrayBuffer {
    return this._uutBuffer;
  }

  // Returns the json that represents the contents of this message
  get json(): Record<string, unknown> {
    return uutJson(this._uutBuffer);
  }

  // Returned the cached instance of a capnp GenesisBlock.  This is assuming
  // that this object is never modified by the code that obtains this object.
  get uut(): UUT {
    return this._uut;
  }

  // Verify that the message was signed by the public key in the message
  verifySignature(publicKeyBuffer: ArrayBuffer): Promise<boolean> {
    return verifyUUTBuffer(publicKeyBuffer, this._uutBuffer);
  }

  // Has side-effect of replacing _uut and _uutBuffer
  // One danger of doing this is if someone holds onto the old reference for the unsigned genesis block
  async signUUT(keys: ECKeys) {
    // Copy block data (ie. no signature)
    const newMessage = new Message();
    const newRoot = newMessage.initRoot(UUT);
    newRoot.setUutData(this.uut.getUutData());

    // Allocate space for signature
    newRoot.initSenderSignature(64);
    
    // Compute signature
    const buffer = new Uint8Array(newMessage.toPackedArrayBuffer());
    const signature = await keys.sign(buffer);

    // Save signature to dup genesis block
    const dupBlockSignature = newRoot.getSenderSignature();
    dupBlockSignature.copyBuffer(signature);

    // Save signature to original genesis block
    const blockSignature = this.uut.getSenderSignature();
    blockSignature.copyBuffer(signature);
    
    // Reset internal variables since the genesis block has been replaced.
    this._uutBuffer = newMessage.toPackedArrayBuffer();
    this._uut = newRoot;  

    // // DEBUG
    // if (keys.publicKey != null) {
    //   const isTestVerified = await verifyUUTBuffer(keys.publicKey, this._uutBuffer );
    //   console.log(isTestVerified);
    // }
  }

  constructor(uutBuffer: ArrayBuffer) {
    this._uutBuffer = uutBuffer;
    this._uut = uutFromBuffer(uutBuffer);
  }
}

export default {
    UutWrapper,
    verifyUUT
};
