
function buf2hex(buffer: ArrayBuffer) {
    return Array.prototype.map.call(new Uint8Array(buffer), x => ('00' + x.toString(16)).slice(-2)).join('');
}

function truncatedSignature(buffer: ArrayBuffer) {
    //const l = buffer.byteLength;
    return buf2hex(buffer).substring(0,16)+"...";
}

function trucatedBuf2hex(buffer: ArrayBuffer) {
    const bufferString = buf2hex(buffer)
    const l = bufferString.length;
    return "..." + bufferString.substring(l-6,l);
}
    
export { buf2hex, trucatedBuf2hex, truncatedSignature };
