/* tslint:disable */

/**
 * This file has been automatically generated by the [capnpc-ts utility](https://github.com/jdiaz5513/capnp-ts).
 */

import * as capnp from "capnp-ts";
import { ObjectSize as __O, Struct as __S } from 'capnp-ts';
import { AuditAdminRecord } from "./audit.capnp";
import { ConfirmationTx } from "./confirmationTx.capnp";
import { HtbData, HTB } from "./htb.capnp";
export const _capnpFileId = "ead6298ca3ccfef0";
export class BlockData extends __S {
    static readonly _capnp = { displayName: "BlockData", id: "d0c77596c385d79a", size: new __O(16, 5) };
    static _ConfTxList: capnp.ListCtor<ConfirmationTx>;
    getBlockNumber(): number { return __S.getUint32(0, this); }
    setBlockNumber(value: number): void { __S.setUint32(0, value, this); }
    getEpochNumber(): number { return __S.getUint32(4, this); }
    setEpochNumber(value: number): void { __S.setUint32(4, value, this); }
    getChainNumber(): number { return __S.getUint32(8, this); }
    setChainNumber(value: number): void { __S.setUint32(8, value, this); }
    adoptPrevCLSHash(value: capnp.Orphan<capnp.Data>): void { __S.adopt(value, __S.getPointer(0, this)); }
    disownPrevCLSHash(): capnp.Orphan<capnp.Data> { return __S.disown(this.getPrevCLSHash()); }
    getPrevCLSHash(): capnp.Data { return __S.getData(0, this); }
    hasPrevCLSHash(): boolean { return !__S.isNull(__S.getPointer(0, this)); }
    initPrevCLSHash(length: number): capnp.Data { return __S.initData(0, length, this); }
    setPrevCLSHash(value: capnp.Data): void { __S.copyFrom(value, __S.getPointer(0, this)); }
    adoptLast10HtbHash(value: capnp.Orphan<capnp.Data>): void { __S.adopt(value, __S.getPointer(1, this)); }
    disownLast10HtbHash(): capnp.Orphan<capnp.Data> { return __S.disown(this.getLast10HtbHash()); }
    getLast10HtbHash(): capnp.Data { return __S.getData(1, this); }
    hasLast10HtbHash(): boolean { return !__S.isNull(__S.getPointer(1, this)); }
    initLast10HtbHash(length: number): capnp.Data { return __S.initData(1, length, this); }
    setLast10HtbHash(value: capnp.Data): void { __S.copyFrom(value, __S.getPointer(1, this)); }
    adoptConfTxList(value: capnp.Orphan<capnp.List<ConfirmationTx>>): void { __S.adopt(value, __S.getPointer(2, this)); }
    disownConfTxList(): capnp.Orphan<capnp.List<ConfirmationTx>> { return __S.disown(this.getConfTxList()); }
    getConfTxList(): capnp.List<ConfirmationTx> { return __S.getList(2, BlockData._ConfTxList, this); }
    hasConfTxList(): boolean { return !__S.isNull(__S.getPointer(2, this)); }
    initConfTxList(length: number): capnp.List<ConfirmationTx> { return __S.initList(2, BlockData._ConfTxList, length, this); }
    setConfTxList(value: capnp.List<ConfirmationTx>): void { __S.copyFrom(value, __S.getPointer(2, this)); }
    adoptAuditRecord(value: capnp.Orphan<AuditAdminRecord>): void { __S.adopt(value, __S.getPointer(3, this)); }
    disownAuditRecord(): capnp.Orphan<AuditAdminRecord> { return __S.disown(this.getAuditRecord()); }
    getAuditRecord(): AuditAdminRecord { return __S.getStruct(3, AuditAdminRecord, this); }
    hasAuditRecord(): boolean { return !__S.isNull(__S.getPointer(3, this)); }
    initAuditRecord(): AuditAdminRecord { return __S.initStructAt(3, AuditAdminRecord, this); }
    setAuditRecord(value: AuditAdminRecord): void { __S.copyFrom(value, __S.getPointer(3, this)); }
    adoptHtb(value: capnp.Orphan<HTB>): void { __S.adopt(value, __S.getPointer(4, this)); }
    disownHtb(): capnp.Orphan<HTB> { return __S.disown(this.getHtb()); }
    getHtb(): HTB { return __S.getStruct(4, HTB, this); }
    hasHtb(): boolean { return !__S.isNull(__S.getPointer(4, this)); }
    initHtb(): HTB { return __S.initStructAt(4, HTB, this); }
    setHtb(value: HTB): void { __S.copyFrom(value, __S.getPointer(4, this)); }
    toString(): string { return "BlockData_" + super.toString(); }
}
export class Block extends __S {
    static readonly _capnp = { displayName: "Block", id: "a9759069860013cc", size: new __O(0, 2) };
    adoptBlockData(value: capnp.Orphan<BlockData>): void { __S.adopt(value, __S.getPointer(0, this)); }
    disownBlockData(): capnp.Orphan<BlockData> { return __S.disown(this.getBlockData()); }
    getBlockData(): BlockData { return __S.getStruct(0, BlockData, this); }
    hasBlockData(): boolean { return !__S.isNull(__S.getPointer(0, this)); }
    initBlockData(): BlockData { return __S.initStructAt(0, BlockData, this); }
    setBlockData(value: BlockData): void { __S.copyFrom(value, __S.getPointer(0, this)); }
    adoptNodeSignature(value: capnp.Orphan<capnp.Data>): void { __S.adopt(value, __S.getPointer(1, this)); }
    disownNodeSignature(): capnp.Orphan<capnp.Data> { return __S.disown(this.getNodeSignature()); }
    getNodeSignature(): capnp.Data { return __S.getData(1, this); }
    hasNodeSignature(): boolean { return !__S.isNull(__S.getPointer(1, this)); }
    initNodeSignature(length: number): capnp.Data { return __S.initData(1, length, this); }
    setNodeSignature(value: capnp.Data): void { __S.copyFrom(value, __S.getPointer(1, this)); }
    toString(): string { return "Block_" + super.toString(); }
}
BlockData._ConfTxList = capnp.CompositeList(ConfirmationTx);
