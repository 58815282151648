<template>
  <transition name="modal">

    <div class="modal-mask">

      <div class="modal-wrapper">

        <div class="modal-container">

          <div class="modal-header">
            <slot name="header">
              <h3>Send Transaction</h3>
            </slot>
            
              <button class=" x-button" @click="cancelForm()">
                +
              </button>
          </div><!-- end modal-header -->

          <div class="modal-body">

            <slot name="body">
                <p v-if="errors.length">
                  <b>Please correct the following error(s):</b>
                  <ul>
                    <li style="color: red" v-for="error in errors" :key="error">{{ error }}</li>
                  </ul>
                </p>
              <form @submit.prevent="submitForm">

                <div class="modal-form-row">

                  <div class="modal-label-wrap">
                    <label for="targetNode">Target Geeq Node</label>
                  </div><!-- end modal-label-wrap -->

                  <div class="modal-input-wrap">
                    <select
                      id="targetNode"
                      name="targetNode"
                      v-model="targetNode"
                    >
                      <option
                        v-for="nodeNumber in nodeList"
                        :value="nodeNumber"
                        :key="nodeNumber"
                        >{{ nodeNumber }}</option
                      >
                    </select>
                  </div><!-- end modal-input-wrap -->

                </div><!-- end end modal-form-row -->

                <div class="modal-form-row">

                  <div class="modal-label-wrap">
                    <label for="targetUser">Target Geeq User Account</label>
                  </div><!-- end end modal-label-wrap -->

                  <div class="modal-input-wrap">
                    <select
                      id="targetUser"
                      name="targetUser"
                      v-model="targetUser"
                    >
                      <option
                        v-for="userKey in userList"
                        :value="userKey"
                        :key="userKey"
                        >{{ userKey }}</option
                      >
                    </select>
                  </div><!-- end modal-form-row -->

                </div><!-- end modal-form-row -->

                <div class="modal-form-row">
                  <div>
                    <label for="userInitialBalance">User Account Initial Balance</label>
                      <p>(Available balance: {{userBalance}})</p>
                  </div><!-- end modal-form-row -->
                  <input id="transactionAmount" v-model.number="transactionAmount" type="number"/>
                </div><!-- end modal-form-row -->
              </form>

            </slot>

          </div><!-- end modal-body -->

          <div class="modal-footer">
            <slot name="footer">
              <button class="modal-default-button" @click="submitForm()">
                Save
              </button>
            </slot>
          </div><!-- end modal-footer -->

        </div><!-- end modal-container -->

      </div><!-- end modal-wrapper -->

    </div><!-- end modal-mask -->

  </transition>
</template>

<script>
export default {
  emits: ["close"],
  data() {
    return {
      errors: [],
      targetNode: 0,
      targetUser: "",
      transactionAmount: 0,
    };
  },
  computed: {},
  methods: {
    submitForm() {
      this.errors = [];

      // Change this to <= zero for full validation.  Don't check zero to allow for invalid input.
      if (this.transactionAmount < 0) {
        this.errors.push("Amount must be greater than zero.");
        return;
      }

      if (this.transactionAmount > this.userBalance) {
        this.errors.push("Amount can not be greater than your balance.");
        return;
      }

      this.$emit("close", {
        targetNode: this.targetNode,
        targetUser: this.targetUser,
        transactionAmount: this.transactionAmount,
      });
    },
    cancelForm() {
      this.$emit("close", null)
    }
  },
  props: {
    nodeList: Array,
    userList: Array,      // User Accounts
    userBalance: Number,
  },
};
</script>

<style>
</style>
